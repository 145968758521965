define('runPrepHeaderDetailsView',[
    'module',
    'backbone.marionette',
    'underscore',
    'template!runPrepHeaderDetailsView',
    'pcrRunCollectionView',
    'prepRunEditLayoutView',
    'prepRunExtrKitsLotsView',
    'app',
    'renderer',
    'template!prepRunStatusTpl'
], function (
    module,
    Marionette,
    _,
    Tpl,
    PcrRunCollectionView,
    CreateEditLayoutView,
    PrepRunExtrKitsLotsView,
    App,
    Renderer,
    PrepRunStatusTpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'details',
        regions: {
            'pcrRuns': '.js-pcrruns'
        },
        ui: {
            copyParameters: '.js-copyParameters',
            statusToolTip: '.js-status-tooltip'
        },
        modelEvents: {
            'change': 'renderSafe'
        },
        events: {
            'click .btn-edit-preprun': 'onEditPrepRun',
            'click .btn-show-kits': 'onShowKits',
            'click .btn-show-attachments': 'onShowAttachments',
            'click .js-run-refresh': 'onRunRefresh'
        },
        serializeData: function () {
            return {
                code: this.model.get('code'),
                status: this.model.get('status')
            };
        },
        renderSafe: function () {
            if (!this.isDestroyed) {
                setTimeout(_.bind(this.render, this), 50);
            }
        },
        onRender: function () {
            var pcrRuns = this.model.get('pcrRuns');
            this.showChildView('pcrRuns', new PcrRunCollectionView({
                collection: pcrRuns,
                model: this.model
            }));
            App.trigger('forClipboard', this.ui.copyParameters);
            this.showStatusPrepRunPopover();
        },
        onEditPrepRun: function () {
            var view = new CreateEditLayoutView({
                model: this.model
            });
            view.show({
                title: _.i18n('preprun.edit'),
                className: 'runEditAddPopup'
            }, _.bind(function () {
                this.render();
            }, this));
        },
        onShowKits: function () {
            this.model.getKitLots()
                .done(_.bind(function (result) {
                    this.prepRunExtrKitsLotsView = new PrepRunExtrKitsLotsView({
                        model: this.model,
                        kitLots: result
                    });
                    this.prepRunExtrKitsLotsView.show({
                        title: _.i18n('prepRun.extr.kits'),
                        className: 'runShowKitsPopup'
                    });
                }, this));
        },
        onShowAttachments: function () {
            var PrepRunService = require('services/caccounts/prepruns');
            var AttachmentService = require('services/caccounts/attachment');
            AttachmentService.openAttachment(PrepRunService, this.model);
        },
        onRunRefresh: function () {
            $('.js-global-loader').show();
            this.model.fetch().always(function () {
                $('.js-global-loader').hide();
            });
        },
        showStatusPrepRunPopover: function () {
            if (this.isDestroyed()) {
                return;
            }
            if (this.ui.statusToolTip && this.ui.statusToolTip.length > 0) {
                var renderer = new Renderer({
                    template: PrepRunStatusTpl, templateData: {
                        current: this.model.get('status')
                    }
                });
                renderer.render();
                var content = renderer.$el.html();
                this.ui.statusToolTip.attr('data-content', content);
                this.ui.statusToolTip.popover({
                    trigger: 'hover',
                    placement: 'bottom',
                    html: 'true',
                    container: 'body'
                }).data('bs.popover').tip().addClass('run-prep-popover');
            }
        }
    });
});

