/**
 * Created by OLD on 07/08/2015.
 */
define('resetShowView',[
    'module',
    'underscore',
    'logger',
    'backbone.marionette',
    'app',
    'template!resetViewTpl'
], function (
    module,
    _,
    Logger,
    Marionette,
    App,
    viewTpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: viewTpl,
        errorCodes: {
            empty: _.i18n('login.enter.password'),
            nonEqual: _.i18n('resetpassword.enter.notequal'),
            requestError: _.i18n('common.request.error')
        },

        triggers: {
            'click @ui.resetPassword': 'reset:password'
        },

        ui: {
            password: '.js-password',
            passwordConfirm: '.js-password-confirm',
            resetPassword: '.js-reset-password',
            form: '.reset-pass-form'
        },

        events: {
            'click @ui.passwordConfirm': 'onSelect',
            'focus @ui.passwordConfirm': 'onSelect'
        },

        className: 'login-form-container',

        onSelect: function () {
            this.ui.form.find('.floating-label').text('Confirm your password');
            this.ui.form.removeClass('has-error');
        },

        passwordError: function (error) {
            this.ui.form.addClass('has-error');
            this.ui.passwordConfirm.addClass('invalid');
            this.ui.form.find('.floating-label').text(error);
        }
    });
});
