define('pcrWellResultController',[
    'module',
    'underscore',
    'jquery',
    'backbone',
    'entities/ns',
    'pcrWellResultDotView',
    'pcrWellResultHistogramNormalView'
], function (
    module,
    _,
    $,
    Backbone,
    app,
    PcrWellResultDotView,
    PcrWellResultHistogramNormalView
) {
    'use strict';

    module.exports = {
        show: function (model, location, region) {
            var run;
            if (model instanceof app.ns.PcrWell) {
                run = model.get('run');
            } else if (model instanceof app.ns.PcrWellResult) {
                run = model.get('refPcrWell').get('run');
            } else if (model instanceof Backbone.Collection) {
                if (model.isEmpty()) {
                    return;
                } else {
                    run = model.first().get('refPcrWell').get('run');
                }
            } else {
                throw new Error('Unknown model type');
            }
            if (!run) {
                return;
            }
            if (!run.get('refKitProt')) {
                return;
            }
            return this._show(model, location, region, run.get('displayMode'));
        },
        _show: function (model, location, region, displayMode) {
            var results = new Backbone.Collection();
            if (model instanceof app.ns.PcrWell) {
                results = model.get('results');
            } else if (model instanceof app.ns.PcrWellResult || _.any(app.ns.PcrWellResult._subModels || [], function (subModel) {
                return model instanceof subModel;
            })) {
                results.push(model);
            } else if (model instanceof Backbone.Collection && (model.first() instanceof app.ns.PcrWellResult || _.any(app.ns.PcrWellResult._subModels || [], function (subModel) {
                return model.first() instanceof subModel;
            }))) {
                results = model;
            } else {
                throw new Error('Unknown model type');
            }
            var parameter = displayMode.findParameterForLocation(location);
            if (!parameter) {
                console.error('Parameter not found for location ' + location);
                return;
            }
            var config = parameter.getConfig(location);
            var generateImage = !region;
            var datas = [];
            var propertySequence = config.display.propertyName || 'sequence';
            var collection = new Backbone.Collection(results.chain().filter(function (result) {
                return result.get('refAssayResult') && result.get('refAssayResult').get(propertySequence);
            }).sortBy(function (result) {
                return result.get('refAssayResult').get(propertySequence);
            }).value());

            if (location === 'Tooltip') {
                config.width = 20 * collection.length;
                if (config.width > 400) {
                    config.width = 400;
                }
            }

            switch (parameter.get('parameter').type.toUpperCase()) {
                case 'DOT':
                    var dotView = new PcrWellResultDotView({
                        collection: collection,
                        config: config
                    });
                    if (region) {
                        region.show(dotView);
                    } else {
                        return dotView;
                    }
                    break;
                case 'HISTOGRAM':
                    datas = collection.map(function (result) {
                        var value = _.isArray(result.get('cts')) ? _.first(result.get('cts')) : result.get('cts');
                        if (!value) {
                            value = 0;
                        }
                        return {
                            value: value,
                            code: result.get('result') ? result.get('result').get('code') : '',
                            color: result.get('result') ? result.get('result').get('color') : '',
                            secId: result.get('secId')
                        };
                    });

                    var typeHistogram = parameter.get('parameter').param.split(';')[0];
                    var hView;
                    switch (typeHistogram.toUpperCase()) {
                        case 'NORMAL':
                            if (config.max) {
                                _.each(datas, function (data) {
                                    var value = parseInt(data.value, 10);
                                    if (value > config.max) {
                                        data.value = config.max;
                                    }
                                    if (value < config.min) {
                                        data.value = config.min;
                                    }
                                });
                            } else {
                                config.max = _.max(datas, function (data) {
                                    return data.value;
                                }).value;
                            }
                            _.each(datas, function (data) {
                                if (data.code === 'N') {
                                    if (data.value >= config.minNegative && data.value <= config.maxNegative) {
                                        data.value = [config.chartMinNegative, config.chartMaxNegative];
                                    }
                                }
                            });
                            hView = new PcrWellResultHistogramNormalView({
                                data: datas,
                                generateImage: generateImage,
                                config: config
                            });
                            break;
                        case 'INVERSE':
                            _.each(datas, function (data) {
                                var result = collection.findWhere({secId: data.secId});
                                if (!result || !(result instanceof app.ns.PcrWellResultCurve)) {
                                    return;
                                }
                                data.value = config.max - data.value;

                                if (data.code === 'N') {
                                    data.value = [config.max / 4, config.max / 4 * 3];
                                }
                            });

                            hView = new PcrWellResultHistogramNormalView({
                                data: datas,
                                generateImage: generateImage,
                                config: config
                            });
                            break;
                        default:
                            throw new Error('Unknown histogram type ' + typeHistogram);
                    }

                    if (region) {
                        region.show(hView);
                    } else {
                        return hView;
                    }
                    break;
                default:
                    throw new Error('Unknown parameter type ' + parameter.get('type'));
            }
        },

        generateContent: function (model, location) {
            var view = this.show(model, location);
            if (!view) {
                return '';
            }
            var content = '';
            view.render();
            if (view instanceof PcrWellResultHistogramNormalView) {
                content = '<img class="histogramImage" src="' + view.toBase64Image() + '" alt="Histogram"/>';
            } else if (view instanceof PcrWellResultDotView) {
                content = view.$el.html();
            } else {
                throw new Error('Unknown view type');
            }
            view.destroy();
            return content;
        }
    };
});

