
define('template!roleView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n    <h2>' +
((__t = ( _.i18n('roles') )) == null ? '' : __t) +
'</h2>\n\n    ';
 if(!readOnly) { ;
__p += '\n    <div><label>ALL</label><input type="checkbox" class="js-checked" value="ALL" checked> <input type="checkbox" class="js-unchecked" value="ALL"></div>\n    <div><label>READ</label><input type="checkbox" class="js-checked" value="READ" checked> <input type="checkbox" class="js-unchecked" value="READ"></div>\n    <div><label>WRITE</label><input type="checkbox" class="js-checked" value="WRITE" checked> <input type="checkbox" class="js-unchecked" value="WRITE"></div>\n    <div><label>ARCHIVE</label><input type="checkbox" class="js-checked" value="ARCHIVE" checked> <input type="checkbox" class="js-unchecked" value="ARCHIVE"></div>\n    ';
 } ;
__p += '\n</div>\n\n<div class="js-roles"></div>\n';

}
return __p
};});

