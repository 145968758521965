define('dynamicCreateEditTab',[
    'module',
    'backbone',
    'underscore',
    'backbone.marionette',
    'dynamicCreateEditTabCollection',
    'template!dynamicCreateEditTabTpl',
    'dialogFormView'
], function (
    module,
    Backbone,
    _,
    Marionette,
    DynamicCreateEditTabCollection,
    Tpl,
    DialogFormView
) {
    'use strict';

    module.exports = DialogFormView.extend({

        template: Tpl,
        regions: {
            tab: '.js-tab',
            currentModel: '.js-current-model'
        },
        events: {
            'click .js-add': 'onAdd'
        },

        initialize: function () {
            this.currentTabId = this.collection.first().cid;

        },
        onAdd: function () {
            this.onAddModel(this.collection.model.findOrCreate({}));
        },
        onAddModel: function (model) {
            this.currentTabId = model.cid;
            this.collection.push(model);
            this.render();
        },
        onRender: function () {
            var tab = new DynamicCreateEditTabCollection({
                collection: this.options.collection,
                model: this.model,
                currentTabId: this.currentTabId
            });
            this.getRegion('tab').show(tab);
            this.listenTo(tab, 'item:changeTab', this.changeTab);
            var model = _.first(this.collection.filter(_.bind(function (model) {
                return model.cid === this.currentTabId;
            }, this)));
            var view = new this.options.view({model: model});
            this.getRegion('currentModel').show(view);
            this.listenTo(view, 'item:add', this.onAddModel);
            this.listenTo(view, 'item:delete', this.onDeleteModel);
        },
        changeTab: function (modelId) {
            var model = _.first(this.collection.filter(_.bind(function (model) {
                return model.cid === modelId;
            }, this)));
            this.currentTabId = model.cid;
            var view = new this.options.view({model: model});
            this.getRegion('currentModel').show(view);
            this.listenTo(view, 'item:add', this.onAddModel);
            this.listenTo(view, 'item:delete', this.onDeleteModel);
        },
        onDeleteModel: function (model) {
            this.collection.remove(model);
            this.currentTabId = this.collection.first().cid;
            this.render();
        }
    });
});
