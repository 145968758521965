/**
 * Created by OLD on 08/09/2015.
 */
define('headerPopupView',[
    'module',
    'underscore',
    'settings',
    'template!headerPopupTpl',
    'dialogFormView',
    'stateMessages'
], function (
    module,
    _,
    Settings,
    viewTpl,
    DialogFormView,
    StateMessages
) {
    'use strict';

    var PopupHeaderView = DialogFormView.extend({
        template: viewTpl,

        triggers: {
            'change @ui.upload': 'onChangeLogo',
            'click @ui.save': 'onSaveClick',
            'click @ui.changePassword': 'onChangePassword'
        },

        ui: {
            upload: '.upload-user-avatar',
            preview: '.user-avatar-img',
            save: '.btn-s-md-save',
            input: '.js-form-input',
            changePassword: '.js-change-password'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'focus @ui.input': 'onFocus'
        },

        behaviors: {
            StateMessages: {
                behaviorClass: StateMessages
            }
        },

        serializeData: function () {
            var templateData = this.model.toJSON();

            if (this.model.get('logo')) {
                templateData.fileUrl = Settings.url('rest/file', this.model.get('logo'));
            } else {
                templateData.fileUrl = null;
            }
            return templateData;
        }
    });
    module.exports = PopupHeaderView;
});

