define('prepRunDisplayKitsLotsView',[
    'module',
    'backbone.marionette',
    'backbone',
    'dialogFormView',
    'underscore',
    'jquery',
    'app',
    'savingBehavior',
    'template!prepRunDisplayKitsLotsView'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    _,
    $,
    App,
    SavingBehavior,
    WellCommentTpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: WellCommentTpl,

        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        ui: {
            cancel: '.js-cancel-popup'
        },

        events: {
            'click .js-cancel-popup': 'onCancel'
        },

        serializeData: function () {
            var templateData = {
                pos: this.model.get('pos'),
                smpId: this.model.get('smpId') ? {
                    name: this.model.get('smpId').get('name'),
                    code: this.model.get('smpId').get('code')
                } : {name: '', code: ''},
                kitLotByKits: _.map(this.model.get('kitLotByKits'), function (kitLot) {
                    return {
                        refPcrKit: kitLot.refPcrKit.code,
                        pcrKitLots: _.pluck(kitLot.pcrKitLots, 'code')
                    };
                })
            };

            templateData.extractionKitLots = this.model.get('run').get('extractionDetails').getExtractionKitLots();

            var kits = _.uniq(this.model.get('pcrKitLots').pluck('kit'), false, function (kit) {
                return kit.get('secId');
            });

            templateData.kitLotByKits = [];
            _.each(kits, _.bind(function (kit) {
                var pcrKitLots = this.model.get('pcrKitLots').chain().filter(function (pcrKitLot) {
                    return pcrKitLot.get('kit') === kit;
                }).map(function (pcrKitLot) {
                    return pcrKitLot.get('code');
                }).value();
                templateData.kitLotByKits.push({
                    pcrKit: kit.get('code'),
                    pcrKitLots: pcrKitLots
                });
            }, this));
            return templateData;
        },

        onRender: function () {
            this.triggerMethod('enable:cancel:confirm');
        },

        onSave: function (model) {
            this.triggerMethod('saved');
            this.triggerMethod('prep:wellComment:saved', model); // to refresh well view
            this.box.modal('hide');
        },

        onCancel: function () {
            this.box.modal('hide');
        }
    });
});
