/**
 * Created by RKL on 05/08/2015.
 */
define('forgotMainRouter',[
    'require',
    'exports',
    'module',
    'marionetteAppRouter',
    'app'
], function (
    require,
    exports,
    module,
    AppRouter,
    App
) {
    'use strict';

    var ForgotPwdRouter = AppRouter.extend({
        appRoutes: {
            'forgot_password': 'showForgot'
        }
    });

    var API = {
        showForgot: function () {
            require(['forgotShowController'], function (ShowController) {
                App.startSubModule('forgotMain');
                ShowController.showForgot();
            });
        }
    };

    App.on('before:start', function () {
        new ForgotPwdRouter({
            controller: API
        });
    });

    module.exports = ForgotPwdRouter;
});

