/**
 * Created by Matteo on 11/07/2015.
 */
define('headerShowView',[
    'module',
    'backbone.marionette',
    'underscore',
    'jquery',
    'app',
    'settings',
    'imageUtils',
    'autocompleteView',
    'template!headerViewTpl',
    'template!headerCaccountAutocompleteTpl',
    'rolesMixin',
    'dateUtils',
    'bootbox',
    'cookies',
    'superAdminGodModeView',
    'require'
], function (
    module,
    Marionette,
    _,
    $,
    App,
    Settings,
    imageUtils,
    AutocompleteView,
    viewTpl,
    caccountAutocompleteItemTpl,
    RolesMixIn,
    DateUtils,
    Bootbox,
    Cookies,
    SuperAdminGodModeView,
    requireJS
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: viewTpl,

        triggers: {
            'click @ui.logout': 'onLogout',
            'click @ui.editLogin': 'onEdit'
        },

        ui: {
            logout: '.logout',
            logoutUser: '.logout-user',
            logo: '.js-user-avatar',
            userName: '.js-user-name',
            caccount: '.js-caccount',
            user: '.js-user',
            caccountName: '.js-caccount-name',
            caccountLogo: '.js-caccount-logo',
            caretCAccount: '.js-caret-caccount',
            caretUser: '.js-caret-user',
            dropdown: '.caccount-hover-dropdown',
            editLogin: '.js-edit-login',
            changeLanguage: '.js-languages',
            godMode: '.js-godmode',
            authorities: '.js-authorities'
        },

        className: function () {
            return 'app-header' + (this.options.obsolete ? ' obsolete' : '');
        },

        events: {
            'click @ui.caccount': 'onShowDropdown',
            'click @ui.editLogin': 'onEdit',
            'click @ui.changeLanguage': 'onChangeLanguageClick',
            'click @ui.godMode': 'onGodModeClick',
            'click @ui.authorities': 'onAuthoritiesClick',
            'click @ui.logoutUser': 'onLogoutUser'
        },

        regions: {
            caccountAutocomplete: '.js-caccounts-autocomplete'
        },

        serializeData: function () {
            var templateData = {};
            templateData.translationDisplayModeCookie = Cookies.get('gm-displayI18nCode') ? true : false;
            var currentCAccount = Settings.get('currentCAccount');
            templateData.logo = imageUtils.getLogo(
                currentCAccount.logo,
                currentCAccount.code, {
                    action: 'res',
                    params: 't'
                }
            );
            var currentUser = Settings.get('currentUserModel');
            templateData.avatar = imageUtils.getLogo(
                currentUser.get('logo'),
                currentUser.get('firstName') + ' ' + currentUser.get('lastName'), {
                    action: 'res',
                    params: 't'
                }, 'circle'
            );
            templateData.userName = (currentUser.get('nickName'));
            templateData.userNameTitle = currentUser.get('firstName') + ' ' + currentUser.get('lastName') + '(' + currentUser.get('logonId') + ')';
            templateData.Bversion = this.options.information.version;
            templateData.Bdate = this.options.information.date ? DateUtils.toDateTimeFormatString(this.options.information.date) : '';
            templateData.Fversion = Settings.get('version');
            templateData.Fdate = DateUtils.toDateTimeFormatString(Settings.get('date'));
            templateData.label = null;
            if (templateData.Bversion && templateData.Bversion.includes('SNAPSHOT')) {
                templateData.label = 'DEV';
            }
            if (templateData.Bversion && templateData.Bversion.includes('RC')) {
                templateData.label = 'RC';
            }
            templateData.code = templateData.title = currentCAccount.code;
            templateData.superUser = currentUser.hasRole(RolesMixIn.SUPER_USER);
            templateData.loginTo = Settings.get('loginTo');
            if (templateData.superUser) {
                templateData.title = currentCAccount.secId.split('_')[0];
            }
            templateData.folder = '';
            if (Settings.get('folder')) {
                templateData.folder = Settings.get('folder');
            }
            return templateData;
        },

        onChangeLanguageClick: function () {
            var LanguageService = require('services/languages');
            LanguageService.getCollection().getLanguages(false, true).done(_.bind(function (languageCollection) {
                var inputOptions = languageCollection.map(function (language) {
                    return {text: language.get('name'), value: language.get('abbreviation')};
                });
                Bootbox.prompt({
                    title: _.i18n('languageSelector.selectLang'),
                    inputType: 'select',
                    callback: _.bind(function (result) {
                        if (result) {
                            this.trigger('changeLanguage', result);
                        }
                    }, this),
                    inputOptions: inputOptions,
                    value: Settings.get('lang'),
                    buttons: {
                        confirm: {
                            label: '<i></i>',
                            className: 'mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm'
                        },
                        cancel: {
                            label: '<i></i>',
                            className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
                        }
                    },
                    className: 'bootbox-checkbox bootbox-language'
                });
            }, this));
        },

        getAccountsAutocompleteOptions: function () {
            return {
                type: 'remote',
                alwaysOpen: false,
                remote: Settings.url('rest/v2/sessions.json/token/byuser/caccounts'),
                valueKey: 'code',
                apiKey: 'secId',
                placeholder: _.i18n('selectaccount.placeholder'),
                value: null,
                name: 'accounts',
                onPressKey: true,
                callback: _.bind(this.onSwitchCaccount, this),
                childTemplate: caccountAutocompleteItemTpl,
                childViewSerializeData: this._autocompleteViewSerializeData,
                lazyLoad: true,
                keys: {
                    query: 'caccountCode',
                    limit: 'rows'
                },
                service: require('services/global/caccounts')
            };
        },

        onRender: function () {
            if (this.options.multiCaccounts) {
                this.ui.caretCAccount.show();
                this.renderCaccountsDropdown();
            } else {
                this.ui.caretCAccount.hide();
                this.displayCurrentCaccountInfo();
            }
        },

        renderCaccountsDropdown: function () {
            this._caccountAutocompleteView = new AutocompleteView(
                _.extend({
                    onBlur: _.bind(this.onShowDropdown, this)
                }, this.getAccountsAutocompleteOptions()));

            this.getRegion('caccountAutocomplete').show(this._caccountAutocompleteView);
            this._caccountAutocompleteView.$('.ac-suggestions.dropdown-menu').removeClass('dropdown-menu ac-suggestions').addClass('caccount-hover-dropdown');
            this.displayCurrentCaccountInfo(Settings.get('currentCAccount'));
        },

        displayCurrentCaccountInfo: function (account) {
            var currentCAccount = account || Settings.get('currentCAccount'),
                logo;

            this.currentCAccount = currentCAccount;
            this.ui.caccountName.text(currentCAccount.code);
            logo = imageUtils.getLogo(
                currentCAccount.logo,
                currentCAccount.code, {
                    action: 'res',
                    params: 't'
                }
            );
            this.ui.caccountLogo.attr('src', logo);
        },

        onShowDropdown: function () {
            this.ui.dropdown.toggleClass('open');
            if (this.ui.dropdown.hasClass('open')) {
                this._caccountAutocompleteView.ui.autocomplete.focus();
            }
        },

        onSwitchCaccount: function (fieldName, cAccount) {
            if (cAccount) {
                App.trigger('switchCaccount', cAccount.toJSON());
            }
        },

        _autocompleteViewSerializeData: function () {
            var templateData = {};

            templateData.logo = imageUtils.getLogo(
                this.model.get('logo'),
                this.model.get('code'), {
                    action: 'res',
                    params: 't'
                }
            );
            templateData.code = this.model.get('code');

            return templateData;
        },
        onGodModeClick: function () {
            var view = new SuperAdminGodModeView();
            view.show({
                title: _.i18n('godmode.title'),
                size: 'large'
            });
        },
        onAuthoritiesClick: function () {
            var model = Settings.get('currentUserModel');
            var authorityGroupService = require('services/caccounts/authorityGroup');
            var authorityGroupModel = authorityGroupService.getModel({
                secId: model.get('secId') + '_authorities', code: 'authorities', privileges: _.clone(model.get('authorities'))
            });
            requireJS(['roleController'], function (Controller) {
                Controller.showDetails({model: authorityGroupModel, readOnly: true, title: model.get('code') + ' (' + model.get('name') + ')'});
            });
        },
        onLogoutUser: function () {
            var userpidService = require('services/caccounts/userpids');
            userpidService.logout();
        }
    });
});

