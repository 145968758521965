
define('template!chartView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<canvas class="chart-canvas"></canvas>\n<div class="slider-range" style="margin-left: 40px; margin-right: 6px;"></div>\n<span class="mdi mdi-rotate-3d-variant reset-slider-vert clickable"\n      style="display: none; position: absolute; right: -3px; top: -24px;font-size: 20px;line-height: 20px;"></span>\n<div class="slider-range-vert" style="position: absolute; bottom: 0; right: 0;"></div>\n<span class="mdi mdi-rotate-3d-variant reset-slider clickable"\n      style="display: none; position: absolute; bottom: -15px; left: 22px;font-size: 20px;line-height: 20px;"></span>';

}
return __p
};});

