define('entities/caccounts/assayconfiguration',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/assayConfigurationResult',
    'entities/caccounts/colorCompensations'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.AssayConfiguration = Backbone.RelationalModel.extend({
        service: 'services/caccounts/asayconfiguration',
        fetch: function () {
            this.url = Settings.url('rest/v2/assayConfigurations/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'results': [],
            'cyclers': [],
            'refColorCompensation': null,
            'refAssay': null
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'AssayConfigurationResult',
            collectionType: 'AssayConfigurationResultCollection'
        }, {
            type: Backbone.HasMany,
            key: 'cyclers',
            relatedModel: 'Cycler',
            collectionType: 'CyclerCollection'
        }, {
            type: Backbone.HasOne,
            key: 'refColorCompensation',
            relatedModel: 'ColorCompensation'
        }, {
            type: Backbone.HasMany,
            key: 'refAssay',
            relatedModel: 'Assay',
            includeInJSON: false
        }],


        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'AssayConfigurationJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayConfiguration'

    });

    app.ns.AssayConfiguration.role = RolesMixin.ASSAY;

    app.ns.AssayConfigurationCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/assayConfigurations/');
        },
        model: app.ns.AssayConfiguration
    });
});

