define('standardCurveAssayConfigurationResultPcrRunCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'standardCurveAssayConfigurationResultPcrRunItem'
], function (
    module,
    Backbone,
    Marionette,
    View
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({

        childView: View,

        childViewEvents: {
            'item:click': 'onChildItemClick'
        },

        onChildItemClick: function (view) {
            this.trigger('pcrRun:click', view.model);
        },

        collectionEvents: {
            'change': 'render'
        },
        childViewOptions: function (model) {
            return {
                selected: this.options.model === model
            };
        }
    });
});
