define('assayVersionController',[
    'module',
    'underscore',
    'jquery',
    'assayVersionCreateEditLayout'
], function (
    module,
    _,
    $,
    CreateEditLayoutView
) {
    'use strict';

    module.exports = {
        /**
         * This function is used to show the details of a specific model.
         * It first checks if the model exists. If it doesn't, it throws an error.
         * It then shows a global loader and fetches the model details.
         * Once the model details are fetched, it requires the AssayVersionService and creates a new instance of the CreateEditLayoutView with the model, lots, and cyclerPublicSecId.
         * The new instance of the CreateEditLayoutView is then shown with the title, className, and service.
         * If a callback function for refresh is defined, it is called after the CreateEditLayoutView is shown.
         * Finally, the global loader is hidden.
         *
         * @param {Object} param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.options - The options object.
         * @param {Array} param.options.lots - The lots to be passed to the CreateEditLayoutView.
         * @param {string} param.options.cyclerPublicSecId - The cyclerPublicSecId to be passed to the CreateEditLayoutView.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @throws {Error} If the model does not exist.
         */
        showDetails: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            $('.js-global-loader').show();
            param.model.fetch().done(function () {
                var AssayVersionService = require('services/caccounts/assayVersion');
                var createEditView = new CreateEditLayoutView({
                    model: param.model,
                    lots: param.options.lots,
                    cyclerPublicSecId: param.options.cyclerPublicSecId
                });
                createEditView.show({
                    title: _.i18n(AssayVersionService.getName() + '.new'),
                    className: 'assayVersionsEditAddPopup',
                    service: AssayVersionService
                }, _.bind(function () {
                    if (param.callBackOnClose) {
                        param.callBackOnClose();
                    }
                }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
            })
                .always(function () {
                    $('.js-global-loader').hide();
                });
        }
    };
});

