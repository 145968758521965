define('app',[
    'require',
    'exports',
    'module',
    'jquery',
    'backbone',
    'backbone.marionette',
    'settings',
    'browserUtils',
    'bootbox',
    'underscore',
    'translation',
    'outdatedBrowser',
    'autogrow',
    'extendBackbone'
], function (
    require,
    exports,
    module,
    $,
    Backbone,
    Marionette,
    Settings,
    BrowserUtils,
    Bootbox,
    _
) {
    'use strict';

    // Create the  Application
    var app = new Marionette.Application();

    // check version
    app.checkNewVersion = function () {
        var lastcommit = Settings.get('lastcommit');
        var environment = Settings.get('environment');
        if (environment === ' ') {
            // developpment mode
            return;
        }
        $.get('lastcommit').success(_.bind(function (data) {
            if (lastcommit !== data.replace('\n', '').replace('\r', '')) {
                Bootbox.confirm({
                    message: _.i18n('version.new'),
                    buttons: {
                        confirm: {
                            label: _.i18n('version.refreshApp')
                        },
                        cancel: {
                            label: _.i18n('version.ignoreRefresh')
                        }
                    },
                    callback: function (result) {
                        if (result) {
                            window.location.reload();
                        }
                    }
                });
            }
        }, this));

        setTimeout(app.checkNewVersion, 1800000);
    };

    app.checkNewVersion();

    //Encapsulate the backbone navigation into the application
    app.navigate = function (route, options) {
        options = options || {};

        Backbone.history.navigate(route, options);
    };

    //Enable to get the current route from backbone
    app.getCurrentRoute = function () {
        return Backbone.history.fragment;
    };

    app.clearAuthCookieValues = function () {
        Settings.set('tokenId', null);
        Settings.set('currentUser', null);
        Settings.set('currentUserGlobal', null);
        Settings.set('currentCAccount', null);
    };

    app.startSubModule = function (moduleName, args) {
        var currentModule = moduleName ? moduleName : null;

        if (currentModule && currentModule !== '') {
            require([moduleName], function (module) {
                currentModule = module;
                if (app.currentModule === currentModule) {
                    return;
                }

                if (app.currentModule) {
                    app.currentModule.stop();
                }

                app.currentModule = currentModule;
                if (currentModule) {
                    currentModule.start(args);
                }
            });
        }
    };

    app.on('before:start', function () {
        Settings.set('lang', Settings.get('lang') || BrowserUtils.getLanguage());
        //Manage the out of date browser
        $(document).ready(function () {
            outdatedBrowser({
                bgColor: '#f25648',
                color: '#ffffff',
                lowerThan: 'transform',
                languagePath: '../bower_components/outdatedbrowser/lang/' + Settings.get('lang') + '.html'
            });
        });

        //load translations
        var RegionContainer = Marionette.View.extend({
            el: 'body',
            regions: {
                main: '#main-region'
            }
        });
        app.regions = new RegionContainer();
        app.regions.getRegion('main').on('show', function () {
        });
    });

    //Fires after the Application has started and
    // after the initializer have been executed.
    app.on('start', function () {
        if (!Backbone.History.started) {
            Backbone.history.start();
        }
        require(['services/global/token'], function (TokenEntities) {
            TokenEntities.findBy().done(function (model) {
                Settings.set('currentUserGlobal', model.userpid);
                Settings.set('currentCAccount', model.caccount);
            });
        });
    });

    //Additional jquery methods
    //-------------------------------
    if ($.when.all === undefined) {
        $.when.all = function (deferreds) {
            var deferred = new $.Deferred();
            $.when.apply($, deferreds).done(
                function () {
                    deferred.resolve(Array.prototype.slice.call(arguments));
                },
                function () {
                    deferred.fail(Array.prototype.slice.call(arguments));
                });

            return deferred;
        };
    }

    app.on('forClipboard', function (element, value) {
        if (element.length) {
            _.each(element, function (el) {
                var value;
                if (el.attributes['data-copy'] && el.attributes['data-copy'].value !== '' && el.attributes['data-copy'].value !== '-') {
                    value = el.attributes['data-copy'].value;
                }
                app.trigger('forClipboard', el, value);
            });
            return;
        }
        if (element.length === 0) {
            return;
        }
        if (!value) {
            element.classList.add('invisible');
            return;
        }
        element.setAttribute('data-copy-value', value);
        element.setAttribute('title', _.i18n('icon.copy.to.clipboard'));
        element.onclick = _.bind(app.clipboard, this);
    });

    app.on('clipboard', app.clipboard);

    app.clipboard = function (event) {
        var $target = $(event.currentTarget);
        var value;
        if (event.currentTarget.attributes['data-copy-value']) {
            value = event.currentTarget.attributes['data-copy-value'].value;
        }
        if (event.currentTarget.attributes['data-copy']) {
            value = event.currentTarget.attributes['data-copy'].value;
        }
        if (!navigator.clipboard) {
            //old - document execcommand copy is deprecated
            var $temp = $('<input>');
            $target.append($temp);
            $temp.val(value).select();
            document.execCommand('copy');
            $temp.remove();
        } else {
            navigator.clipboard.writeText(value);
        }
    };

    module.exports = app;
});

