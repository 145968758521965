
define('template!loginViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '    <form target="hidden_iframe" autocomplete="on" class="form-horizontal js-form-submit form-group">\n      <fieldset>\n        <div class="top-login-part text-center">\n          <a class="login-view-link">\n            <div class="avatar-view-link-img"></div>\n          </a>\n          <h2>' +
((__t = ( _.i18n("login.signInRequest") )) == null ? '' : __t) +
'</h2>\n          ';
 if(textAdditionnal) { ;
__p += '\n          <h3>' +
((__t = ( textAdditionnal )) == null ? '' : __t) +
'</h3>\n          ';
 } ;
__p += '\n        </div>\n        <div class="form-group">\n          <div class="col-lg-12">\n            <input name="username" type="email" class="form-control login-view-username floating-label" autocomplete="on" placeholder="' +
((__t = ( _.i18n("login.login") )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <div class="form-group">\n          <div class="col-lg-12">\n            <input name="password" type="password" class="form-control login-view-password floating-label" autocomplete="on" placeholder="' +
((__t = ( _.i18n("login.password") )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <div class="normall-bold-error-compuzz txt-error" style="display: none;" >\n          ' +
((__t = ( _.i18n('common.login.error') )) == null ? '' : __t) +
'\n        </div>\n        <div class="sm-regular-notes-cpz">\n          * ' +
((__t = ( _.i18n('common.required.browser') )) == null ? '' : __t) +
'\n        </div>\n        <div class="form-group checkbox-group">\n          <div class="col-lg-12">\n            <div class="checkbox checkbox-primary">\n              <label>\n                  <input type="checkbox" class="js-keep-logged-in">' +
((__t = ( _.i18n("login.keepMeSignIn") )) == null ? '' : __t) +
'\n              </label>\n            </div>\n          </div>\n        </div>\n        <div class="form-group">\n          <div class="col-lg-12 login-view-btns">\n            <a class="btn btn-primary btn-lg btn-block login-view-sign-in">' +
((__t = ( _.i18n("login.signIn") )) == null ? '' : __t) +
'</a>\n            <a href="#forgot_password" class="pull-left">' +
((__t = ( _.i18n("login.forgotPassword") )) == null ? '' : __t) +
'</a>\n            <!--<a href="#register" class="pull-right">' +
((__t = ( _.i18n("login.register") )) == null ? '' : __t) +
'</a>-->\n          </div>\n        </div>\n        <div class="form-group login-footer">\n          <div class="col-lg-12 text-center">\n            <p>Mobiolink</p>\n            <p>&#169; ' +
((__t = ( year )) == null ? '' : __t) +
'</p>\n          </div>\n        </div>\n      </fieldset>\n    </form>\n    <!-- Hidden iframe for login form autofill -->\n    <iframe class="hidden_iframe-block" name="hidden_iframe"></iframe>';

}
return __p
};});

