define('services/caccounts/standardCurve/standardCurveAssayConfigurationResults',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResults'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.StandardCurveAssayConfigurationResult,
                collection: app.ns.StandardCurveAssayConfigurationResultCollection
            };
        },
        getName: function () {
            return 'standardcurveassayconfigurationresult';
        },
        getDynamicJson: function () {
            return 'StandardCurveAssayConfigurationResultJson';
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'assayConfigurationResult',
                    'param': {'type': 'ASSAYCONFIGURATIONRESULT_FROMASSAY', 'display': {'class': 'col-w-55'}}
                },
                {
                    'field': 'concentration',
                    'param': {'type': 'NUMBER', 'display': {'class': 'col-w-10 m-r-20'}}
                },
                {
                    'field': 'sampleId',
                    'param': {'type': 'TEXT', 'display': {'class': 'col-w-20'}}
                },
                {
                    'field': 'ct',
                    'param': {'type': 'NUMBER', 'display': {'class': 'col-w-6'}}
                },
                {
                    'field': 'show',
                    'param': {
                        'type': 'POPUP',
                        'config': _.bind(this.showResults, this),
                        'display': {'class': 'col-w-5', 'mdi': 'mdi-eye'}
                    }
                }
            ];
        },
        showResults: function (param) {
            require(['standardCurveController'], _.bind(function (Controller) {
                Controller.showResults(this, param);
            }, this));
        }
    }, Dynamic);
});

