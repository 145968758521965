/**
 * Created by bfr on 30/01/2017.
 */
define('runsMainRouter',[
    'require',
    'exports',
    'module',
    'backbone',
    'marionetteAppRouter',
    'app',
    'wellUtils',
    'cssUtils',
    'underscore',
    'contentShowController',
    'itemPagesNameMixin',
    'jquery',
    'sidebarShowController',
    'emptyView',
    'runPcrController'
], function (
    requireJS,
    exports,
    module,
    Backbone,
    AppRouter,
    App,
    WellUtils,
    CssUtils,
    _,
    ContentShowController,
    ItemPagesNameMixin,
    $,
    SidebarShowController,
    EmptyView,
    RunPcrController
) {
    'use strict';

    var RunsRouter = AppRouter.extend({
        appRoutes: {
            'runs/pcr': 'showView',
            'runs/pcr/empty': 'showEmpty',
            'runs/pcr/:runSecId': 'show'
        }
    });

    var API = {
        findModel: function (runSecId, callback) {
            var defer = $.Deferred();
            var PcrRunService = require('services/caccounts/pcrruns');
            var model = PcrRunService.getModel({secId: runSecId});
            model.fetch().done(_.bind(function () {
                this.model = model;
                if (callback) {
                    callback();
                }
                defer.resolve();
            }, this));

            return defer.promise();
        },


        getModel: function (callback) {
            var defer = $.Deferred();
            defer.resolve();
            if (callback) {
                callback(this.model);
            }
            return defer.promise();
        },

        showEmpty: function () {
            CssUtils.applyPcrRunTheme();
        },

        showView: function () {
            this.dynamicShowList({
                entityNamePath: 'caccounts/pcrruns',
                itemPagesNameMixin: ItemPagesNameMixin.RUNS,
                callBackCreateEditView: RunPcrController.showDetails,
                callBackItemClick: _.bind(function (model) {
                    App.navigate('runs/pcr/' + model.get('secId'), {trigger: true});
                }, this)
            });
        },

        show: function (runSecId) {
            $('.js-global-loader').show();
            ContentShowController.showContent().done(_.bind(function () {
                var region = App.regions.getRegion('main').currentView.getRegion('content');
                region.show(new EmptyView());
                WellUtils.dismissRemainingPopovers();
                CssUtils.applyPcrRunTheme();
                this.findModel(runSecId).done(_.bind(function () {
                    requireJS(['runController'], _.bind(function (RunController) {
                        RunController.show(region, this.model);
                        SidebarShowController.setActiveNavItem(ItemPagesNameMixin.RUNS);
                        $('.js-global-loader').hide();
                    }, this));
                }, this));
            }, this));
        },

        dynamicShowList: function (request) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                requireJS([
                    'dynamicController'
                ], function (DynamicController) {
                    DynamicController.showList(
                        App.regions.getRegion('main').currentView.getRegion('content'), {
                            entityNamePath: request.entityNamePath,
                            callBackCreateEditView: request.callBackCreateEditView,
                            callBackItemClick: request.callBackItemClick
                        });
                    SidebarShowController.setActiveNavItem(request.itemPagesNameMixin);
                });
            });
        },

        checkContent: function () {
            var defer = $.Deferred();

            requireJS([
                'contentShowController'
            ], function (ContentShowController) {
                ContentShowController.showContent().done(function () {
                    defer.resolve();
                });
            });
            return defer.promise();
        }
    };

    App.on('before:start', function () {
        new RunsRouter({
            controller: API
        });
    });


    module.exports = RunsRouter;
});

