define('env',['module'
], function (module) {
    'use strict';

    module.exports = {
        config: {
            defaults: {
                serverUrl: 'http://localhost:8085/',
                defaultLogo: '/images/logo.svg',
                obsolete: false,
                folder: ''
            },
            minified: {
                serverUrl: 'http://localhost:8085/',
                defaultLogo: 'images/logo.svg',
                obsolete: false,
                folder: ''
            }
        }
    };
});

