define('services/admin/codelist',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/admin/codelist'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.CodeList,
                collection: app.ns.CodeListCollection
            };
        },
        findItemsByCode: function (code) {
            var url = Settings.url('rest/public/codeLists/code/' + code + '/elements'),
                defer = $.Deferred();

            $.ajax({
                type: 'POST',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },
        getUrl: function () {
            return 'rest/public/codeLists/';
        },
        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.callbackAdd = undefined;
            configuration.callbackEdit = undefined;
            configuration.urlPath = this.getCollectionUrl();
            configuration.values = config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            };
            return configuration;
        },
        getAutocompleteParamForCode: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.callbackAdd = undefined;
            configuration.callbackEdit = undefined;
            configuration.urlPath = 'rest/public/codeLists/code/' + config.codeListCode + '/elements?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            }));
            configuration.canAddOrEdit = false;
            return configuration;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getName: function () {
            return 'codelist';
        },
        getDynamicJson: function () {
            return 'CodeListJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.archivable = false;
            config.selectArchivable = false;
            config.exportable = false;
            config.importable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getTypeDynamicJson: function () {
            return 'ADMIN';
        }
    }, Dynamic);
});

