define('entities/caccounts/standardCurve/standardCurves',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/standardCurve/standardCurveSamples',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResults'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.ns.StandardCurve = Backbone.RelationalModel.extend({
        service: 'services/caccounts/standardCurve/standardCurves',
        fetch: function () {
            this.url = Settings.url('rest/v2/standardCurves/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'descriptionKitLot': '',
            dilution: 10,
            fromCt: null,
            toCt: null,
            samples: [],
            results: []
        },
        idAttribute: 'secId',
        initialize: function () {
            this.listenTo(this, 'change:dilution', _.bind(this.changeDilution, this));
            this.listenTo(this.get('samples'), 'add', _.bind(this.addSample, this));
        },

        addSample: function (model) {
            var at = this.get('samples').indexOf(model);
            model.set('dilution', Math.pow(parseInt(this.get('dilution'), 10), parseInt(at, 10)));
        },

        relations: [{
            type: Backbone.HasMany,
            key: 'samples',
            relatedModel: 'StandardCurveSample',
            collectionType: 'StandardCurveSampleCollection'
        }, {
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'StandardCurveAssayConfigurationResult',
            collectionType: 'StandardCurveAssayConfigurationResultCollection'
        }],

        postUrl: function () {
            return Settings.url('rest/v2/standardCurves/');
        },

        save: function () {
            this.url = this.postUrl();
            this.get('results').each(function (result) {
                result.set('values', new app.ns.StandardCurveAssayConfigurationResultValueCollection([]));
            });
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'StandardCurveJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'StandardCurve',

        importable: false,
        changeDilution: function () {
            this.get('samples').each(_.bind(function (sample, index) {
                sample.set('dilution', Math.pow(parseInt(this.get('dilution'), 10), parseInt(index, 10)));
            }, this));
        }
    });

    app.ns.StandardCurve.role = RolesMixin.STANDARDCURVE;
});

