define('menuGroupQuickActionItem',[
    'module',
    'backbone.marionette',
    'underscore',
    'template!menuItemMdi',
    'template!menuItemCustom'
], function (
    module,
    Marionette,
    _,
    ItemMdi,
    ItemCustom
) {
    'use strict';

    /**
     * @class QuickAction
     *
     * model {
     *     mdi: String
     *     title: String
     *     click: CallableFunction
     * }
     */
    module.exports = Marionette.View.extend({
        getTemplate: function () {
            if (this.model.get('mdi')) {
                return ItemMdi;
            }
            return ItemCustom;
        },
        className: function () {
            var className = 'btn clickable';
            if (this.model.get('className')) {
                className += ' ' + this.model.get('className');
            }
            return className;
        },
        tagName: 'button',
        attributes: function () {
            var attributes = this.model.get('attributes') || {};
            attributes.title = _.i18n(this.model.get('nameCode') + '.popover');
            return attributes;
        },
        events: {
            'click': 'onClick'
        },
        modelEvents: {
            'enable': 'defineEnable',
            'enable:quickAction': 'defineEnable',
            'visible:quickAction': 'defineVisible'
        },
        ui: {
            icon: 'span'
        },
        onClick: function () {
            if (this.$el.hasClass('clickable')) {
                this.model.get('click')();
            }
        },
        serializeData: function () {
            return {
                mdi: this.model.get('mdi'),
                custom: this.model.get('custom'),
                nameCode: this.model.get('nameCode'),
                title: _.i18n(this.model.get('nameCode'))
            };
        },
        defineEnable: function (enable) {
            if (enable) {
                this.$el.removeClass('disabled');
                this.$el.addClass('clickable');
            } else {
                this.$el.addClass('disabled');
                this.$el.removeClass('clickable');
            }
            this.$el.attr('disabled', !enable);
        },
        defineVisible: function (visible) {
            if (visible) {
                this.$el.show();
            } else {
                this.$el.hide();
            }
        },
        defineSelected: function (selected) {
            if (selected) {
                this.$el.addClass('selected');
            } else {
                this.$el.removeClass('selected');
            }
        },
        onRender: function () {
            if (this.model.get('mdiAttributes')) {
                this.ui.icon.attr(this.model.get('mdiAttributes'));
            }
            this.defineEnable(!this.model.get('disabled'));
            this.defineSelected(this.model.get('selected'));
            if (this.model.get('visible')) {
                if (this.model.get('visible')()) {
                    this.$el.show();
                } else {
                    this.$el.hide();
                }
            }
        }
    });
});

