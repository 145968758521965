/**
 * Created by OBL on 14/01/2016.
 */
define('arrayEncodeEditLayoutController',[
    'module',
    'underscore',
    'arrayEncodeEditLayoutView',
    'bootbox',
    'renderer',
    'template!arrayToolTip'
], function (
    module,
    _,
    CreateEditLayoutView,
    Bootbox,
    Renderer,
    ArrayToolTip
) {
    'use strict';

    module.exports = {
        showPopup: function (model, fieldName, arrayParams, arrayCode, view, readOnly, l2ldatas) {
            var ArrayService = require('services/admin/array');
            ArrayService.findByCode(arrayCode).done(_.bind(function (array) {
                if (!array) {
                    Bootbox.alert(_.i18n('array.missing', null, {code: arrayCode}));
                } else {
                    var arrayEncodeEditView = new CreateEditLayoutView({
                        arrayParams: arrayParams,
                        arrayCode: arrayCode,
                        model: model,
                        fieldName: fieldName,
                        viewToRefresh: view,
                        array: array,
                        readOnly: readOnly,
                        l2ldatas: l2ldatas,
                        service: ArrayService
                    });
                    arrayEncodeEditView.show({
                        title: _.i18n('target.algo.parameters.edit'),
                        className: 'baseTableEditAddPopup arrayPopup'
                    });
                }
            }, this));
        },
        generateToolTip: function (target, arrayCode, value) {
            if (!arrayCode) {
                return;
            }
            var ArrayService = require('services/admin/array');
            ArrayService.findByCode(arrayCode).done(_.bind(function (array) {
                if (array === null) {
                    Bootbox.alert('missing array with code ' + arrayCode);
                    return;
                }
                var templateData = {
                    code: array.get('code'),
                    name: array.get('name')
                };
                templateData.items = [];
                var arrayParams = _.isString(value) ? value.split(';') : _.isArray(value) ? value : [];

                array.get('arrayElements').each(function (arrayElement, index) {
                    var item = {key: arrayElement.get('name')};
                    if (arrayParams[index]) {
                        item.value = {value: arrayParams[index], contentType: arrayElement.get('contentType')};
                    } else {
                        item.value = {value: '-', contentType: arrayElement.get('contentType')};
                    }
                    templateData.items.push(item);
                });

                var renderer = new Renderer({template: ArrayToolTip, templateData: templateData});
                renderer.render();
                var content = renderer.$el.html();

                target.attr('data-content', content);
                target.popover({
                    trigger: 'hover',
                    html: 'true',
                    placement: 'bottom',
                    container: 'body'
                });
            }, this));
        }
    };
});

