
define('template!registerViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form target="hidden_iframe" autocomplete="on" class="form-horizontal js-form-submit form-group">\n    <fieldset>\n        <div class="top-login-part text-center">\n            <a class="login-view-link">\n                <div class="avatar-view-link-img"></div>\n            </a>\n            <h2>' +
((__t = ( _.i18n("register.title") )) == null ? '' : __t) +
'</h2>\n        </div>\n        <div class="form-group form-company">\n            <div class="col-lg-12">\n                <input name="company" type="text" class="form-control register-company floating-label" autocomplete="on"\n                       placeholder="' +
((__t = ( _.i18n('common.company.name') )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <div class="form-group">\n            <div class="col-lg-12">\n                <input name="email" type="email" class="form-control register-email floating-label" autocomplete="on"\n                       placeholder="' +
((__t = ( _.i18n('common.emailadress') )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <div class="form-group">\n            <div class="col-lg-12 register-btn">\n                <a class="btn btn-primary btn-lg btn-block register-sign-in">' +
((__t = ( _.i18n("register.create.account")
                    )) == null ? '' : __t) +
'</a>\n            </div>\n        </div>\n        <div class="form-group login-footer">\n            <div class="col-lg-12 text-center">\n                <p>Mobiolink</p>\n                <p>&#169; 2015</p>\n            </div>\n        </div>\n    </fieldset>\n</form>';

}
return __p
};});

