define('standardCurveAssayConfigurationResultView',[
    'module',
    'dialogFormView',
    'template!standardCurveAssayConfigurationResultView',
    'savingBehavior',
    'underscore',
    'settings',
    'standardCurveAssayConfigurationResultPcrRunCollection',
    'jquery',
    'wellsGraphView',
    'standardCurveAssayConfigurationResultGraph',
    'moment'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    Settings,
    StandardCurveAssayConfigurationResultPcrRunCollection,
    $,
    WellsGraphView,
    StandardCurveGraph,
    moment
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            tab: 'li.nav-tab.curve',
            status: '.js-status'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm',
            'click @ui.tab': 'onTabClick',
            'change .chart-CC-change': 'onCCchange',
            'change .chart-legend-change': 'onLegendChange',
            'change .rangeSmooth': 'onRangeSmoothChange'
        },

        regions: {
            'pcrRuns': '.js-pcrRuns',
            graphCurves: '.js-graph-curves',
            graphStandardCurve: '.js-graph-standardCurve'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [
            {name: 'code', type: 'required'}
        ],

        initialize: function () {
            this.model = this.model.get('values').first();

            this.graphToDisplay = '';
            if (this.model.get('assayResultVersion').get('algoResult').type === 'AMP') {
                this.graphToDisplay = 'A';
            } else if (this.model.get('assayResultVersion').get('algoResult').type === 'MUTV') {
                this.graphToDisplay = 'M';
            } else if (this.model.get('assayResultVersion').get('algoResult').type === 'CRI') {
                this.graphToDisplay = 'A';
            } else if (this.model.get('assayResultVersion').get('algoResult').type === 'AMP_IC') {
                this.graphToDisplay = 'A';
            }
            this.currentGraph = this.graphToDisplay.indexOf('A') !== -1 ? 'amplCurves' : 'meltCurves1';
            this.targetToDisplay = this.model.get('assayConfigurationResult');
            this.displayLegend = true;
            this.rangeSmooth = false;
            this.withCC = true;
        },

        serializeData: function () {
            var templateData = {};
            templateData.pcrRun = {
                code: this.model.get('pcrRun').get('code'),
                period: moment(this.model.get('pcrRun').get('period')).format('DD-MM-YYYY')
            };
            templateData.displayCC = this.withCC;
            templateData.rangeSmooth = this.rangeSmooth;
            templateData.displayLegend = this.displayLegend;
            templateData.displayAmpl = this.graphToDisplay.indexOf('A') !== -1;
            templateData.displayMelt = this.graphToDisplay.indexOf('M') !== -1;
            templateData.currentGraph = this.currentGraph;
            templateData.slope = this.model.get('slope').toFixed(2);
            templateData.intercept = this.model.get('intercept').toFixed(2);
            templateData.efficiency = this.model.get('efficiency').toFixed(2);
            templateData.assayCode = this.model.get('assayResultVersion').get('assay').get('code');
            templateData.targetCode = this.model.get('assayResultVersion').get('target').get('code');
            return templateData;
        },
        onRender: function () {
            var view = new StandardCurveAssayConfigurationResultPcrRunCollection({
                collection: this.options.model.get('values'),
                model: this.model
            });
            this.getRegion('pcrRuns').show(view);
            this.listenTo(view, 'pcrRun:click', _.bind(this.onPcrRunClick, this));
            var service = require('services/caccounts/standardCurve/standardCurveAssayConfigurationResults');
            this.setPermissions(service.canAddOrEdit());
            this.displayGraph();
            this.ui.status.val(this.model.get('status'));
            this.ui.status.trigger('change');
        },

        onPcrRunClick: function (model) {
            this.model = model;
            this.render();
        },

        onTabClick: function (e) {
            this.currentGraph = e.currentTarget.attributes['data-graph'].value;
            this.displayGraph();
        },

        displayGraph: function () {
            var view = null;
            switch (this.currentGraph) {
                case 'meltCurvesRaw':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        hideExtraPoint: true
                    });
                    view.setModel(this.model.get('results').pluck('pcrWell'));
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.targetToDisplay = this.targetToDisplay;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = true;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    break;
                case 'meltCurves':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        hideExtraPoint: true
                    });
                    view.setModel(this.model.get('results').pluck('pcrWell'));
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.targetToDisplay = this.targetToDisplay;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    break;
                case 'meltCurves1':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        hideExtraPoint: true
                    });
                    view.setModel(this.model.get('results').pluck('pcrWell'));
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv1Enable = true;
                    view.targetToDisplay = this.targetToDisplay;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    break;
                case 'meltCurves2':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'MELT',
                        hideExtraPoint: true
                    });
                    view.setModel(this.model.get('results').pluck('pcrWell'));
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv2Enable = true;
                    view.targetToDisplay = this.targetToDisplay;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.filterValuesY = null;
                    view.filterValuesX = null;
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    break;
                case 'amplCurvesRaw':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: false,
                        enableSliderVertical: false,
                        hideExtraPoint: true
                    });
                    view.setModel(this.model.get('results').pluck('pcrWell'));
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = true;
                    view.targetToDisplay = this.targetToDisplay;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.minXVal = this.minVal;
                    view.maxXVal = this.maxVal;
                    view.minYVal = this.minValY;
                    view.maxYVal = this.maxValY;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    break;
                case 'amplCurves':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: false,
                        enableSliderVertical: false,
                        hideExtraPoint: true
                    });
                    view.setModel(this.model.get('results').pluck('pcrWell'));
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.targetToDisplay = this.targetToDisplay;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.minXVal = this.minVal;
                    view.maxXVal = this.maxVal;
                    view.minYVal = this.minValY;
                    view.maxYVal = this.maxValY;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    if (this.runAt) {
                        view.baseline = this.runAt.baseline;
                        view.threshold = this.runAt.threshold;
                    }
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    break;
                case 'amplCurves1':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: false,
                        enableSliderVertical: false,
                        hideExtraPoint: true
                    });
                    view.setModel(this.model.get('results').pluck('pcrWell'));
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.amplGraph = true;
                    view.deriv1Enable = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.targetToDisplay = this.targetToDisplay;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    break;
                case 'amplCurves2':
                    view = new WellsGraphView({
                        type: 'line',
                        displayLegend: this.withLegend,
                        rangeSmooth: this.rangeSmooth,
                        algoType: 'AMP',
                        enableSlider: false,
                        enableSliderVertical: false,
                        hideExtraPoint: true
                    });
                    view.setModel(this.model.get('results').pluck('pcrWell'));
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv2Enable = true;
                    view.amplGraph = true;
                    view.legend = this.legend;
                    view.withoutCC = !this.withCC;
                    view.raw = false;
                    view.targetToDisplay = this.targetToDisplay;
                    view.fixedColor = this.fixedColor;
                    view.highlight = this.options.highlight;
                    view.targetColor = this.targetColor;
                    view.onCurveClick = _.bind(this.onCurveClick, this);
                    view.assayResultVersion = this.model.get('assayResultVersion');
                    break;
            }

            if (view) {
                this.getRegion('graphCurves').show(view);
            }

            view = new StandardCurveGraph({
                type: 'line',
                displayLegend: this.withLegend,
                rangeSmooth: this.rangeSmooth,
                algoType: 'AMP',
                enableSlider: false,
                enableSliderVertical: false
            });
            view.options.collection = this.model.get('results').map(function (item) {
                return {'log': item.get('log'), 'ct': item.get('ct'), 'regression': item.get('regression')};
            });
            view.slopeEnable = true;
            view.brutEnable = false;
            view.deriv2Enable = true;
            view.amplGraph = true;
            view.legend = this.legend;
            view.withoutCC = !this.withCC;
            view.raw = false;
            view.targetToDisplay = this.targetToDisplay;
            view.fixedColor = this.fixedColor;
            view.highlight = this.options.highlight;
            view.targetColor = this.targetColor;
            this.getRegion('graphStandardCurve').show(view);
        },

        onLegendChange: function (event) {
            this.withLegend = event.target.checked;
            this.displayGraph();
            this.trigger('well:graph:legend', event.target.checked);
        },

        onRangeSmoothChange: function (event) {
            this.rangeSmooth = event.target.checked ? 1 : 0;
            this.displayGraph();
            this.trigger('well:graph:legend', event.target.checked);
        },

        // cc color compensation filter - <select-option>
        onCCchange: function (event) {
            this.withCC = event.target.checked;
            this.displayGraph();
        },

        onCurveClick: function (well) {
            $('.js-global-loader').show();
            this.model.get('pcrRun').fetch().done(_.bind(function () {
                require(['pcrWellController'], _.bind(function (PcrWellController) {
                    PcrWellController.showDetails({model: well});
                }, this));
            })).always(function () {
                $('.js-global-loader').hide();
            });
        },

        onConfirm: function () {
            this.model.changeStatus(this.ui.status.val()).done(_.bind(function () {
                this.triggerMethod('saved', this.model);
                this.box.modal('hide');
            }, this));
        },

        onSave: function () {
            if (this.enableSave) {
                this.triggerMethod('saved', this.options.model);
                this.box.modal('hide');
            }
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        }
    });
});

