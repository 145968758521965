define('runCreateFromResultFileView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runCreateFromResultFileView',
    'commonCancelUploadView',
    'customBootboxMessage',
    'bootbox',
    'autocompleteView',
    'settings'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonCancelUploadView,
    CustomBootboxMessage,
    Bootbox,
    AutocompleteView,
    Settings
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        regions: {
            cancelUploadRegion: '.js-cancel-upload-region',
            selectCycler: '.js-select-cycler-region',
            selectRespUser: '.js-select-user-region'
        },
        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },
        modelEvents: {
            'change:cycler': 'onValidateChange',
            'change:respUser': 'onValidateChange'
        },

        fieldsToValidate: function () {
            return [
                {name: 'cycler', type: 'required'},
                {name: 'respUser', type: 'required'}
            ];
        },

        initialize: function () {
            this.model = new Backbone.Model({
                cycler: this.options.defaultCycler,
                respUser: Settings.get('currentUserModel')
            });
        },

        onValidateChange: function () {
            this.commonCancelUploadView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onRender: function () {
            this.commonCancelUploadView = new CommonCancelUploadView({settingImport: this.options.settingImport, accept: '.xlsx,.xls,.xml,.rdml,.csv,.txt', multiple: true});
            this.listenTo(this.commonCancelUploadView, 'upload:click', _.bind(this.onUpload, this));
            this.listenTo(this.commonCancelUploadView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelUploadRegion').show(this.commonCancelUploadView);
            this.commonCancelUploadView.enableButtons(false);

            var CyclerPublicService = require('services/caccounts/cyclerpublics');
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(CyclerPublicService.getAutocompleteParam({
                    modelProperty: 'cycler'
                }))
            );
            this.getRegion('selectCycler').show(view);

            var UserPidService = require('services/caccounts/userpids');
            view = new AutocompleteView(
                this._getAutocompleteOptionObject(UserPidService.getAutocompleteParam({
                    modelProperty: 'respUser',
                    valueProperty: 'nickName',
                    queryProperty: 'nickName',
                    valueKey: 'nickName'
                }))
            );
            this.getRegion('selectRespUser').show(view);
            this.commonCancelUploadView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onUpload: function (files) {
            var loading = $('.js-global-loader');
            loading.show();
            this.model.set('files', files);
            this.options.service.createRunFromResult(this.model)
                .done(_.bind(function () {
                    this.hide();
                    if (this.options.callback) {
                        this.options.callback(arguments);
                    }
                }, this))
                .fail(_.bind(function (response) {
                    alert(response.statusText + ' -- ' + response.status);
                }, this))
                .always(function () {
                    loading.hide();
                });
        }
    });
});
