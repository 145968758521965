define('pcrWellResultOvarItemView',[
    'module',
    'backbone.marionette',
    'template!pcrWellResultOvarItemView',
    'underscore',
    'jquery',
    'editWellView',
    'wellTargetPropCPopupView',
    'colorUtils',
    'pcrWellResultController'
], function (
    module,
    Marionette,
    WellResultTpl,
    _,
    $,
    EditWellView,
    WellTargetPropCPopupView,
    ColorUtils,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: WellResultTpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'click .btn-validate-result': 'onConfirmResult',
            'click .btn-negative-result': 'onNegativeResult',
            'click .btn-edit-well-target-result': 'onEditWellTargetResult',
            'click .js-target-propC-popup': 'onTargetPropC'
        },

        modelEvents: {
            'change': 'renderSafe'
        },

        triggers: {
            'click .mdi-format-list-bulleted': 'show:properties'
        },

        regions: {
            result: '.js-result'
        },

        className: 'result result-ovar',

        renderSafe: function () {
            if (!this.isDestroyed()) {
                this.render();
            }
        },

        onTargetPropC: function () {
            this.propCPopupView = new WellTargetPropCPopupView({
                model: this.model
            });
            this.propCPopupView.show({
                title: _.i18n('wellTarget.result.interpretation'),
                className: 'js-draggable-modal wellTargetPropC-popup'
            });
            $('.js-draggable-modal  .modal-dialog').draggable({
                handle: '.modal-header'
            });
        },

        serializeData: function () {
            var wellResColor = 'transparent';
            if (this.model.get('result')) {
                wellResColor = this.model.get('result').get('color');
            }
            var templateData = {
                refPcrWellExported: this.model.get('refPcrWell') ? this.model.get('refPcrWell').get('exported') : false,
                mbAna: this.model.get('mbAna') ? this.model.get('mbAna').get('code') : undefined,
                result: this.model.get('result') ? this.model.get('result').get('code') : '',
                cts: this.model.get('cts'),
                quantifications: this.model.get('quantifications'),
                comment: this.model.get('comment'),
                codeErr: this.model.get('codeErr'),
                valSt: this.model.get('valSt'),
                needValidation: this.model.get('needValidation'),
                readOnly: (this.options.readOnly !== undefined && this.options.readOnly) || this.model.isReadOnly()
            };
            templateData.resBackgroundColor = wellResColor ? ColorUtils.defineAlpha(wellResColor, '30') : 'transparent';
            templateData.resBarColor = wellResColor ? wellResColor : 'transparent';

            templateData.userVal1Code = '';
            templateData.userVal1Title = '';
            if (this.model.get('refValUser')) {
                templateData.userVal1Code = this.model.get('refValUser').get('nickName');
                if (this.model.get('refValUser').get('email')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('email');
                }
                if (templateData.userVal1Code === '' && templateData.userVal1Title) {
                    templateData.userVal1Code = templateData.userVal1Title;
                }
                if (this.model.get('refValUser').get('firstName') && this.model.get('refValUser').get('lastName')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('firstName') + ' ' + this.model.get('refValUser').get('lastName');
                }
            }
            templateData.userVal2Code = '';
            templateData.userVal2Title = '';
            if (this.model.get('refValUser2')) {
                templateData.userVal2Code = this.model.get('refValUser2').get('nickName');
                if (this.model.get('refValUser2').get('email')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('email');
                }
                if (templateData.userVal2Code === '' && templateData.userVal2Title) {
                    templateData.userVal2Code = templateData.userVal2Title;
                }
                if (this.model.get('refValUser2').firstName && this.model.get('refValUser2').get('lastName')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('firstName') + ' ' + this.model.get('refValUser2').get('lastName');
                }
            }

            templateData.errorMessage = '';
            if (templateData.codeErr) {
                templateData.errorMessage = templateData.codeErr + ' ';
            }
            if (templateData.justificatory) {
                templateData.errorMessage = templateData.justificatory;
            }

            templateData.hasProperties = this.model.get('properties') && this.model.get('properties').length > 0;

            return templateData;
        },

        onConfirmResult: function () {
            $('.js-global-loader').show();
            this.model.confirmResult()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onNegativeResult: function () {
            $('.js-global-loader').show();
            this.model.negativeResult()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onEditWellTargetResult: function () {
            this.showEditWellView();
        },

        showEditWellView: function () {
            this.createEditView = new EditWellView({
                model: this.model.get('refPcrWell'),
                modelResult: this.model,
                wellResColor: this.wellResColor,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.info.edit'),
                className: 'popupWellView'
            });
        },
        onRender: function () {
            PcrWellResultController.show(this.model, 'WellTarget', this.getRegion('result'));
        }
    });
});

