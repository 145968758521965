define('prepRunEditLayoutView',[
    'module',
    'dialogFormView',
    'template!prepRunEditLayoutTpl',
    'prepRunEditRunNameView',
    'savingBehavior',
    'underscore',
    'jquery',
    'app',
    'settings',
    'autocompleteView',
    'fieldUtils',
    'dateUtils',
    'wellUtils'
], function (
    module,
    DialogFormView,
    createEditLayoutTpl,
    CreateEditRunNameView,
    SavingBehavior,
    _,
    $,
    App,
    Settings,
    AutocompleteView,
    FieldUtils,
    DateUtils,
    WellUtils
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            input: '.js-info-input',
            plateSizeNb: '.js-run-plate-size-Nb',
            periodDt: '.js-period',
            copyParameters: '.js-copyParameters',
            extraction: '.extraction'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'click .js-edit-runName': 'onEditRunName'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            error: 'onSaveError',
            change: 'renderSafe',
            'change:toBePooled': 'onToBePooledChange'
        },

        regions: {
            selectRespUser: '.js-select-respUser-region'
        },

        fieldsToValidate: [
            {name: 'name', type: 'required'}
        ],

        className: 'container-fluid',

        renderSafe: function () {
            if (!this.isDestroyed()) {
                this.render();
            }
        },

        onEditRunName: function () {
            this.createEditRunNameView = new CreateEditRunNameView({
                model: this.model.clone(),
                runModel: this.model,
                settings: this.model.get('settings')
            });
            this.createEditRunNameView.show({
                title: _.i18n('prepRun.editRunName'),
                className: 'runEditAddPopup'
            });
            this.createEditRunNameView.listenTo(this.createEditRunNameView, 'hide:editRunNameView', _.bind(this.destroyEditView, this));
        },

        destroyEditView: function () {
            this.createEditRunNameView = null;
        },

        serializeData: function () {
            var templateData = {
                name: this.model.get('name'),
                toBePooled: this.model.get('toBePooled'),
                extractionDetails: this.model.get('extractionDetails'),
                plateIdDW: this.model.get('plateIdDW'),
                plateIdEL: this.model.get('plateIdEL'),
                comment: this.model.get('comment')
            };

            templateData.pcrRuns = this.model.get('pcrRuns').map(function (pcrRun) {
                return {code: pcrRun.get('code'), name: pcrRun.get('name')};
            });

            templateData.prepRunPooled = _.uniq(_.map(this.model.get('wells').filter(function (well) {
                return !well.get('wellsPooled').isEmpty();
            }), function (pcrRun) {
                return {name: pcrRun.get('run').get('name')};
            }), 'name');

            var dpgsToDisplay = {
                department: this.model.get('department') ? this.model.get('department') : ' ',
                periodFormatted: this.model.get('periodFormatted') ? this.model.get('periodFormatted') : ' ',
                group: this.model.get('group') ? this.model.get('group') : ' ',
                sequenceFormatted: FieldUtils.getSequenceFormatted(this.model.get('sequence'), this.model.get('sequenceFormat'))
            };

            templateData.dpgs = dpgsToDisplay.department + ' - ' + dpgsToDisplay.periodFormatted + ' - ' + dpgsToDisplay.group + ' - ' + dpgsToDisplay.sequenceFormatted;
            templateData.creatDateToDisplay = DateUtils.toDateTimeFormatStringFromSettings(this.model.get('creatDate'), this.model.get('settings'));
            templateData.wellsSizePlateIcon = WellUtils.getWellsSizePlateIcon(this.model.get('wells').size());
            templateData.extractionMethodExtractor = '';
            if (this.model.get('refExtractionMethod') &&
                this.model.get('refExtractionMethod').refExtractor &&
                this.model.get('refExtractionMethod').refExtractor.code
            ) {
                templateData.extractionMethodExtractor = this.model.get('refExtractionMethod').refExtractor.code;
            }

            templateData.assaysToDisplay = this.model.getFormattedRunAssayStatus();

            return templateData;
        },

        onRender: function () {
            this.onToBePooledChange();
            this.showRefRespUserDropDown();
            App.trigger('forClipboard', this.ui.copyParameters);
        },

        showRefRespUserDropDown: function () {
            var UserpidService = require('services/caccounts/userpids');
            var respUserView = new AutocompleteView(
                this._getAutocompleteOptionObject(UserpidService.getAutocompleteParam({
                    modelProperty: 'refRespUser',
                    valueProperty: 'nickName',
                    queryProperty: 'nickName',
                    valueKey: 'nickName',
                    callBackOnChange: this.onRespUserChange
                }))
            );
            this.getRegion('selectRespUser').show(respUserView);
        },

        onRespUserChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.save();
            }
        },

        save: function () {
            $('.js-global-loader').show();
            this.model.saveHeader()
                .done(_.bind(function () {
                    this.box.modal('hide');
                }, this))
                .fail(_.bind(function (error) {
                    console.error(error);
                    this.model.fetch();
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onCancel: function () {
            $('.js-global-loader').show();
            this.model.fetch().done(_.bind(function () {
                this.hide();
            }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },

        onToBePooledChange: function () {
            if (this.model.get('toBePooled')) {
                this.ui.extraction.hide();
            } else {
                this.ui.extraction.show();
            }
        }
    });
});

