define('runPcrKitsLotsView',[
    'module',
    'dialogFormView',
    'template!runPcrKitsLotsTpl'
], function (
    module,
    DialogFormView,
    runPcrKitsLotsTpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: runPcrKitsLotsTpl,

        className: 'd-f',

        serializeData: function () {
            return {kitLots: this.options.kitLots ? this.options.kitLots : []};
        }
    });
});
