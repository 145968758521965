define('entities/caccounts/standardCurve/standardCurveAssayConfigurationResults',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResultValues',
    'entities/caccounts/assayConfigurationResult'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.ns.StandardCurveAssayConfigurationResult = Backbone.RelationalModel.extend({
        service: 'services/caccounts/standardCurve/standardCurveAssayConfigurationResults',
        defaults: {
            'values': [],
            assayConfigurationResult: null,
            concentration: null,
            sampleId: null,
            ct: null,
            commentary: null
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'values',
            relatedModel: 'StandardCurveAssayConfigurationResultValue',
            collectionType: 'StandardCurveAssayConfigurationResultValueCollection'
        }, {
            type: Backbone.HasOne,
            key: 'assayConfigurationResult',
            relatedModel: 'AssayConfigurationResult'
        }],
        getImageUid: function () {
        }
    });

    app.ns.StandardCurveAssayConfigurationResult.role = RolesMixin.STANDARDCURVE;

    app.ns.StandardCurveAssayConfigurationResultCollection = Backbone.Collection.extend({
        model: app.ns.StandardCurveAssayConfigurationResult
    });
});

