define('services/caccounts/lisRecordQueries',[
    'entities/ns',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/lisRecordQueries'
], function (
    app,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.LisRecordQuery,
                collection: app.ns.LisRecordQueryCollection
            };
        },
        getName: function () {
            return 'lisrecord';
        },
        getUrl: function () {
            return 'rest/v2/export/result/ASTM/';
        },
        getDynamicJson: function () {
            return 'LisRecordJson';
        },
        getCreateEditLine: function () {
            return [
                {'field': 'createdDate', 'param': {'type': 'DATETIME', 'display': {'class': 'col-xs-2'}}},
                {'field': 'responseDate', 'param': {'type': 'DATETIME', 'display': {'class': 'col-xs-2'}}},
                {'field': 'responseType', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-2'}}},
                {'field': 'assay', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-1'}}},
                {'field': 'targetCode', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-1'}}},
                {'field': 'resultCode', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-1'}}},
                {'field': 'cts', 'param': {'type': 'TEXT', 'display': {'class': 'col-xs-1'}}},
                {
                    'field': 'result',
                    'param': {
                        'type': 'POPUP',
                        'config': this.showResults,
                        'display': {'class': 'col-w-5', 'mdi': 'mdi-eye'}
                    }
                },
                {
                    'field': 'query',
                    'param': {
                        'type': 'POPUP',
                        'config': this.showQuery,
                        'display': {'class': 'col-w-5', 'mdi': 'mdi-eye'}
                    }
                },
                {
                    'field': 'retry',
                    'param': {
                        'type': 'POPUP',
                        'config': this.retry,
                        'display': {
                            'class': 'col-w-5', 'mdi': 'mdi-refresh',
                            'condition': {
                                key: 'responseType',
                                value: 'FAILED'
                            }
                        }
                    }
                }
            ];
        },
        showResults: function (param) {
            require(['adminExportStatusController'], function (Controller) {
                Controller.showResults(param);
            });
        },
        showQuery: function (param) {
            require(['adminExportStatusController'], function (Controller) {
                Controller.showQuery(param);
            });
        },
        retry: function (param) {
            $('.js-global-loader').show();
            param.model.retry().always(function () {
                $('.js-global-loader').hide();
            });
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getConfigCreateEditLine: function () {
            return {
                editButton: false
            };
        }
    }, Dynamic);
});

