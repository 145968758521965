/**
 * Created by dev on 10-02-16.
 */
define('autoLoginController',[
    'module',
    'underscore',
    'app',
    'settings'
], function (
    module, _, App, Settings
) {
    'use strict';

    module.exports = {
        autoLogin: function (userSecId, tokenId) {
            Settings.set('currentUser', null);
            Settings.set('currentUserGlobal', null);
            Settings.set('currentCAccount', null);
            Settings.set('tokenId', tokenId);
            var SessionService = require('services/session');
            var getUser = SessionService.getSessionByToken(
                {tokenId: tokenId, userSecId: userSecId}
            );

            getUser.done(_.bind(function (response) {
                Settings.set('currentUserGlobal', response);
                App.trigger('caccounts:list');
            }, this));
        }
    };
});
