define('services/caccounts/orders',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/orders'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.Order,
                collection: app.ns.OrderCollection
            };
        },
        getName: function () {
            return 'order';
        },
        getUrl: function () {
            return 'rest/v2/orders/';
        },
        getDynamicJson: function () {
            return 'OrderJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.creatable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 80
            }, {
                label: _.i18n('order.specie'),
                name: 'specie',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'specie.code',
                width: 40
            }, {
                label: _.i18n('order.samples'),
                name: 'samples',
                formatter: viewDynamic.codeNameListFormatter,
                search: true,
                sortable: false,
                index: 'sample.code'
            }, {
                label: _.i18n('order.created'),
                name: 'creatDate',
                formatter: _.bind(viewDynamic.dateTimeFormatterFromSettings, this), // this formatter need DATEFMT from settings
                search: true,
                index: 'creatDate',
                sorttype: 'date',
                formatoptions: {newformat: 'dd/mm/yy'},
                searchrules: {date: true},
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                },
                width: 90
            }, {
                label: _.i18n('order.expirationDate'),
                name: 'expirationDate',
                formatter: _.bind(viewDynamic.dateTimeFormatterFromSettings, this), // this formatter need DATEFMT from settings
                search: true,
                index: 'expirationDate',
                sorttype: 'date',
                sortable: false,
                searchrules: {date: true},
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                },
                width: 90
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'creatDate';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        }
    }, Dynamic);
});

