define('services/caccounts/kitprotconfiguration',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/kitprotconfiguration'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.KitProtConfiguration,
                collection: app.ns.KitProtConfigurationCollection
            };
        },
        getName: function () {
            return 'kitProtConfiguration';
        },
        getUrl: function () {
            return 'rest/v2/kitProtConfiguration/';
        },
        getDynamicJson: function () {
            return 'KitProtConfigurationJson';
        },
        getJqGridColumn: function () {
            return new Error('can\'t get grid column');
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'name';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'name';
            configuration.urlPath = config.url ? config.url : this.getCollectionUrl();
            configuration.values = config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'kitprot.code'
            };
            configuration.valueKey = config.valueKey ? config.valueKey : 'name';
            return configuration;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refKitProt.code', 'refCycler.code']};
        },
        getCreateEditFieldValidate: function () {
            var array = Dynamic.getCreateEditFieldValidate();
            array.push({name: 'refCycler', type: 'required'});
            return array;
        },
        getCreateEditLine: function () {
            var CyclerService = require('services/caccounts/cyclerpublics');
            return [
                {
                    'field': 'refCycler',
                    'param': {
                        'type': 'REFERENCE', 'config': {'getAutocompleteParam': CyclerService.getAutocompleteParam},
                        'context': CyclerService,
                        'display': {'class': 'col-w-20'}
                    }
                },
                {'field': 'protocolNN', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45'}}},
                {'field': 'protocolRN', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45'}}},
                {'field': 'protocolNM', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45'}}},
                {'field': 'protocolRM', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45'}}}
            ];
        }
    }, Dynamic);
});

