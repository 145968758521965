define('propertiesController',[
    'module',
    'underscore',
    'jquery',
    'entities/ns',
    'pcrWellDetailsView',
    'pcrWellToolTipView',
    'bootbox'
], function (
    module,
    _,
    $,
    app,
    PcrWellDetailsView,
    PcrWellToolTipView,
    Bootbox
) {
    'use strict';

    module.exports = {
        showProperties: function (model) {
            if(!model.get('properties')) {
                Bootbox.alert(_.i18n('no.properties'));
                return;
            }
            var properties = _.map(model.get('properties'), function (property) {
                return {
                    name: property.name,
                    value: property.value
                };
            });
            var message = '<h1>' + _.i18n('properties') + '</h1>';
            message += '<table class="table table-striped">';
            message += '<thead>';
            message += '<tr>';
            message += '<th>' + _.i18n('property.name') + '</th>';
            message += '<th>' + _.i18n('property.value') + '</th>';
            message += '</tr>';
            message += '</thead>';
            message += '<tbody>';
            _.each(properties, function (property) {
                message += '<tr>';
                message += '<td>' + property.name + '</td>';
                message += '<td>' + property.value + '</td>';
                message += '</tr>';
            });
            message += '</tbody>';
            message += '</table>';
            Bootbox.dialog({
                message: message,
                title: _.i18n('properties'),
                className: 'noIcon',
                buttons: {
                    cancel: {
                        label: '<i></i>',
                        className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
                    }
                }
            });
        }
    };
});

