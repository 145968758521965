define('standardCurveAssayConfigurationResultPcrRunItem',[
    'module',
    'backbone.marionette',
    'template!standardCurveAssayConfigurationResultPcrRunItem',
    'underscore',
    'jquery',
    'autocompleteView',
    'dialogFormView'
], function (
    module,
    Marionette,
    Tpl,
    _,
    $,
    AutocompleteView,
    DialogFormView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {},

        events: {
            'click': 'onClick'
        },

        regions: {},

        className: 'rounded clickable',

        attributes: function () {
            return {style: 'margin-bottom: 10px;'};
        },

        serializeData: function () {
            var templateData = {};
            templateData.code = this.model.get('pcrRun').get('code');
            return templateData;
        },

        onClick: function () {
            this.trigger('item:click', this);
        },

        onRender: function () {
            if (this.options.selected) {
                this.$el.css('background-color', '#e6e6e6');
            }
        }
    });
});
