
define('template!prepRunWellTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="rightSideAbsolute">\n    <div class="btn-group-vertical">\n        <button class="wellButton js-btn-kitLot">\n            <span class="mdi mdi-flask-outline clickable"></span>\n        </button>\n        ';
 if(well.smpType === 'U') { ;
__p += '\n        <button class="wellButton js-btn-sample">\n            <span class="mdi mdi-alpha-s clickable"></span>\n        </button>\n        <button class="wellButton js-btn-comment">\n            <span class="mdi mdi-alpha-c clickable"></span>\n        </button>\n        ';
 } ;
__p += '\n        ';
 if(error) { ;
__p += '\n        <button class="wellButton">\n            <span class="mdi mdi-alert-octagram clickable"></span>\n        </button>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';
 if(otherWell && otherWell !== 'other') { ;
__p += '\n<div class="iconTop">\n    ';
 if(otherWell === 'repeat') { ;
__p += '\n    <span class="repeatStatus repeat-' +
((__t = ( repeatStatus )) == null ? '' : __t) +
'"></span>\n    ';
 } ;
__p += '\n</div>\n';
 } ;
__p += '\n\n<div class="watermark">\n    ';
 if(otherWell === 'repeat') { ;
__p += '\n    <span class="repeatStatus repeat-' +
((__t = ( repeatStatus )) == null ? '' : __t) +
'"></span>\n    ';
 } ;
__p += '\n</div>\n\n<span>\n    <div class="bioGroupColorBar" style="height: 5px; width: 100%; position: absolute;"></div>\n</span>\n\n<div class="content-cell">\n    <!-- repeatOpacity : classe nécessaire si on affiche une icone sur la cellule -->\n    <div class="js-well-type d-f"></div>\n    <div class="d-f">\n        <div class="js-well-sample" style="width: calc(100% - 20px);"></div>\n        ';
 if(otherWell === 'other') { ;
__p += '\n        <span class="repeatStatus repeat-other" style="display:flex; width:15px; height: 15px; justify-content: flex-end;"></span>\n        ';
 } ;
__p += '\n    </div>\n    ' +
((__t = ( assaysToDisplay.assaysIcons.waiting )) == null ? '' : __t) +
'\n    <div class="noWrapHidden cs-sample-font">\n        ' +
((__t = ( assaysToDisplay.waiting )) == null ? '' : __t) +
'\n    </div>\n    ' +
((__t = ( assaysToDisplay.assaysIcons.running )) == null ? '' : __t) +
'\n    <div class="noWrapHidden cs-sample-font">\n        ' +
((__t = ( assaysToDisplay.running )) == null ? '' : __t) +
'\n    </div>\n    ' +
((__t = ( assaysToDisplay.assaysIcons.done )) == null ? '' : __t) +
'\n    <div class="noWrapHidden cs-sample-font">\n        ' +
((__t = ( assaysToDisplay.done )) == null ? '' : __t) +
'\n    </div>\n</div>\n';

}
return __p
};});

