/**
 * Created by GSP on 4/08/2015.
 */
define('editTemplatePluginView',[
    'module',
    'backbone.marionette',
    'underscore',
    'app',
    'iframeFullScreen',
    'template!editTemplatePluginTpl'
], function (
    module,
    Marionette,
    _,
    App,
    IframeFullScreen,
    layoutTpl
) {
    'use strict';

    var EditTemplatePluginView = Marionette.View.extend({
        template: layoutTpl,

        behaviors: {
            IframeFullScreen: {
                behaviorClass: IframeFullScreen
            }
        },

        onRender: function () {
            this.$el = this.$el.children();
            this.$el.unwrap();
            this.setElement(this.$el);
        },

        serializeData: function () {
            return {
                url: this.options.url,
                title: _.i18n('common.editTemplate')
            };
        }
    });

    module.exports = EditTemplatePluginView;
});
