define('runPcrValidationMainWellView',[
    'module',
    'underscore',
    'jquery',
    'jqgridView',
    'wellUtils',
    'colorUtils',
    'pcrWellResultController'
], function (
    module,
    _,
    $,
    JqGridView,
    WellUtils,
    ColorUtils,
    PcrWellResultController
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: _.template('<table class="wellTable" id="jq-grid-list" role="grid"></table>'),
        filtersName: 'wellViewTableListFilters',
        className: 'content',

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqgRow: 'tr.jqgrow',
                blockLoading: '.empty-page-loader'
            }, this.gridUi);
        },

        attributes: {
            style: 'flex: 1; height: 100%;'
        },

        modelEvents: {
            'change:assayResultVersion': 'render'
        },

        serializeData: function () {
            var templateData = {};
            templateData.editEnable = !!this.channelToDisplay;
            templateData.sortOn = this.sortOn;
            templateData.multiRes = this.multiRes;
            return templateData;
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 384,
                currentPage: 1
            };
        },

        gridOptions: function (data) {
            var that = this,
                defaultsOptions = this.gridInitOptions(data),
                options = {
                    colModel: [
                        {
                            label: '',
                            name: 'secId',
                            key: true,
                            hidden: true
                        }, {
                            label: ' ',
                            name: 'select',
                            sortable: false,
                            search: false,
                            classes: 'select-link-action ignore-row-click',
                            formatter: this.rowSelectorFormatter,
                            fixed: true,
                            width: 25,
                            checked: true
                        }, {
                            label: this.getSmpTypeLabelIcon(),
                            name: 'smpType',
                            formatter: _.bind(this.smptypeFormater, this),
                            search: true,
                            sortable: true,
                            index: 'smpType',
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {sopt: ['eq'], value: this.smpTypeList, clearSearch: false},
                            fixed: true,
                            width: 30
                        }, {
                            label: _.i18n('well.pos'),
                            name: 'pos',
                            formatter: this.wellPosFormatter,
                            classes: 'css-grid-cell-accentuatuion-03',
                            sortable: true,
                            title: false,
                            index: 'pos',
                            fixed: true,
                            width: 50,
                            search: true,
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            }
                        }, {
                            label: _.i18n('well.smpId'),
                            name: 'smpId.name',
                            formatter: _.bind(this.wellSmpidFormatter, this),
                            classes: 'css-grid-cell-accentuatuion-02',
                            search: true,
                            sortable: true,
                            title: false,
                            index: 'smpId.name',
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            },
                            fixed: true,
                            width: 130
                        }, {
                            label: _.i18n('well.validation'),
                            name: 'valWst',
                            formatter: this.validationFormatter,
                            search: false,
                            width: 40
                        }, {
                            label: _.i18n('well.icon'),
                            name: 'secId',
                            formatter: this.iconWellFormatter,
                            search: false,
                            width: 40
                        }, {
                            label: _.i18n('well.result'),
                            name: 'wellResSearchValue',
                            classes: 'well-list-label-wellRes',
                            formatter: _.bind(this.wellResIconCodeNumFormatter, this),
                            search: true,
                            sortable: true,
                            title: false,
                            searchoptions: {sopt: ['cn'], clearSearch: false},
                            index: 'wellResSearchValue',
                            fixed: true,
                            width: 110
                        }
                    ],
                    sortname: this.sortOn ? this.sortOn : 'row',

                    gridComplete: function () {
                        defaultsOptions.gridComplete.apply(this, arguments);

                        var $item = that.$('.js-wellRes-color-cell');
                        _.each($item, _.bind(function (item) {
                            var $item = $(item);
                            var color = $item.attr('data-color');
                            var rgbObj = ColorUtils.hexToRgba(color);
                            var rgbaCol = 'rgba(' + rgbObj.r + ',' + rgbObj.g + ',' + rgbObj.b + ', 0.2) !important';
                            $item.parent().parent().parent().attr('style', 'background-color: ' + rgbaCol + ';');
                        }, this));
                    },

                    onSelectRow: _.bind(function (rowId, bool, e) {
                        var well = this.model.get('wellSelected').find(function (obj) {
                            return obj.get('secId') === rowId;
                        });
                        if ($(e.target).parent().hasClass('select-link-action')) {
                            this.checkOneRow(e);
                            if ($(e.target).hasClass('mdi-checkbox-marked-outline')) {
                                this.trigger('well:check', well);
                            } else {
                                this.trigger('well:uncheck', well);
                            }
                            return;
                        }
                        if (!$(e.target).parent().hasClass('ignore-row-click') && e.type === 'click' && !$(e.target).is('input[type=checkbox]')) {
                            require(['pcrWellController'], function (PcrWellController) {
                                PcrWellController.showDetails({model: well});
                            });
                        }
                    }, this)
                };
            var max = this.model.get('wellSelected').chain().map(function (well) {
                if (!well.get('assayVersion')) {
                    return null;
                }
                var results = well.get('assayVersion').get('results');
                return results.chain().filter(function (result) {
                    return result.get('sequenceValidation');
                }).value().length;
            }).filter(function (count) {
                return count && count > 0;
            }).uniq().value();

            max = _.first(max);

            var width = 20;

            var location = this.model.get('run').get('displayMode').findParameterForLocation('Val');
            var paramSplitted = location.get('parameter').param.split(';');
            var isHorizontal = location.get('parameter').type === 'HISTOGRAM' && paramSplitted.length > 11 && paramSplitted[11] === 'HORIZONTAL';
            if (isHorizontal) {
                if (max < 8) {
                    width = 60;
                } else {
                    width = 40;
                }
            }

            for (var i = 1; i <= max; i++) {
                options.colModel.push({
                    label: i % 10 === 0 ? '0' : i % 10,
                    name: 'target_' + i,
                    formatter: _.bind(this.resultFormatter, this),
                    search: false,
                    sortable: false,
                    sequence: i,
                    width: width
                });
            }
            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        },


        wellPosFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellPosPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="position: relative;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="position: relative;">' + cellValue + '</span>';
        },

        wellSmpidFormatter: function (cellValue, options, object) {
            if (!cellValue) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellSmpidPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
        },

        wellResIconCodeNumFormatter: function (cellValue, call, object) {
            var start, end;
            var wellResColor = false;

            if (object && object.wellRes) {
                if (object.pos) {
                    start = '<div class="row m-b-0 ovrerallResultPopover-' + object.pos + ' ">';
                } else {
                    start = '<div class="row m-b-0">';
                }

                if (object.wellRes.get('result') && object.wellRes.get('result').get('color')) {
                    wellResColor = object.wellRes.get('result').get('color');
                    start += '<div class="wellRes-tableView js-wellRes-color-cell" data-color="' + wellResColor +
                        '" style="width: 5px; height: 20px; background-color: ' + wellResColor + ';"></div>';
                }

                start += '<span class="p-l-5" style="min-width: 5px; min-height: 20px; font-size:12px;">' +
                    (object.wellRes.get('result') && object.wellRes.get('result').get('code') ? object.wellRes.get('result').get('code') : '') + '</span>';

                start += '<span class="p-l-5" ' +
                    'style="min-width: 5px; min-height: 20px; font-size:12px;">' +
                    (object.wellRes.get('cts') ? object.wellRes.get('cts').join(';') : '') + '</span>';
                end = '</div>';
                return start + end;
            } else {
                return '';
            }
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15">';

            if (cellValue === 'NC') {
                start += '<span class="mdi-content-remove-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi-content-add-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="iconify" data-icon="ant-design:copyright-circle-filled" style="font-size: 17px;"></span>';
            } else if (cellValue === 'RC') {
                start += '<span class="iconify" data-icon="bxs:registered" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="iconify" data-icon="mdi:google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        },

        onCheckRowCount: function (rowCount) {
            if (rowCount === 0) {
                this.trigger('well:check-rowcount');
            }
        },
        onGridLoaded: function (model, rowId) {
            var listViewData = this.getListViewData(this.model.get('wellSelected'));
            this.setGridData(listViewData, rowId);
        },
        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            var data = {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
            return data;
        },
        getWellListViewData: function (wells) {
            var wellsForData = wells.map(function (well) {
                return well.toJSON();
            });
            return {
                wellsForData: wellsForData
            };
        },
        setGridData: function (data, rowId) {
            /** */ this.ui.jqGrid.clearGridData(); /** needed in case of refresh */
            /** */ this.ui.jqGrid.setGridParam(data, true); //overwrite param is needed true to avoid maximum call stack error on grid refresh
            /** needed in case of refresh */
            this.gridOptionsObj = this.gridOptions(data);
            this.onRowCountDone(data, 9999);
            this.triggerMethod('checkRowCount', 9999);
            if (rowId) {
                /** */ this.ui.jqGrid.trigger('reloadGrid', [{current: true}]);
                /** needed in case of refresh */
                this.ui.jqGrid.setSelection(rowId, false); // highlight selected row
            }
            this.setWellListPopover();
        },
        setWellListPopover: function () {
            this.model.get('wellSelected').each(_.bind(function (well, index) {
                if (well.get('pos') && $('.wellAssayPopover-' + well.get('pos')).length > 0) {

                    var params = {
                        model: well
                    };

                    params.targets = [
                        $('.wellAssayPopover-' + well.get('pos'))
                    ];

                    if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                        (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                        params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                    }
                    var placement = 'bottom';
                    if (index > this.model.get('wellSelected').length - 5) {
                        placement = 'top';
                    }

                    params.placement = placement;
                    WellUtils.showPcrWellPopover(params);
                }
            }, this));
        },
        resultFormatter: function (cellValue, call, object) {
            var model = this.model.get('wellSelected').findWhere({secId: object.secId});
            var html = '';
            var result = model.get('results').chain().filter(function (result) {
                return result.get('refAssayResult').get('sequenceValidation') === call.colModel.sequence;
            }).first().value();
            if (result) {
                html = PcrWellResultController.generateContent(result, 'Val');
            }
            return html;
        }
    });
});

