/**
 * Created by GSP on 15/09/2015.
 */
/**
 * Created by RKL on 10/09/2015.
 */
define('chartView',[
    'module',
    'logger',
    'backbone.marionette',
    'underscore',
    'jquery',
    'chart',
    'template!chartView'
], function (
    module,
    Logger,
    Marionette,
    _,
    $,
    Chart,
    viewTpl
) {
    'use strict';

    module.exports = Marionette.View.extend({

        template: viewTpl,

        ui: {
            chart: '.chart-canvas',
            slider: '.slider-range',
            sliderVert: '.slider-range-vert',
            resetSliderVert: '.reset-slider-vert',
            resetSlider: '.reset-slider'
        },

        events: {
            'click @ui.resetSliderVert': 'resetSliderVert',
            'click @ui.resetSlider': 'resetSlider'
        },

        className: 'chart-height css-chartView-bg-color',

        attributes: function () {
            return {style: 'height:' + (this.options.height ? this.options.height : 500) + 'px'};
        },

        resetSlider: function () {
            this.filterValuesX(null, null);
        },

        resetSliderVert: function () {
            this.filterValuesY(null, null);
        },

        serializeData: function () {
            return {};
        },

        initOptions: function () {
            return {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 1.2,
                hover: {
                    animationDuration: 0,
                    intersect: false
                },
                onClick: _.bind(function (item) {
                    var elementAtEventElement = this.instance.getElementAtEvent(item)[0];
                    if (!elementAtEventElement) {
                        return;
                    }
                    var data = this.instance.data.datasets[elementAtEventElement._datasetIndex];
                    if (this.onCurveClick) {
                        this.onCurveClick(data.wellSecId);
                    }
                }, this),
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';
                            if (label) {
                                label += ': ';
                            }
                            label += Math.round(tooltipItem.yLabel * 100) / 100;
                            return label;
                        },
                        title: function (tooltipItem, data) {
                            var label = data.datasets[tooltipItem[0].datasetIndex].tooltip || '';
                            return tooltipItem[0].xLabel + ' ' + label;
                        }
                    },
                    bodyFontSize: 20,
                    titleFontSize: 20,
                    footerFontSize: 20
                },
                animation: {
                    onComplete: _.bind(function () {
                        this.instance.config.options.animation.onComplete = null; //disable after first render
                        this.onComplete();
                    }, this)
                }
            };
        },

        getOptions: function (options) {
            return _.extend(options || {}, this.initOptions());
        },

        onRender: function () {
            this.type = this.options.type;
            if (!_.isUndefined(this.data) && !_.isEmpty(this.data)) {
                this.instance = new Chart(this.ui.chart[0].getContext('2d'), {
                    type: this.type,
                    data: this.data,
                    options: this.getOptions(this.options)
                });

                if (this.options.legend) {
                    this.$el.prepend(this.options.legend);
                }
            }

            this.ui.slider.hide();
            this.ui.resetSlider.hide();
            this.ui.sliderVert.hide();
            this.ui.resetSliderVert.hide();
            if (this.options.enableSlider) {
                this.ui.slider.show();
                this.ui.resetSlider.show();
            }
            if (this.options.enableSliderVertical) {
                this.ui.sliderVert.show();
                this.ui.resetSliderVert.show();
            }
        },

        onComplete: function () {
            var ci = this.instance.chart;
            if (this.filterValuesY) {
                var legV = ci.boxes[3];
                legV.min = legV.min * 100;
                legV.max = legV.max * 100;
                this.ui.sliderVert.css('top', this.instance.chartArea.top + 'px');
                this.ui.sliderVert.css('height', this.instance.chartArea.bottom - this.instance.chartArea.top + 'px');
                this.ui.resetSliderVert.css('top', this.instance.chartArea.top - 30 + 'px');
                this.ui.sliderVert.slider({
                    range: true,
                    min: legV.min,
                    orientation: 'vertical',
                    max: legV.max,
                    values: [legV.min, legV.max],
                    change: _.bind(function (event, ui) {
                        if (ui && (ui.values[0] !== legV.min || ui.values[1] !== legV.max)) {
                            this.filterValuesY(ui.values[0] / 100, ui.values[1] / 100);
                        }
                    }, this)
                });
            }
            if (this.filterValuesX) {
                var legH = ci.boxes[2];
                $('.slider-range').slider({
                    range: true,
                    min: legH.min,
                    max: legH.max,
                    values: [legH.min, legH.max],
                    change: _.bind(function (event, ui) {
                        if (ui.values[0] >= legH.min || ui.values[1] <= legH.max) {
                            this.filterValuesX(ui.values[0], ui.values[1]);
                        } else if (this.minXVal && this.maxXVal) {
                            this.filterValuesX(null, null);
                        }
                    }, this)
                });
            }
        },


        onDestroy: function () {
            if (this.instance) {
                this.instance.destroy();
            }
        },

        getBaseData: function () {
            var data = {
                labels:
                    [],
                datasets: [
                    {
                        fill: false,
                        borderColor: 'rgb(0, 0, 0)',
                        pointBackgroundColor: 'rgb(0, 0, 0)',
                        pointBorderColor: 'rgb(0, 0, 0)',
                        backgroundColor: '#FF000000',
                        data: [],
                        lineTension: 0,
                        borderJoinStyle: 'round'
                    }
                ]
            };
            return data;
        },

        /**
         * set chart control options to display
         * @param {Number} dataLength = "data.length of the dataset"
         * @param {Number} ucv = "upper control value"
         * @param {Number} uwv = "upper warning value"
         * @param {Number} av = "average value / middle value"
         * @param {Number} lwv = "lower waring value"
         * @param {Number} lcv = "lower control value"
         * @param {Number} min = "minimum value"
         * @param {Number} max = "maximum value"
         */
        addChartControls: function (dataLength, max, ucv, uwv, av, lwv, lcv, min) {
            if (!dataLength) {
                dataLength = this.data.datasets[0].data.length;
            }

            if (dataLength === 1) {
                dataLength = 2;
            }

            if (ucv && uwv && av && lwv && lcv) {
                if (!min) {
                    min = Math.min(ucv, uwv, av, lwv, lcv) - 1;
                }
                if (!max) {
                    max = Math.max(ucv, uwv, av, lwv, lcv) + 1;
                }

                this.addLimitControls('maximumValue', max, dataLength);
                this.addLimitControls('upperControl', ucv, dataLength);
                this.addLimitControls('upperWarning', uwv, dataLength);
                this.addLimitControls('averageValue', av, dataLength);
                this.addLimitControls('lowerWarning', lwv, dataLength);
                this.addLimitControls('lowerControl', lcv, dataLength);
                this.addLimitControls('minimumValue', min, dataLength);
            }
        },

        /**
         * @param {Number} yMinValue = "lower value of the y (vertical) axe to highlight in the dataset"
         * @param {Number} yMaxValue = "higher value of the y (vertical) axe to highlight in the dataset"
         * @param {Number} xValue = "x (horizontal) where to place the vertical line"
         * @param {String} color
         */
        addVerticalLine: function (yMinValue, yMaxValue, xValue, color) {
            var data = [];
            data.push({x: xValue, y: yMinValue});
            data.push({x: xValue, y: yMaxValue});
            this.data.datasets.push({
                label: '',
                borderColor: color,
                pointBackgroundColor: '#00000000',
                pointBorderColor: '#00000000',
                backgroundColor: '#00000000',
                borderWidth: 2,
                fill: false,
                legend: false,
                borderDash: [2, 1],
                data: data,
                pointRadius: 0
            });
        },

        /**
         * @param start
         * @param {Number} dataLength = "data.length of the dataset"
         * @param {Number} yValue = "y (vertical) value to highlight in the dataset"
         * @param {String} color
         */
        addHorizontalLine: function (start, dataLength, yValue, color) {
            var data = [];
            for (var i = start; i <= dataLength; i++) {
                data.push({x: i, y: yValue});
            }
            this.data.datasets.push({
                label: '',
                borderColor: color,
                pointBackgroundColor: '#00000000',
                pointBorderColor: '#00000000',
                backgroundColor: '#00000000',
                borderWidth: 2,
                fill: false,
                legend: false,
                borderDash: [2, 4],
                data: data,
                pointRadius: 0
            });
        },

        /**
         * @param {String} type = "upperControl, upperWarning, averageValue, lowerWarning, lowerControl"
         * @param {Number} controlValue
         * @param {Number} dataLength = "data.length of the dataset"
         */
        addLimitControls: function (type, controlValue, dataLength) {
            var controlData = [];
            for (var i = 0; i < dataLength; i++) {
                controlData.push(controlValue);
            }
            switch (type) {
                case 'maximumValue':
                    this.data.datasets.push({
                        label: '',
                        legend: false,
                        backgroundColor: '#FF000000',
                        borderWidth: 2,
                        borderColor: '#FF000000',
                        borderDash: [20, 5],
                        fill: false,
                        data: controlData,
                        pointRadius: 0,
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent'
                    });
                    return;
                case 'upperControl':
                    this.data.datasets.push({
                        label: _.i18n('upper.control.limit'),
                        backgroundColor: '#FF000026',
                        borderWidth: 2,
                        borderColor: '#FF0000FF',
                        borderDash: [20, 5],
                        fill: '-1',
                        data: controlData,
                        pointRadius: 0,
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent'
                    });
                    return;
                case 'upperWarning':
                    this.data.datasets.push({
                        label: _.i18n('upper.warning.limit'),
                        backgroundColor: '#FF9A0026',
                        borderWidth: 2,
                        borderColor: '#FF6200A0',
                        borderDash: [10, 20],
                        fill: '-1',
                        data: controlData,
                        pointRadius: 0,
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent'
                    });
                    return;
                case 'averageValue':
                    this.data.datasets.push({
                        label: _.i18n('average.value'),
                        backgroundColor: '#4BFF0013',
                        borderWidth: 2,
                        borderColor: '#1CFF00FF',
                        fill: '-1',
                        data: controlData,
                        pointRadius: 0,
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent'
                    });
                    return;
                case 'lowerWarning':
                    this.data.datasets.push({
                        label: _.i18n('lower.warning.limit'),
                        backgroundColor: '#4BFF0013',
                        borderWidth: 2,
                        borderColor: '#FF6200A0',
                        borderDash: [10, 20],
                        fill: '-1',
                        data: controlData,
                        pointRadius: 0,
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent'
                    });
                    return;
                case 'lowerControl':
                    this.data.datasets.push({
                        label: _.i18n('lower.control.limit'),
                        backgroundColor: '#FF9A0026',
                        borderWidth: 2,
                        borderColor: '#FF0000FF',
                        borderDash: [20, 5],
                        fill: '-1',
                        data: controlData,
                        pointRadius: 0,
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent'
                    });
                    return;
                case 'minimumValue':
                    this.data.datasets.push({
                        label: '',
                        legend: false,
                        backgroundColor: '#FF000026',
                        borderWidth: 2,
                        borderColor: '#FF000000',
                        borderDash: [20, 5],
                        fill: '-1',
                        data: controlData,
                        pointRadius: 0,
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent'
                    });
                    return;
            }
        },

        /**
         * set chart scales options
         * @param {Boolean} yDisplay
         * @param xDisplay
         * @param yTickDisplay
         * @param xTickDisplay
         * @param {Number} ticksFontSize = "15"
         * @param {String} fontFamily = "Verdana"
         * @param {Number} yMaxVal = "27"
         * @param {Number} yMinVal = "25"
         * @param {Number} yStepSize = "0.5"
         * @param {Number} yRotation = "40"
         * @param {String} yLabel = "_.i18n('y.axes.label')"
         * @param {String} xLabel = "_.i18n('x.axes.label')"
         * @param {Number} labelFontSize = "25"
         */
        setScales: function (yDisplay, xDisplay, yTickDisplay, xTickDisplay, ticksFontSize, fontFamily, yMaxVal, yMinVal, yStepSize, yRotation, yLabel, xLabel, labelFontSize) {
            this.options.scales = {
                yAxes: [{
                    ticks: {
                        fontSize: ticksFontSize,
                        max: yMaxVal,
                        min: yMinVal,
                        stepSize: yStepSize,
                        family: fontFamily,
                        display: yTickDisplay
                    },
                    scaleLabel: {
                        display: yDisplay,
                        labelString: yLabel,
                        fontSize: labelFontSize
                    }
                }],
                xAxes: [{
                    ticks: {
                        fontSize: ticksFontSize,
                        minRotation: yRotation,
                        family: fontFamily,
                        display: xTickDisplay
                    },
                    scaleLabel: {
                        display: xDisplay,
                        labelString: xLabel,
                        fontSize: labelFontSize
                    }
                }]
            };
        },

        /**
         * set chart legend options
         * @param {Boolean} display
         * @param {String} position = "right"
         * @param {String} align = "start"
         * @param {Number} padding = "20"
         * @param {Boolean} lineStyle = "false = display box; true = display line"
         */
        generateLegend: function (display, position, align, padding, lineStyle) {
            this.options.legend = {
                display: false
            };
            if (display) {
                this.options.legend = {
                    display: display,
                    position: position,
                    align: align,
                    padding: padding,
                    labels: {
                        useLineStyle: lineStyle
                    }
                };
            }
        },

        /**
         * set chart title options
         * @param {Boolean} display
         * @param {String} chartTitle = "_.i18n('chart.title')"
         * @param {Number} fontSize = "40"
         * @param {String} align = "start"
         * @param {Number} bottom = "10"
         */
        generateTitle: function (display, chartTitle, fontSize, bottom) {
            this.options.title = {
                display: false
            };
            if (display) {
                this.options.title = {
                    display: display,
                    text: chartTitle,
                    fontSize: fontSize,
                    bottom: bottom
                };
            }
        }
    });
});
