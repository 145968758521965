/**
 * Created by RKL on 16/09/2015.
 */
define('pluginRouter',[
    'require',
    'exports',
    'module',
    'marionetteAppRouter',
    'underscore',
    'app'
], function (
    require,
    exports,
    module,
    AppRouter,
    _,
    App
) {
    'use strict';

    var PluginRouter = AppRouter.extend({
        appRoutes: {
            'template/:returnTab/:groupId/:templateId/:aId(/:id)(/:userSecId)(/:parentId)': 'editTemplate',
            'attachments/:returnTab/:cAccountId/:attachmentsId/:itemType': 'editAttachments',
            'orderprocess/:code/:id/(:template)(/:poi)':
                'showOrderProcess',
            'orderprocess/actions/:action/:code/:id/(:template)(/:poi)':
                'showOrderProcess',
            'orderprocess/actions/:action/:name/:code/:id/:secId/:logo(/:option)':
                'showOrderProcess',
            'editImage/:userId/:aProfileId/:userCode/:path/:dsv(/:groupId)(/:tab)':
                'showImageEditor',
            'files/:secId': 'viewFile'
        }
    });

    var API = {
        editTemplate: function (
            returnTab,
            groupSecId,
            templateSecId,
            cAccountId,
            endUserId,
            userSecId,
            parentId
        ) {
            require([
                'editTemplateController',
                'contentShowController'
            ], function (EditTemplateController, ContentShowController) {
                var data = {
                    returnTab: returnTab,
                    groupSecId: groupSecId,
                    templateSecId: templateSecId,
                    cAccountId: cAccountId,
                    endUserId: endUserId,
                    userSecId: userSecId,
                    parentId: parentId
                };

                ContentShowController.showContent().done(function () {
                    EditTemplateController.showPlugin(data);
                });
            });
        },

        editAttachments: function (returnTab, cAccountId, attachmentsId, itemType) {
            require([
                'editAttachmentsController',
                'contentShowController'
            ], function (EditAttachmentsController, ContentShowController) {
                var data = {
                    returnTab: returnTab,
                    cAccountId: cAccountId,
                    attachmentsId: attachmentsId,
                    type: itemType
                };

                ContentShowController.showContent().done(function () {
                    EditAttachmentsController.showPlugin(data);
                });
            });
        },

        showOrderProcess: function () {
            var args = Array.prototype.slice.call(arguments);

            args.pop();
            require([
                'orderprocessController',
                'contentShowController'
            ], function (OrderprocessController, ContentShowController) {
                ContentShowController.showContent().done(function () {
                    OrderprocessController.showPlugin(args);
                });
            });
        },

        showImageEditor: function () {
            var args = Array.prototype.slice.call(arguments);

            args.pop();
            require([
                'editImagePluginController',
                'contentShowController'
            ], function (EditImageController, ContentShowController) {
                ContentShowController.showContent().done(function () {
                    EditImageController.showPlugin(args);
                });
            });
        },
        viewFile: function (secId) {
            var AttachmentService = require('services/caccounts/attachment');
            return AttachmentService.downloadFile(secId);
        }
    };

    App.on('before:start', function () {
        new PluginRouter({
            controller: API
        });
    });

    module.exports = PluginRouter;
});

