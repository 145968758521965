define('runPcrContentWellListView',[
    'module',
    'underscore',
    'jquery',
    'jqgridView',
    'wellUtils',
    'colorUtils',
    'pcrWellResultController'
], function (
    module,
    _,
    $,
    JqGridView,
    WellUtils,
    ColorUtils,
    PcrWellResultController
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: _.template('<table class="jq-grid-list" id="<%= jqGridId %>" role="grid"></table><div class="jq-grid-pager-list" id="<%= jqGridPagerId %>"></div>'),
        filtersName: 'wellViewTableListFilters',
        className: 'content',

        ui: function () {
            return _.extend({
                jqGrid: '#' + this.jqGridId.jqGrid,
                jqGridPager: '#' + this.jqGridId.jqGridPager,
                jqgRow: 'tr.jqgrow'
            }, this.gridUi);
        },

        initialize: function () {
            this.jqGridId = {
                jqGrid: _.uniqueId('jq-grid-list'),
                jqGridPager: _.uniqueId('jq-grid-pager-list')
            };
        },
        attributes: {
            style: 'flex: 1; height: 100%;'
        },

        serializeData: function () {
            return {
                jqGridId: this.jqGridId.jqGrid,
                jqGridPagerId: this.jqGridId.jqGridPager
            };
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 9999,
                currentPage: 1
            };
        },

        gridOptions: function (data) {
            var that = this,
                defaultsOptions = this.gridInitOptions(data),
                options = {
                    colModel: [
                        {
                            label: '',
                            name: 'secId',
                            key: true,
                            hidden: true
                        }, {
                            label: _.i18n('well.sample.comment'),
                            name: 'wellComment',
                            formatter: _.bind(this.wellCommentFormatter, this),
                            search: true,
                            sortable: true,
                            index: 'wellComment',
                            searchoptions: {
                                clearSearch: false
                            },
                            fixed: true,
                            width: 150
                        }, {
                            label: this.getWarningLabelIcon(),
                            name: 'codeErr',
                            formatter: _.bind(this.warningFormater, this),
                            search: true,
                            sortable: true,
                            index: 'codeErr',
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {sopt: ['eq'], value: this.warningSearch, clearSearch: false},
                            fixed: true,
                            width: 30
                        }, {
                            label: this.getRepeatLabelIcon(),
                            name: 'repeatStatus',
                            formatter: _.bind(this.repeatStatusFormater, this),
                            search: true,
                            sortable: true,
                            index: 'repeatStatus',
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {sopt: ['eq'], value: this.availableRepeatStatus, clearSearch: false},
                            fixed: true,
                            width: 30
                        }, {
                            label: this.getSameOtherAssayLabelIcon(),
                            name: 'existOtherWellSameAssay',
                            formatter: _.bind(this.sameOtherAssayFormater, this),
                            search: true,
                            sortable: true,
                            index: 'existOtherWellSameAssay',
                            fixed: true,
                            width: 40
                        }, {
                            label: this.getSmpTypeLabelIcon(),
                            name: 'smpType',
                            formatter: _.bind(this.smptypeFormater, this),
                            search: true,
                            sortable: true,
                            index: 'smpType',
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {sopt: ['eq'], value: this.smpTypeList, clearSearch: false},
                            fixed: true,
                            width: 30
                        }, {
                            label: _.i18n('well.pos'),
                            name: 'pos',
                            formatter: this.wellPosFormatter,
                            classes: 'css-grid-cell-accentuatuion-03',
                            sortable: true,
                            title: false,
                            index: 'pos',
                            fixed: true,
                            width: 50,
                            search: true,
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            }
                        }, {
                            label: _.i18n('well.smpId'),
                            name: 'smpId',
                            formatter: _.bind(this.wellSmpidFormatter, this),
                            classes: 'css-grid-cell-accentuatuion-02',
                            search: true,
                            sortable: true,
                            title: false,
                            index: 'smpId',
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            },
                            fixed: true,
                            width: 130
                        }, {
                            label: _.i18n('common.empty.placeholder'),
                            name: 'smpId.code',
                            sortable: false,
                            search: false,
                            classes: 'ignore-row-click css-no-leftBorder',
                            formatter: this.copyToClipboardFormatter,
                            fixed: true,
                            width: 30
                        }, {
                            label: _.i18n('well.refAssay'),
                            name: 'refAssay.code',
                            formatter: this.wellAssayFormatter,
                            classes: 'css-run-listView-accentuation',
                            search: true,
                            sortable: true,
                            title: false,
                            index: 'refAssay.code',
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            },
                            fixed: true,
                            width: 150
                        }, {
                            label: _.i18n('well.result'),
                            name: 'wellResSearchValue',
                            classes: 'well-list-label-wellRes',
                            formatter: _.bind(this.wellResultFormatter, this),
                            search: true,
                            sortable: true,
                            title: false,
                            searchoptions: {sopt: ['cn'], clearSearch: false},
                            index: 'wellResSearchValue',
                            fixed: true,
                            width: 110
                        }
                    ],
                    sortname: this.sortOn ? this.sortOn : 'row',

                    gridComplete: function () {
                        defaultsOptions.gridComplete.apply(this, arguments);

                        var $item = that.$('.js-wellRes-color-cell');
                        _.each($item, _.bind(function (item) {
                            var $item = $(item);
                            var color = $item.attr('data-color');
                            var rgbObj = ColorUtils.hexToRgba(color);
                            var rgbaCol = 'rgba(' + rgbObj.r + ',' + rgbObj.g + ',' + rgbObj.b + ', 0.2) !important';
                            $item.parent().parent().parent().attr('style', 'background-color: ' + rgbaCol + ';');
                        }, this));
                    },

                    onSelectRow: _.bind(function (rowId, bool, e) {
                        if (!$(e.target).parent().hasClass('ignore-row-click') && e.type === 'click') {
                            var filteredRowList = this.ui.jqGrid.getRowData(null, true);
                            var cSelection = [];
                            _.each(filteredRowList, _.bind(function (wellRow) {
                                cSelection.push(wellRow.pos);
                            }, this));
                            var well = this.model.get('wells').find(function (obj) {
                                return obj.get('secId') === rowId;
                            });
                            require(['pcrWellController'], function (PcrWellController) {
                                PcrWellController.showDetails({model: well});
                            });
                        }
                    }, this)
                };

            var max = this.model.get('wells').chain().map(function (well) {
                if (!well.get('assayVersion')) {
                    return null;
                }
                var results = well.get('assayVersion').get('results');
                return results.chain().filter(function (result) {
                    return !result.get('sequenceDisplayGroup');
                }).value().length;
            }).filter(function (count) {
                return count && count > 0;
            }).uniq().value();

            max = _.first(max);

            var width = this.model.get('displayMode').findParameterForLocation('WellList').getConfig('WellList').width;
            for (var i = 1; i <= max; i++) {
                options.colModel.push({
                    label: i % 10 === 0 ? '0' : i % 10,
                    name: 'target_' + i,
                    formatter: _.bind(this.resultFormatter, this),
                    search: false,
                    sortable: false,
                    sequence: i,
                    fixed: true,
                    width: width
                });
            }

            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        },

        wellCommentFormatter: function (cellValue, options, object) {
            var comment = object.smpId ? (object.smpId.lisComment ? object.smpId.lisComment : '') +
                ' ' + (object.smpId.comment ? object.smpId.comment : '') : '';
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + comment + '</span>';
        },

        warningFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12" style="padding-left: 17px;">';
            if (cellValue === 'warning') {
                start += '<span class="iconify" data-icon="fluent:warning-24-filled" ' +
                    'style="font-size: 17px; position: absolute; top: -7px; color: #ff5200;"></span>';
            } else if (cellValue === 'expert') { //object.valWst === 3.1
                start += '<span class="mdi-social-school" ' +
                    'style="font-size: 17px; position: absolute; top: -7px; color: #0014FF;"></span>';
            }
            start += '</div>';
            end = '</div>';
            return start + end;
        },

        repeatStatusFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12" style="right: 5px;">';
            if (cellValue === 'PT') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="controlSpan" style="position: absolute; left: 25px; top: -7px; color: white; font-size: 11px;">c</span>';
            } else if (cellValue === 'XT') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px; left: 21px;"></span>';
                start += '<span class="p-l-0 p-r-0" style="font-size:11px; position: absolute;' +
                    'left: 40px; bottom: -8px;">' +
                    '</span>';
            } else if (cellValue === 'XC') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px; left: 21px;"></span>';
                start += '<span class="controlSpan" style="position: absolute; left: 28px; top: -7px; color: white; font-size: 11px;">c</span>';
                start += '<span class="p-l-0 p-r-0" style="font-size:11px; position: absolute;' +
                    'left: 40px; bottom: -8px;">' +
                    '</span>';
            }
            start += '</div>';
            end = '</div>';
            return start + end;
        },

        sameOtherAssayFormater: function (cellValue, call, object) {
            if (object.smpType !== 'PC' && object.smpType !== 'NC' && object.smpType !== 'RC' && object.smpType !== 'OC') {
                var start, end;
                start = '<div class="row m-b-0 m-t-0">';
                start += '<div class="col-xs-12 p-l-15" style="display: flex;">';
                if (object.existOtherWellOtherAssay) {
                    start += '<span class="triangle-other-Assay-wellView" ' +
                        'style="' +
                        'border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 15px solid ' +
                        this.model.otherAssayColor + '; ' +
                        '"></span>';
                }
                if (object.existOtherWellSameAssay) {
                    start += '<span class="triangle-same-Assay-wellView" ' +
                        'style="' +
                        'border-left: 6px solid transparent; border-right: 6px solid transparent; border-bottom: 15px solid ' +
                        this.model.sameAssayColor + '; ' +
                        '"></span>';
                }
                start += '</div>';
                end = '</div>';
                return start + end;
            }
            return '';
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15">';

            if (cellValue === 'NC') {
                start += '<span class="mdi-content-remove-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi-content-add-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="iconify" data-icon="ant-design:copyright-circle-filled" style="font-size: 17px;"></span>';
            } else if (cellValue === 'RC') {
                start += '<span class="iconify" data-icon="bxs:registered" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="iconify" data-icon="mdi:google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        },

        wellPosFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellPosPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="position: relative;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="position: relative;">' + cellValue + '</span>';
        },

        wellSmpidFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = {name: ''};
            }
            if (object.pos) {
                return '<span class="cell-default wellSmpidPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="font-weight: 600;">' + cellValue.name + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue.name + '</span>';
        },

        wellAssayFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellAssayPopover-' + object.pos + '" ' +
                    'style="font-weight: bold;" data-row-id="' +
                    options.rowId + '">' + cellValue + '</span>';
            }
            return '<span class="cell-default" style="font-weight: bold;" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        wellResultFormatter: function (cellValue, call, object) {
            var model = this.model.get('wells').findWhere({secId: object.secId});
            var html;
            var result = _.first(model.get('results').filter(function (result) {
                return result.get('refAssayResult').get('sequenceDisplayGroup');
            }));

            if (result) {
                html = '<div class="row m-b-0 ovrerallResultPopover-' + object.pos + ' ">';
            } else {
                html = '<div class="row m-b-0">';
            }
            html += '<div class="col-xs-12 p-r-0" style="display: flex;">';
            if (result) {
                html += PcrWellResultController.generateContent(result, 'WellList');
            }
            html += '</div>';
            html += '</div>';
            return html;
        },

        resultFormatter: function (cellValue, call, object) {
            var model = this.model.get('wells').findWhere({secId: object.secId});
            var html;
            var results = model.get('results').filter(function (result) {
                return !result.get('refAssayResult').get('sequenceDisplayGroup');
            });
            var result;
            if (results.length >= call.colModel.sequence) {
                result = results[call.colModel.sequence];
            }

            html = '';
            if (result) {
                html += PcrWellResultController.generateContent(result, 'WellList');
            }
            return html;
        },

        onGridLoaded: function (model, rowId) {
            var listViewData = this.getListViewData(this.model.get('wells'));
            this.setGridData(listViewData, rowId);
        },
        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            this.availableRepeatStatus = wellListViewData.repeatStatusList;
            this.smpTypeList = wellListViewData.smpTypeList;
            this.warningSearch = wellListViewData.warningSearch;
            var data = {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
            return data;
        },
        getWellListViewData: function (wells) {
            var availableRepeatStatus = [];
            var availableSmpTypes = [];
            var wellsForData = [];
            var warningFound = {empty: 0, warning: 0, expert: 0};

            wells.each(function (well) {
                var counter = 0;
                if (well.get('repeatStatus')) {
                    availableRepeatStatus.push(well.get('repeatStatus'));
                }
                if (well.get('smpType')) {
                    availableSmpTypes.push(well.get('smpType'));
                }
                var model = {
                    secId: well.get('secId'),
                    wellRes: null,
                    pos: well.get('pos'),
                    codeErr: well.get('codeErr'),
                    repeatStatus: well.get('repeatStatus'),
                    existOtherWellSameAssay: well.get('existOtherWellSameAssay'),
                    smpType: well.get('smpType')
                };
                model.wellRes = well.getWellRes();

                model.wellResSearchValue = model.wellRes;

                if (well.get('smpId')) {
                    model.smpId = {
                        secId: well.get('smpId').get('secId'),
                        name: well.get('smpId').get('name'),
                        code: well.get('smpId').get('code'),
                        lisComment: well.get('smpId').get('lisComment'),
                        comment: well.get('smpId').get('comment')
                    };
                }
                if (well.get('refAssay')) {
                    model.refAssay = {
                        secId: well.get('refAssay').get('secId'),
                        code: well.get('refAssay').get('code'),
                        name: well.get('refAssay').get('name')
                    };
                }

                var wellResCode = model.wellRes && model.wellRes.get('result') ? model.wellRes.get('result').get('code') : '';

                var wellResQuantification = model.wellRes && model.wellRes.get('quantificationFormatted') ?
                    model.wellRes.get('quantificationFormatted') : '';

                model.wellResSearchValue = wellResCode + wellResQuantification;

                well.get('results').each(function (target) {
                    if (target.get('codeErr')) {
                        model.codeErr = 'warning';
                        warningFound.warning++;
                        counter++;
                    }
                });

                if (well.get('coErr') !== 'warning' && well.get('valWst') === 3.1) {
                    model.codeErr = 'expert';
                    warningFound.expert++;
                    counter++;
                }

                if (counter === 0) {
                    warningFound.empty++;
                    model.codeErr = 'empty';
                }

                wellsForData.push(model);
            });

            availableSmpTypes = _.uniq(availableSmpTypes);
            availableRepeatStatus = _.uniq(availableRepeatStatus);

            return {
                repeatStatusList: this.getGridSearchField(availableRepeatStatus),
                smpTypeList: this.getGridSearchField(availableSmpTypes),
                warningSearch: this.getGridWarningSearchField(warningFound),
                wellsForData: wellsForData
            };
        },
        getGridSearchField: function (list) {
            var retVal = ':' + _.i18n('common.any') + ';'; //': ;';
            _.each(list, function (l) {
                retVal += l + ':' + l + ';';
            });
            retVal = retVal.substring(0, retVal.length - 1);
            return retVal;
        },

        getGridWarningSearchField: function (object) {
            var retVal = ':' + _.i18n('common.any') + ';'; //': ;';
            if (object.empty > 0) {
                retVal += 'empty:' + _.i18n('empty') + ';';
            }
            if (object.expert > 0) {
                retVal += 'expert:' + _.i18n('expert') + ';';
            }
            if (object.warning > 0) {
                retVal += 'warning:' + _.i18n('warning') + ';';
            }
            retVal = retVal.substring(0, retVal.length - 1);
            return retVal;
        },

        setGridData: function (data, rowId) {
            /** */ this.ui.jqGrid.clearGridData(); /** needed in case of refresh */
            /** */ this.ui.jqGrid.setGridParam(data, true); //overwrite param is needed true to avoid maximum call stack error on grid refresh
            /** needed in case of refresh */
            this.gridOptionsObj = this.gridOptions(data);
            this.onRowCountDone(data, 9999);
            this.triggerMethod('checkRowCount', 9999);
            if (rowId) {
                /** */ this.ui.jqGrid.trigger('reloadGrid', [{current: true}]);
                /** needed in case of refresh */
                this.ui.jqGrid.setSelection(rowId, false); // highlight selected row
            }
            this.setWellListPopover();

        },
        setWellListPopover: function () {
            this.model.get('wells').each(_.bind(function (well, index) {
                if (well.get('pos')) {

                    if ($('.wellPosPopover-' + well.get('pos')).length > 0 &&
                        $('.wellSmpidPopover-' + well.get('pos')).length > 0 &&
                        $('.wellAssayPopover-' + well.get('pos')).length > 0) {

                        var params = {
                            model: well
                        };

                        params.targets = [
                            $('.wellPosPopover-' + well.get('pos')),
                            $('.wellSmpidPopover-' + well.get('pos')),
                            $('.wellAssayPopover-' + well.get('pos'))
                        ];

                        if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                            (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                            params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                        }
                        var placement = 'right';
                        if (index < 3) {
                            placement = 'bottom';
                        } else if (index > this.model.get('wells').length - 4) {
                            placement = 'top';
                        }
                        params.placement = placement;
                        WellUtils.showPcrWellPopover(params);
                    }
                }
            }, this));
        }
    });
});

