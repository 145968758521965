define('services/caccounts/fileFormatPlateLayouts',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/fileFormatPlateLayouts'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.FileFormatPlateLayout,
                collection: app.ns.FileFormatPlateLayoutCollection
            };
        },
        getName: function () {
            return 'fileFormatPlateLayout';
        },
        getUrl: function () {
            return 'rest/v2/fileFormat/plateLayouts/';
        },
        getDynamicJson: function () {
            return 'FileFormatPlateLayoutJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('fileFormat.inputPlateLayout'),
                name: 'inputPlateLayoutService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'inputPlateLayoutService',
                searchoptions: {size: 10},
                width: 25
            }, {
                label: _.i18n('fileFormat.customInputPlateLayout'),
                name: 'customInputPlateLayout',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'customInputPlateLayout',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('fileFormat.outputPlateLayout'),
                name: 'outputPlateLayoutService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'outputPlateLayoutService',
                searchoptions: {size: 10},
                width: 25
            }, {
                label: _.i18n('fileFormat.customOutputPlateLayout'),
                name: 'customOutputPlateLayout',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'customOutputPlateLayout',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('customInputPlateLayout', 'customOutputPlateLayout');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {
                    'field': 'inputPlateLayoutService',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customInputPlateLayout',
                        'url': 'rest/v2/inputPlateLayoutService/'
                    }
                },
                {
                    'field': 'outputPlateLayoutService',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customOutputPlateLayout',
                        'url': 'rest/v2/outputPlateLayoutService/'
                    }
                },
                {
                    'field': 'customOutputPlateLayout',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customOutputResult',
                        'url': 'rest/v2/outputResultService/'
                    }
                }
            );
            return custom;
        },

        getAutocompleteParamForImport: function (config) {
            config.url = this.getUrl() + 'forImport';
            return Dynamic.getAutocompleteParam(config, this);
        },
        getAutocompleteParamForExport: function (config) {
            config.url = this.getUrl() + 'forExport';
            return Dynamic.getAutocompleteParam(config, this);
        }
    }, Dynamic);
});

