
define('template!wellTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="topSideAbsolute">\n    <div class="btn-group-flat">\n        ';
 if(displayKitLot) { ;
__p += '\n        <button class="wellButton js-btn-preprun-kitLot">\n            <span class="mdi mdi-flask-outline clickable"></span>\n        </button>\n        ';
 } ;
__p += '\n        ';
 if (well.smpType === 'NC' || well.smpType === 'PC' || well.smpType === 'RC' ||
        well.smpType === 'C' || well.smpType === 'OC' )
        { ;
__p += '\n        ';
 if (well.showQC){ ;
__p += '\n        <button class="wellButton smp-id-link mdi-qc">\n            <span class="mdi mdi-alpha-q"></span>\n            <span class="mdi mdi-alpha-c"></span>\n        </button>\n        ';
 } ;
__p += '\n        ';
 } else { ;
__p += '\n        ';
 if(well.smpType === 'U') { ;
__p += '\n        <button class="wellButton smp-id-link">\n            <span class="mdi mdi-alpha-s clickable"></span>\n        </button>\n        <button class="wellButton js-btn-comment">\n            <span class="mdi mdi-alpha-c clickable"></span>\n        </button>\n        ';
 } ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if(error) { ;
__p += '\n        <button class="wellButton">\n            <span class="mdi mdi-alert-octagram clickable" title="' +
((__t = ( _.i18n('common.showErrors') )) == null ? '' : __t) +
'"></span>\n        </button>\n        ';
 } ;
__p += '\n    </div>\n</div>\n\n';
 if(errorWarning || expertMode || well.repeatStatus) { ;
__p += '\n<div class="iconTop">\n    ';
 if(errorWarning && expertMode) { ;
__p += '\n    <span class="mdi mdi-alert warningErrorIcon warningColorIcon"></span>\n    <span class="mdi mdi-school inWarning-expertIcon" style="color: #1c3bc1;"></span>\n    ';
 } else if(expertMode) { ;
__p += '\n    <span class="mdi mdi-school expertIcon" style="color: #1c3bc1;"></span>\n    ';
 } else if(errorWarning) { ;
__p += '\n    <span class="mdi mdi-alert warningErrorIcon warningColorIcon"></span>\n    ';
 } else if (well.repeatStatus) { ;
__p += '\n    <span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
'"></span>\n    ';
 } ;
__p += '\n</div>\n';
 } ;
__p += '\n\n<div class="watermark">\n    ';
 if(errorWarning && expertMode) { ;
__p += '\n    <span class="mdi mdi-alert warningErrorIcon"></span>\n    <span class="mdi mdi-school inWarning-expertIcon"></span>\n    ';
 } else if(expertMode) { ;
__p += '\n    <span class="mdi mdi-school expertIcon"></span>\n    ';
 } else if(errorWarning) { ;
__p += '\n    <span class="mdi mdi-alert warningErrorIcon"></span>\n    <!-- vvv START: move this selection to the target line bellow to show highlighted repeat icon if the status is exported  vvv -->\n    ';
 } else if (well.exported && well.repeatStatus) { ;
__p += '\n    <span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
'" style="opacity: 0.2;"></span>\n    ';
 } else if (well.repeatStatus) { ;
__p += '\n    <span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
'"></span>\n    ';
 } else if(well.val2 && well.smpType !== 'E') { ;
__p += '\n    <span class="mdi mdi-check-all"></span>\n    ';
 } else if(well.exported && well.smpType !== 'E') { ;
__p += '\n    <span class="mdi mdi-send"></span>\n    ';
 } ;
__p += '\n</div>\n<div class="wellResColorBar" style="' +
((__t = ( (wellResColor? ('background-color:'+ wellResColor + ';'):'') )) == null ? '' : __t) +
';"></div>\n<div class="content-cell">\n    <div class="ctn">\n        <!-- Sample -->\n        <div class="js-well-type d-f"></div>\n        <div class="js-well-sample"></div>\n        <div class="existOther">\n            ';
 if(well.existOtherWellSameAssay) { ;
__p += '\n            <span class="triangle-same-assay-plateView"\n                  style="color: ' +
((__t = ( colorOtherWellSameAssay )) == null ? '' : __t) +
'; top: 18px;"></span>\n            ';
 } ;
__p += '\n            ';
 if(well.existOtherWellOtherAssay) { ;
__p += '\n            <span class="triangle-other-assay-plateView"\n                  style="color: ' +
((__t = ( colorOtherWellOtherAssay )) == null ? '' : __t) +
'; top: 18px;"></span>\n            ';
 } ;
__p += '\n        </div>\n        <div class="noWrapHidden cs-assay-font">\n            <span style="' +
((__t = ( (assayColor?('background-color:' + assayColor + ' !important;'):'') )) == null ? '' : __t) +
'">' +
((__t = ( well.assay )) == null ? '' : __t) +
'</span>\n        </div>\n    </div>\n    <div class="js-display-result"></div>\n</div>';

}
return __p
};});

