define('entities/caccounts/privilege',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.Privilege = Backbone.RelationalModel.extend({
        service: 'services/caccounts/privileges',
        fetch: function () {
            this.url = Settings.url('rest/v2/privileges/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': ''
        },
        idAttribute: 'secId',

        jsonObjectName: 'PrivilegeJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Privilege',

        importable: true
    });

    app.ns.Privilege.role = RolesMixin.AUTHORITYGROUPROLE;

    app.ns.PrivilegeCollection = Backbone.Collection.extend({
        model: app.ns.Privilege,
        comparator: function (model) {
            // order by "model.get('code').endsWith
            // 1. $ALL
            // 2. $READ
            // 3. $WRITE
            // 4. $ARCHIVE
            // 5. others 
            var code = model.get('code');
            if (code.endsWith('$ALL')) {
                return 1;
            } else if (code.endsWith('$READ')) {
                return 2;
            } else if (code.endsWith('$WRITE')) {
                return 3;
            } else if (code.endsWith('$ARCHIVE')) {
                return 4;
            } else {
                return 5;
            }
        }
    });
});

