define('pcrWellResultCurveItemView',[
    'module',
    'backbone.marionette',
    'backbone',
    'template!pcrWellResultCurveItemView',
    'underscore',
    'jquery',
    'editWellView',
    'wellPopupDisplayGraphAmpView',
    'bootbox',
    'wellPopupDisplayGraphView',
    'pcrWellResultController',
    'colorUtils',
    'settings',
    'rolesMixin',
    'privileges'
], function (
    module,
    Marionette,
    Backbone,
    Tpl,
    _,
    $,
    EditWellView,
    DisplayPopupGraphAmpView,
    Bootbox,
    DisplayPopupGraphLayoutView,
    PcrWellResultController,
    colorUtils,
    Settings,
    RolesMixin,
    Privileges
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'click .btn-validate-result': 'onConfirmResult',
            'click .btn-negative-result': 'onNegativeResult',
            'click .btn-edit-well-target-result': 'onEditWellTargetResult',
            'click .js-target-propC-popup': 'onTargetPropC',
            'click .btn-graph-well': 'onBtnGraphClick'
        },

        modelEvents: {
            'change': 'renderSafe'
        },

        triggers: {
            'click .mdi-format-list-bulleted': 'show:properties'
        },

        regions: {
            result: '.js-result',
            displayMode: '.js-displayMode'
        },

        className: 'result result-curve',

        renderSafe: function () {
            if (!this.isDestroyed()) {
                this.render();
            }
        },

        onTargetPropC: function () {
            var graphToDisplay = this.model.get('refPcrWell').getGraphToDisplay();

            if (graphToDisplay.includes('A') || graphToDisplay.includes('M')) {
                this.propCPopupView = new DisplayPopupGraphAmpView({
                    model: this.model,
                    graphToDisplay: graphToDisplay
                });
                this.propCPopupView.show({
                    title: _.i18n('wellTarget.result.interpretation.' + graphToDisplay),
                    className: 'js-draggable-modal wellTargetPropC-popup'
                });
                $('.js-draggable-modal  .modal-dialog').draggable({
                    handle: '.modal-header'
                });
            }
        },

        serializeData: function () {
            var templateData = {
                refPcrWellExported: this.model.get('refPcrWell') ? this.model.get('refPcrWell').get('exported') : false,
                mbAna: this.model.get('mbAna') ? this.model.get('mbAna').get('code') : undefined,
                result: this.model.get('result') ? this.model.get('result').get('code') : '',
                cts: this.model.get('cts'),
                quantifications: this.model.get('quantifications'),
                comment: this.model.get('comment'),
                dyeToDisplay: this.model.get('dyeToDisplay'),
                dyeColorToDisplay: this.model.get('dyeColorToDisplay'),
                codeErr: this.model.get('codeErr'),
                valSt: this.model.get('valSt'),
                needValidation: this.model.get('needValidation'),
                readOnly: (this.options.readOnly !== undefined && this.options.readOnly) || this.model.isReadOnly(),
                propC: this.model.get('propC')
            };

            templateData.userVal1Code = '';
            templateData.userVal1Title = '';
            if (this.model.get('refValUser')) {
                templateData.userVal1Code = this.model.get('refValUser').get('nickName');
                if (this.model.get('refValUser').get('email')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('email');
                }
                if (templateData.userVal1Code === '' && templateData.userVal1Title) {
                    templateData.userVal1Code = templateData.userVal1Title;
                }
                if (this.model.get('refValUser').get('firstName') && this.model.get('refValUser').get('lastName')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('firstName') + ' ' + this.model.get('refValUser').get('lastName');
                }
            }
            templateData.userVal2Code = '';
            templateData.userVal2Title = '';
            if (this.model.get('refValUser2')) {
                templateData.userVal2Code = this.model.get('refValUser2').get('nickName');
                if (this.model.get('refValUser2').get('email')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('email');
                }
                if (templateData.userVal2Code === '' && templateData.userVal2Title) {
                    templateData.userVal2Code = templateData.userVal2Title;
                }
                if (this.model.get('refValUser2').firstName && this.model.get('refValUser2').get('lastName')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('firstName') + ' ' + this.model.get('refValUser2').get('lastName');
                }
            }

            templateData.errorMessage = '';
            if (templateData.codeErr) {
                templateData.errorMessage = templateData.codeErr + ' ';
            }
            if (templateData.justificatory) {
                templateData.errorMessage = templateData.justificatory;
            }

            templateData.showWellCompareGraph = !!(this.model.get('result') && this.model.get('curves').any(function (curve) {
                return curve.get('type') === 'AMP' || curve.get('type') === 'AMP_IC' || curve.get('type') === 'MUTV' || curve.get('type') === 'MELT';
            }));
            templateData.allowToNeg = templateData.result !== 'N' && templateData.propC && this.model.get('codeErr') ? Object.keys(templateData.propC).some(function (k) {
                return ~k.indexOf('Amp');
            }) : false;

            templateData.commentErrAuto = this.model.get('commentErrAuto');

            var dyeToDisplay = '';
            var colors = [];
            var curveSorted = this.model.get('curves').sortBy(_.bind(function (curve) {
                var refDye = this.model.get('refPcrWell').get('run').get('dyes').find(function (dye) {
                    return dye.getCurveName() === curve.get('name');
                });

                return refDye ? refDye.get('channel') : curve.get('name');
            }, this));
            _.each(curveSorted, _.bind(function (curve) {
                dyeToDisplay = curve.get('name');
                var refDye = this.model.get('refPcrWell').get('run').get('dyes').find(function (dye) {
                    return dye.getCurveName() === curve.get('name');
                });
                var color;
                if (refDye) {
                    dyeToDisplay = refDye.get('shortName') ? refDye.get('shortName') : '';
                    color = refDye.get('color') ? refDye.get('color') : colorUtils.colorTarget(refDye.get('channel'), this.model.get('refPcrWell').get('run'), this.model, null, curve);
                }
                if (this.model.get('internalControl') && this.model.get('refPcrWell').get('run').get('settings')['IC-PARAM']) {
                    color = this.model.get('refPcrWell').get('run').get('settings')['IC-PARAM'].split(';')[0];
                }
                if (!color) {
                    color = 'transparent';
                }
                colors.push(color);
            }, this));
            var borderImage;
            if (colors.length === 0) {
                borderImage = 'border-color: transparent';
            } else if (colors.length === 1) {
                borderImage = 'border-color: ' + colors[0];
            } else {
                borderImage = 'border-color: transparent';
            }

            templateData.dyeToDisplay = dyeToDisplay;
            templateData.dyeColorToDisplay = borderImage;

            templateData.canConfirm = templateData.canNegative = Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL1) ||
                Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL2) ||
                Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL12) ||
                Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL_EXPERT);

            templateData.hasProperties = this.model.get('properties') && this.model.get('properties').length > 0;
            return templateData;
        },

        onConfirmResult: function () {
            var defer = $.Deferred();
            var isControl = ['RC', 'PC', 'OC', 'NC'].includes(this.model.get('refPcrWell').get('smpType'));
            if (isControl) {
                Bootbox.prompt(_.i18n('pcrWellResult.confirm.justificatory'), _.bind(function (justificatory) {
                    defer.resolve(justificatory);
                }, this));
            } else {
                defer.resolve();
            }
            defer.done(_.bind(function (justificatory) {
                if ((isControl && justificatory) || !isControl) {
                    $('.js-global-loader').show();
                    this.model.confirmResult(justificatory)
                        .done(_.bind(function () {
                            // needed to trigger the graphic refresh on render in 'wellDetailView -> renderSafe -> refreshView'
                            this.model.get('refPcrWell').trigger('change', this.model.get('refPcrWell'));
                        }, this))
                        .always(_.bind(function () {
                            $('.js-global-loader').hide();
                        }, this));
                }
            }, this));
        },

        onNegativeResult: function () {
            var defer = $.Deferred();
            var isControl = ['RC', 'PC', 'OC', 'NC'].includes(this.model.get('refPcrWell').get('smpType'));
            if (isControl) {
                Bootbox.prompt(_.i18n('pcrWellResult.negative.justificatory'), _.bind(function (justificatory) {
                    defer.resolve(justificatory);
                }, this));
            } else {
                defer.resolve();
            }
            defer.done(_.bind(function (justificatory) {
                if ((isControl && justificatory) || !isControl) {
                    $('.js-global-loader').show();
                    this.model.negativeResult(justificatory)
                        .always(_.bind(function () {
                            $('.js-global-loader').hide();
                        }, this));
                }
            }, this));
        },

        onEditWellTargetResult: function () {
            var createEditView = new EditWellView({
                model: this.model.get('refPcrWell'),
                modelResult: this.model,
                wellResColor: this.wellResColor,
                newItem: false
            });
            createEditView.show({
                title: _.i18n('well.info.edit'),
                className: 'popupWellView'
            });
        },

        onBtnGraphClick: function () {
            var collection = new Backbone.Collection();
            this.model.get('refPcrWell').get('run').get('wells').each(_.bind(function (well) {
                well.get('results').filter(_.bind(function (result) {
                    if (result.get('refAssayResult').get('id') === this.model.get('refAssayResult').get('id')) {
                        collection.push(result);
                    }
                }, this));
            }, this));


            this.displayGraphView = new DisplayPopupGraphLayoutView({
                model: this.model,
                currentTarget: null,
                models: collection,
                newItem: false,
                hideExtraPoint: true
            });

            this.displayGraphView.graphToDisplay = this.model.get('refPcrWell').getGraphToDisplay();
            this.displayGraphView.hideOkButton = true;
            this.displayGraphView.hideNextPreviousButton = true;
            this.displayGraphView.selectionMode = false;
            this.displayGraphView.show({
                title: _.i18n('well.compare.curv'),
                className: 'popupWellDetailsGraph'
            });
        },

        onRender: function () {
            PcrWellResultController.show(this.model, 'WellTarget', this.getRegion('result'));
            PcrWellResultController.show(this.model, 'Well', this.getRegion('displayMode'));
        }
    });
});

