define('translation',[
    'module',
    'jquery',
    'underscore',
    'polyglot',
    'lodash',
    'bootbox',
    'settings',
    'cookies',
    'logger'
], function (module,
             $,
             _,
             Polyglot,
             lodash,
             bootbox,
             Settings,
             Cookies) {
    'use strict';

    var polyglot = new Polyglot();

    /**
     * Create the global object with pair/key (localized/text key) eg: fr: {common.save: save}
     * @param translationObj
     * @param tObject
     */
    function setTranslation(translationObj, tObject) {
        var code, patt;

        if (tObject && tObject.code && tObject.text) {
            patt = Settings.get('application') + '.';
            code = tObject.code.replace(patt, '');

            _.each(tObject.text.localized, function (item, key) {
                if (!translationObj[key]) {
                    translationObj[key] = {};
                }
                translationObj[key][code] = tObject.text.localized[key];
            });

            //if the localized is not available for a text we add the localized with the english translation
            if (!tObject.text.localized[Settings.get('lang')]) {
                if (!translationObj[Settings.get('lang')]) {
                    translationObj[Settings.get('lang')] = {};
                }
                translationObj[Settings.get('lang')][code] = tObject.text.localized.en;
            }
        }
    }

    module.exports = {
        isLoaded: false,
        fetchTranslations: function (app) {
            polyglot.prefix = Settings.get('application');
            var translationObj = {};
            return $.ajax({
                async: false,
                url: Settings.url('rest/public/texts/app/' +
                    (app || Settings.get('application')), null, true)
            }).success(_.bind(function (data) {
                _.each(data, function (item) {
                    setTranslation(translationObj, item);
                });
                polyglot.extend(translationObj);
                //Override polyglot.t function and add localized prefix eg: fr.xxxxxx
                //if the localize is not specify use the application localized
                lodash.i18n = _.bind(function (key, localized, param) {
                    var t = polyglot.t((localized || Settings.get('lang')) + '.' + key);
                    if (param) {
                        _.each(Object.entries(param), function (entryKeyValue) {
                            t = t.replaceAll('[' + entryKeyValue[0] + ']', entryKeyValue[1]);
                        });
                    }

                    if (Cookies.get('gm-displayI18nCode')) {
                        return key;
                    } else {
                        return t;
                    }
                }, polyglot);
                _.i18n = lodash.i18n;

                bootbox.addLocale(Settings.get('lang'), {
                    OK: _.i18n('common.ok'),
                    CANCEL: _.i18n('common.cancel'),
                    CONFIRM: _.i18n('common.confirm')
                });
                bootbox.setLocale(Settings.get('lang'));
            }, this));
        },

        /**
         * Filter the translation result by key filter
         * @param translations object
         * @param filter
         */
        filterTranslations: function (translations, filter) {
            var result = [];
            filter = filter || '.title';
            _.each(translations, function (item) {
                if (item.code.indexOf(filter) !== -1) {
                    result.push(item);
                }
            });
            return result;
        }
    };
});

