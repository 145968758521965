define('prepWellContentView',[
    'module',
    'template!prepWellContentView',
    'underscore',
    'jquery',
    'dialogFormView',
    'savingBehavior',
    'app',
    'wellUtils',
    'runController'
], function (
    module,
    Tpl,
    _,
    $,
    DialogFormView,
    SavingBehavior,
    App,
    WellUtils,
    RunController
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            showKitsLots: '.js-show-kitsLots',
            blockLoading: '.empty-page-loader',
            assayPlus: '.assayWaiting .mdi-plus-box-outline',
            assayMinus: '.assayWaiting .mdi-minus-box-outline',
            copyParameters: '.js-copyParameters'
        },

        events: {
            'click .js-show-kitsLots': 'onShowKitsLots',
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click .js-show-pcrWell-from-prepWell': 'onShowPcrWellFromPrepWell',
            'click .js-show-prepWell': 'onShowPrepWell',
            'click @ui.assayPlus': 'onAddAssay',
            'click @ui.assayMinus': 'onRemoveAssay'
        },

        modelEvents: {
            'change': 'render',
            'change:smpId': 'render'
        },

        onShowPcrWellFromPrepWell: function (e) {
            var $target = $(e.currentTarget);
            var datSecId = $target.attr('data-secId');

            if (this.pcrWellsFromPrep) {
                var pcrWellModel = _.find(this.pcrWellsFromPrep, _.bind(function (obj) {
                    return obj.get('secId') === datSecId;
                }, this));

                if (pcrWellModel) {
                    this.ui.blockLoading.show();

                    var PcrRunService = require('services/caccounts/pcrruns');
                    var pcrRunModel = PcrRunService.getModel({secId: pcrWellModel.get('run').get('secId')});
                    pcrRunModel.fetch().done(_.bind(function () {
                        pcrWellModel.set('run', pcrRunModel);
                        require(['pcrWellController'], _.bind(function (PcrWellController) {
                            PcrWellController.showDetails({model: pcrWellModel});
                        }, this));
                        this.ui.blockLoading.hide();
                    }, this));
                }
            }
        },

        onShowPrepWell: function (e) {
            var $target = $(e.currentTarget);
            var datSecId = $target.attr('data-secId');
            var PrepWellService = require('services/caccounts/prepwells');
            var prepWell = PrepWellService.getModel({secId: datSecId});
            require(['prepWellController'], _.bind(function (PrepWellController) {
                PrepWellController.showDetails({model: prepWell});
            }, this));
        },

        initialize: function (options) {
            this.cSelection = options.cSelection;
            this.pcrWellsFromPrep = this.options.pcrWellsFromPrep;
        },

        serializeData: function () {
            var templateData = {
                pos: this.model.get('pos'),
                smpType: this.model.get('smpType'),
                pooled: this.model.get('pooled'),
                smpId: this.model.get('smpId') ? this.model.get('smpId').get('code') : '',
                repeatStatus: this.model.get('repeatStatus'),
                dilution: this.model.get('dilution'),
                wellsPooled: this.model.get('wellsPooled').map(function (well) {
                    return {
                        runName: well.get('run').get('name'),
                        pos: well.get('pos'),
                        smpId: well.get('smpId') ? well.get('smpId').get('code') : ''
                    };
                }),
                wellsPool: this.model.get('wellsPool').map(function (well) {
                    return {
                        runName: well.get('run').get('name'),
                        pos: well.get('pos'),
                        smpId: well.get('smpId') ? well.get('smpId').get('code') : ''
                    };
                })
            };

            templateData.otherWell = null;
            if (_.any(this.model.get('otherPrepWells'), function (well) {
                return well.repeatStatus;
            })) {
                templateData.otherWell = 'repeat';
            }
            if (templateData.otherWell === null && _.any(this.model.get('otherPrepWells'), function (well) {
                return !well.repeatStatus;
            })) {
                templateData.otherWell = 'other';
            }

            templateData.refLmbExtractor = this.model.get('run').get('extractionDetails').get('refLmbExtractor') && this.model.get('run').get('extractionDetails').get('refLmbExtractor').get('code') ?
                this.model.get('run').get('extractionDetails').get('refLmbExtractor').get('code') : '-';

            templateData.refExtractionMethod = this.model.get('run').get('extractionDetails').get('refExtractionMethod') && this.model.get('run').get('extractionDetails').get('refExtractionMethod').get('code') ?
                this.model.get('run').get('extractionDetails').get('refExtractionMethod').get('code') : '-';

            var pcrWellsFromPrepJson = [];
            _.each(this.pcrWellsFromPrep, _.bind(function (well) {
                var wellRes = well.getWellRes();
                var wellResults = well.getTargetsResultIcons(false);
                var wellJson = {
                    runName: well.get('run').get('name'),
                    pos: well.get('pos'),
                    secId: well.get('secId'),
                    assay: well.get('refAssay') ? well.get('refAssay').get('code') : '-'
                };
                wellJson.wellResNumFormated = wellRes && wellRes.get('quantificationFormatted') ? wellRes.get('quantificationFormatted') : '';
                wellJson.wellResColor = this.getWellResColor(wellRes);
                wellJson.results = wellResults;
                if (wellRes) {
                    wellJson.wellRes = {
                        targetCode: wellRes.get('targetCode'),
                        cts: wellRes.get('cts'),
                        resCode: wellRes.get('resCode'),
                        iconHtml: wellRes.getResultIcon(true).iconHtml
                    };
                }
                pcrWellsFromPrepJson.push(wellJson);
            }, this));

            templateData.pcrWellsFromPrep = pcrWellsFromPrepJson;

            templateData.concatComment = (this.model.get('smpId').get('lisComment') ? this.model.get('smpId').get('lisComment') : '') + ' - ' + (this.model.get('smpId').get('comment') ? this.model.get('smpId').get('comment') : '');
            if (templateData.concatComment === ' - ') {
                templateData.concatComment = '';
            }

            templateData.pcrKitsLots = this.model.get('pcrKitLots').map(function (item) {
                return {
                    code: item.get('kit').get('code'),
                    kitLot: item.get('code')
                };
            }, this);

            templateData.pcrKitsLotsForValidation = this.model.get('pcrKitLotsForValidation').map(function (item) {
                return {
                    code: item.get('kit').get('code'),
                    kitLot: item.get('code')
                };
            }, this);

            templateData.assaysToDisplay = WellUtils.getFormattedAssayStatus(this.model, 'wellEditPopup');
            templateData.displayPool = !this.model.get('wellsPool').isEmpty();
            templateData.displayPooled = !this.model.get('wellsPooled').isEmpty();
            return templateData;
        },

        getWellResColor: function (wellRes) {
            var wellResColor;
            if (wellRes && wellRes.get('result')) {
                wellResColor = wellRes.get('result').get('color');
            } else {
                wellResColor = 'transparent';
            }
            return wellResColor;
        },

        onRender: function () {
            this.$el.find('[data-toggle="tooltip"]').tooltip();
        },

        onCancel: function () {
            this.hide();
        },

        hide: function () {
            this.triggerMethod('wellDetail:destroy');
            this.triggerMethod('hide');
        },

        onShowKitsLots: function () {
            this.triggerMethod('wellDetail:showKitsLotsPopup');
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.triggerMethod('hide:label');
            }
            console.error(response);
        },
        onAddAssay: function () {
            RunController.assignAssay(this.model.get('run'), this.model.get('pos'), this.model.get('pos'));
        },
        onRemoveAssay: function () {
            RunController.removeAssay(this.model.get('run'), this.model.get('pos'), this.model.get('pos'));
        }
    });
});

