/**
 * Created by Matteo on 11/07/2015.
 */
define('loginShowView',[
    'module',
    'backbone.marionette',
    'underscore',
    'app',
    'template!loginViewTpl',
    'template!browserNotCompatibleTpl',
    'moment'
], function (
    module,
    Marionette,
    _,
    App,
    viewTpl,
    BrowserNotCompatibleTpl,
    moment
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: viewTpl,
        templateBrowserNotCompatible: BrowserNotCompatibleTpl,
        className: 'login-form-container',

        initialize: function (options) {
            this.textAdditionnal = options.textAdditionnal;
        },

        getTemplate: function () {
            var browser = navigator.userAgent.toLowerCase();
            var isInternetExplorer = /trident/.test(browser) || /msie/.test(browser);
            if (isInternetExplorer) {
                return this.templateBrowserNotCompatible;
            }
            return this.template;
        },
        triggers: {
            'click a.login-view-sign-in': 'login:sign:in'
        },

        ui: {
            userName: 'input.login-view-username',
            password: 'input.login-view-password',
            checkbox: 'input.js-keep-logged-in',
            form: 'form.js-form-submit',
            txtError: '.txt-error'
        },

        events: {
            'keyup @ui.userName': 'onKeyUp',
            'keyup @ui.password': 'onKeyUp',
            'focus @ui.userName': 'onFocus',
            'focus @ui.password': 'onFocus'
        },

        onRender: function () {
            if (this.ui.userName.val()) {
                this.ui.password.removeClass('empty');
            }
        },

        serializeData: function () {
            return {
                year: moment().year(),
                textAdditionnal: this.textAdditionnal
            };
        },

        onKeyUp: function (e) {
            var charCode = (e.which) ? e.which : event.keyCode;

            if (charCode === 13) {
                this.trigger('login:keyup');
            }
        },

        onFocus: function () {
            this.ui.form.removeClass('has-error');
            this.ui.userName.removeClass('invalid');
            this.ui.password.removeClass('invalid');
        },

        onError: function (requestError) {
            var userName = this.ui.userName.val(),
                password = this.ui.password.val();

            if (_.isEmpty(userName) || requestError) {
                this.ui.form.addClass('has-error');
                this.ui.userName.addClass('invalid');
                this.ui.userName.addClass('focus');
                this.ui.txtError.show();
            }
            if (_.isEmpty(password) || requestError) {
                this.ui.form.addClass('has-error');
                this.ui.password.addClass('invalid');
                this.ui.password.addClass('focus');
                this.ui.txtError.show();
            }
        }
    });
});
