define('services/global/token',[
    'jquery',
    'underscore',
    'settings',
    'module'
], function (
    $,
    _,
    Settings,
    module
) {
    'use strict';

    module.exports = {
        findBy: function () {
            var url = Settings.url('rest/v2/tokens/'),
                defer = $.Deferred();
            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        }
    };
});

