/**
 * Created by RKL on 05/08/2015.
 */
define('forgotShowController',[
    'module',
    'underscore',
    'app',
    'forgotShowView',
    'settings'
], function (
    module,
    _,
    App,
    View
) {
    'use strict';

    module.exports = {
        errorCodes: {
            notValidEmail: _.i18n('common.invalidMailAddress'),
            requestError: _.i18n('common.request.error')
        },

        showForgot: function () {
            var view = new View();

            view.listenTo(
                view,
                'forgot:change:email',
                _.bind(this.changeEmail, this)
            );
            view.listenTo(
                view,
                'forgot:send:request',
                _.bind(this.checkRequest, this)
            );

            App.regions.getRegion('main').show(view);
        },

        checkRequest: function (obj) {
            this.changeEmail(obj, 'send');
        },

        changeEmail: function (view, send) {
            view = send === 'send' ? view : view.view;
            this.view = view;

            this.validateEmail(view, send);
        },

        validateEmail: function (view, send) {
            var email = view.ui.email.val(),
                pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                res = pattern.test(email);

            if (res) {
                if (send === 'send') {
                    this.sendRequest(email);
                }
            } else {
                this.view.emailError(this.errorCodes.notValidEmail);
            }
        },

        sendRequest: function (email) {
            var that = this,
                SessionService = require('services/session'),
                model = SessionService.postForgotPassword(),
                url = document.location.origin +
                    document.location.pathname + '#reset_password';

            model.save({email: email, url: url}, {
                success: function (model, response) {
                    if (response.value === 'compuzz.error.warning.login.emailSend') {
                        App.trigger('thank_you:show', 'fromForgot');
                    } else {
                        that.view.emailError(_.i18n(response.value));
                    }
                },
                error: function () {
                    that.view.emailError(that.errorCodes.requestError);
                }
            });
        }
    };
});
