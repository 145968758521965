define('services/customers/customers',[
    'app',
    'jquery',
    'backbone',
    'settings',
    'module'
], function (
    App,
    $,
    Backbone,
    Settings,
    module
) {
    'use strict';

    /**
     * Model
     */
    var Customer = Backbone.Model.extend({
        defaults: {
            aProfile: null,
            cAccountId: null,
            city: null,
            code: null,
            comment: null,
            companyName: null,
            companyNumber: null,
            countryCode: null,
            email: null,
            emailCustomerService: null,
            encodedValue: null,
            endUserId: null,
            fax: null,
            firstName: null,
            houseNumber: null,
            lastName: null,
            locales: null,
            login: null,
            logoUid: null,
            name: null,
            password: null,
            refAgent: null,
            secId: null,
            street: null,
            tel: null,
            userType: null,
            vatNumber: null,
            webSite: null,
            workName: null,
            zip: null,
            addressL1: null,
            addressL2: null,
            other: null,
            priceListCode: null,
            subscribed: true
        },

        postUrl: function () {
            return Settings.url('rest/endusers.json');
        },

        deleteUrl: function () {
            return Settings.url('rest/endusers.json/' + this.get('secId'));
        },

        save: function () {
            this.url = this.postUrl();
            this.set('code', this.get('endUserId'));
            return Backbone.Model.prototype.save.call(this);
        },

        idAttribute: 'secId'
    });

    module.exports = {

        getCustomerModel: function (id) {
            var customer = new Customer();

            customer.url = Settings.url('rest/endusers.json/' + id);

            return customer;
        }
    };
});

