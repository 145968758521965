
define('template!kitLotsActionRegionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h1>' +
((__t = ( _.i18n('lot.' + status.toLowerCase()) )) == null ? '' : __t) +
'</h1>\n<div class="js-value"></div>\n<div class="action">\n    <div class="d-f baseline" style="justify-content: space-around;">\n        ';
 if(forAction) { ;
__p += '\n        <span class="mdi mdi-adjust clickable js-edit" data-change-status="true" title="' +
((__t = ( _.i18n('lot.status.activate') )) == null ? '' : __t) +
'"></span>\n        ';
 } else { ;
__p += '\n        ';
 if(backToStatus) { ;
__p += '\n        <span class="mdi mdi-backup-restore clickable js-status" title="' +
((__t = ( _.i18n('lot.status.reset') )) == null ? '' : __t) +
'"></span>\n        ';
 } ;
__p += '\n        ';
 if(forEdit) { ;
__p += '\n        <span class="mdi mdi-pencil clickable js-edit" data-change-status="false" title="' +
((__t = ( _.i18n('lot.edit') )) == null ? '' : __t) +
'"></span>\n        ';
 } ;
__p += '\n        ';
 if((status === 'VALIDATED_1' || status === 'VALIDATED_2') && isTested) { ;
__p += '\n        <span class="mdi mdi-file-compare clickable js-wells" title="' +
((__t = ( _.i18n('lot.wells') )) == null ? '' : __t) +
'"></span>\n        ';
 } ;
__p += '\n    </div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

