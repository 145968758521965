define('assayResultView',[
    'module',
    'backbone.marionette',
    'template!assayResultTpl',
    'underscore',
    'jquery',
    'autocompleteView',
    'dialogFormView',
    'arrayCustomCurveView',
    'arrayCriCurveView',
    'arrayCustomAssayResultView',
    'arrayInputView',
    'dynamicCreateEditTypeParam'
], function (
    module,
    Marionette,
    Tpl,
    _,
    $,
    AutocompleteView,
    DialogFormView,
    ArrayCustomCurveView,
    ArrayCriCurveView,
    ArrayCustomAssayResultView,
    ArrayInputView,
    TypeParamView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            dropDownInput: '.js-dropDownRegion-input',
            sequenceValidation: '.js-sequenceValidation',
            sequenceDisplayGroup: '.js-sequenceDisplayGroup',
            ic: '.js-ic'
        },

        className: 'assayResult',

        events: {
            'focus @ui.input': 'onFocus',
            'focus @ui.dropDownInput': 'onDropdownFocus',
            'change @ui.input': 'onInputChange',
            'change .js-sequenceDisplayGroup': 'onChangeCheckBoxOVA',
            'click .delete-row': 'onDelete',
            'click .js-radio': 'onClickRadioButton',
            'change .js-radio': 'onChangeRadioButton'
        },

        regions: {
            selectMbAna: '.js-select-mbana-region',
            selectAlgoResult: '.js-algoResult',
            selectAlgoValidation: '.js-algoValidation',
            selectAlgoExport: '.js-algoExport'
        },

        modelEvents: {
            'change:refMbAna': 'renderMbAna',
            'change:ic': 'render',
            'change:sequenceDisplayGroup': 'render',
            'change:algoResult': 'renderAlgoResult',
            'change:algoExport': 'renderAlgoExport',
            'change:algoValidation': 'renderAlgoValidation'
        },

        initialize: function () {
            this.listenTo(this.model.get('assayConfiguration'), 'change:cyclerPublic', _.bind(this.renderAlgoResult, this));
        },
        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.code = this.model.get('assayConfiguration').get('code');
            templateData.secId = this.model.get('secId') ? this.model.get('secId') : '';
            return templateData;
        },

        onClickRadioButton: function (e) {
            if (e.currentTarget.attributes['data-field-name'].value === 'ic' && this.model.get('ic') && e.currentTarget.checked) {
                this.model.set('ic', false);
            } else if (e.currentTarget.attributes['data-field-name'].value === 'sequenceDisplayGroup' && this.model.get('sequenceDisplayGroup') && e.currentTarget.checked) {
                this.model.set('sequenceDisplayGroup', 0);
            }
        },
        onChangeRadioButton: function (e) {
            if (e.currentTarget.attributes['data-field-name'].value === 'ic') {
                this.model.get('assayConfiguration').get('results').each(function (model) {
                    var data = [];
                    data.ic = false;
                    var previousVar = model.get('ic');
                    model.set(data, {silent: true});
                    if (previousVar !== model.get('ic')) {
                        model.trigger('change:ic');
                    }
                });
                this.model.set('ic', e.currentTarget.checked);
            } else if (e.currentTarget.attributes['data-field-name'].value === 'sequenceDisplayGroup') {
                this.model.get('assayConfiguration').get('results').each(function (model) {
                    var data = [];
                    data.sequenceDisplayGroup = 0;
                    var previousVar = model.get('sequenceDisplayGroup');
                    model.set(data, {silent: true});
                    if (previousVar !== model.get('sequenceDisplayGroup')) {
                        model.trigger('change:sequenceDisplayGroup');
                    }
                });
                this.model.set('sequenceDisplayGroup', e.currentTarget.checked ? 1 : 0);
            }
        },

        onRender: function () {
            this.renderMbAna();
            this.renderAlgoResult();
        },
        renderAlgoValidation: function () {
            var CodeListService = require('services/admin/codelist');
            var selectAlgoValidationtView = new TypeParamView({
                fieldName: 'algoValidation',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGO-VALAUT'}),
                readOnly: this.model.get('algoResult') && ['SPO_OVAR', 'COMB', 'COMBCUTOFF', 'SCID'].includes(this.model.get('algoResult').type)
            });
            this.getRegion('selectAlgoValidation').show(selectAlgoValidationtView);
        },
        renderAlgoExport: function () {
            var CodeListService = require('services/admin/codelist');
            var selectAlgoExportView = new TypeParamView({
                fieldName: 'algoExport',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGOTEXPORT'}),
                readOnly: this.model.get('algoResult') && this.model.get('algoResult').type === 'SPO_OVAR'
            });
            this.getRegion('selectAlgoExport').show(selectAlgoExportView);
        },
        renderMbAna: function () {
            var MbAnaService = require('services/caccounts/mbanas');
            this.autocompleteMbAnaView = new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaService.getAutocompleteParam({
                    modelProperty: 'refMbAna',
                    callBackAdd: this.onAddMbAna,
                    callBackEdit: this.onEditMbANa,
                    model: this.model.get('refMbAna')
                })));
            this.getRegion('selectMbAna').show(this.autocompleteMbAnaView);
        },
        renderAlgoResult: function () {
            var CodeListService = require('services/admin/codelist');
            var selectAlgoResultView = new TypeParamView({
                fieldName: 'algoResult',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGO-RES'}),
                customInput: _.bind(this.onShowAlgoRes, this),
                display: {
                    type: {
                        'class': 'col-w-20'
                    },
                    param: {
                        'class': 'col-w-80'
                    }
                }
            });
            this.getRegion('selectAlgoResult').show(selectAlgoResultView);
            if (this.model.get('algoResult')) {
                if (this.model.get('algoResult').type === 'SPO_OVAR') {
                    this.model.set({
                        algoExport: {type: 'NO', param: null},
                        algoValidation: {type: 'AUT', param: '2'},
                        sequenceValidation: null
                    }, {silent: true});
                    this.ui.sequenceValidation.attr('disabled', 'disabled');
                    this.ui.sequenceValidation.attr('readonly', 'readonly');
                    this.ui.sequenceValidation.val('');
                } else if (['COMB', 'COMBCUTOFF', 'SCID'].includes(this.model.get('algoResult').type)) {
                    this.model.set({
                        algoValidation: {type: 'AUT', param: '2'},
                        sequenceValidation: null
                    }, {silent: true});
                    this.ui.sequenceValidation.attr('disabled', 'disabled');
                    this.ui.sequenceValidation.attr('readonly', 'readonly');
                    this.ui.sequenceValidation.val('AUT;2');
                }
            } else {
                this.ui.sequenceValidation.attr('disabled', null);
                this.ui.sequenceValidation.attr('readonly', null);
            }
            this.renderAlgoExport();
            this.renderAlgoValidation();
        },

        onShowAlgoRes: function () {
            switch (this.model.get('algoResult').type) {
                case 'MUTV':
                case 'AMP':
                case 'AMP_IC':
                    return new ArrayCustomCurveView({
                        model: this.model,
                        cyclerPublicSecId: this.model.get('assayConfiguration').get('cyclerPublic') ? this.model.get('assayConfiguration').get('cyclerPublic').get('secId') : undefined,
                        assayConfigurationResultTargets: this.model.get('assayConfiguration').get('results').chain().filter(function (result) {
                            return result.get('algoResult') && result.get('algoResult').type === 'AMP';
                        }).map(function (result) {
                            return {code: result.get('refMbAna').get('code'), name: result.get('refMbAna').get('name')};
                        }).value()
                    });
                case 'CRI':
                    return new ArrayCriCurveView({
                        model: this.model,
                        cyclerPublicSecId: this.model.get('assayConfiguration').get('cyclerPublic') ? this.model.get('assayConfiguration').get('cyclerPublic').get('secId') : undefined
                    });
                case 'COMB':
                case 'COMBCUTOFF':
                case 'STD':
                case 'SEEGTRIPLE':
                    return new ArrayCustomAssayResultView({
                        model: this.model,
                        fieldName: 'algoResult',
                        fieldNameCode: this.model.get('algoResult').type
                    });
                default:
                    return new ArrayCustomAssayResultView({
                        model: this.model,
                        fieldName: 'algoResult',
                        fieldNameCode: this.model.get('algoResult').type
                    });
            }
        },
        onChangeCheckBoxOVA: function (e) {
            if (e.currentTarget.checked) {
                this.model.get('assayConfiguration').get('results').each(function (model) {
                    var data = [];
                    data.sequenceDisplayGroup = 0;
                    model.set(data, {silent: true});
                });
            }
            this.model.set('sequenceDisplayGroup', e.currentTarget.checked ? 1 : 0);
            this.model.get('assayConfiguration').get('results').trigger('change', this.model.get('assayConfiguration').get('results'), {});
        },

        onEditMbANa: function (secId) {
            var MbAnaService = require('services/caccounts/mbanas');
            var model = MbAnaService.getModel({secId: secId});
            model.fetch().done(_.bind(function () {
                this.showEditAddMbAnaView(model, false);
            }, this));
        },

        onAddMbAna: function () {
            var MbAnaService = require('services/caccounts/mbanas');
            var model = MbAnaService.getModel();
            this.showEditAddMbAnaView(model, true);
        },

        showEditAddMbAnaView: function (model, newItem) {
            require(['mbAnaCreateEditLayoutView'], _.bind(function (MbAnaCreateEditLayoutView) {
                var createEditView = new MbAnaCreateEditLayoutView({
                    model: model,
                    navigateAfterSaving: false,
                    newItem: newItem
                });
                createEditView.on('saved', _.bind(function (model) {
                    this.model.set('refMbAna', model.attributes);
                    this.autocompleteMbAnaView.options.value = model.get('code');
                    this.autocompleteMbAnaView.render();
                }, this));
                createEditView.show({
                    title: _.i18n('menu.admin.mbanas'),
                    className: 'baseTableEditAddPopup'
                });
            }, this));
        },

        onDelete: function () {
            this.trigger('item:delete', this.model);
        }
    });
});

