define('prepWellToolTipView',[
    'module',
    'backbone.marionette',
    'template!prepWellToolTipView'
], function (
    module,
    Marionette,
    Tpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        serializeData: function () {
            var well = this.model;

            var templateData = {
                smpType: well.get('smpType'),
                repeatStatus: well.get('repeatStatus'),
                pooled: well.get('pooled'),
                pos: well.get('pos'),
                smpId: well.get('smpId').get('code'),
                dilution: well.get('dilution')
            };

            templateData.wellsPooled = this.model.get('wellsPooled').map(function (well) {
                return {runName: well.get('refPrepRun').get('name'), pos: well.get('pos'), smpId: {name: well.get('smpId').get('name')}};
            });
            templateData.wellsPool = this.model.get('wellsPool').map(function (well) {
                return {runName: well.get('refPrepRun').get('name'), pos: well.get('pos'), smpId: {name: well.get('smpId').get('name')}};
            });
            templateData.assaysToDisplay = this.options.assaysToDisplay;

            templateData.concatComment = '';
            if (well.get('smpId').get('lisComment')) {
                templateData.concatComment = well.get('smpId').get('lisComment') + ' ';
            }
            if (well.get('smpId').get('comment')) {
                templateData.concatComment = templateData.concatComment + well.get('smpId').get('comment');
            }
            return templateData;
        }
    });
});

