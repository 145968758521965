
define('template!prepWellContentView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row col-xs-12 p-r-0">\n    <div class="col-w-all col-w-100 m-t-10 d-f" style="justify-content: center;">\n        <div class="col-w-all col-w-47" style="padding: 5px;">\n            <div class="col-w-all col-w-100 d-f m-b-2" style="height: 19px;">\n                <div class="col-w-all col-w-19 p-l-5 p-r-5 d-f noWrapHidden">\n                    <label>\n                        ' +
((__t = ( _.i18n('assays.waiting') )) == null ? '' : __t) +
'\n                    </label>\n                </div>\n                <div class="col-w-all col-w-1 d-f noWrapHidden"></div>\n                <div class="col-w-all col-w-5 d-f noWrapHidden">\n                    ' +
((__t = ( assaysToDisplay.assaysIcons.waiting )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-68 d-f css-prepRun-listView-assayWaiting noWrapHidden"\n                     style="font-size: 15px;">\n                    ' +
((__t = ( assaysToDisplay.waiting )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-7 d-f noWrapHidden assayWaiting">\n                    <span class="mdi mdi-plus-box-outline clickable"></span>\n                    <span class="mdi mdi-minus-box-outline clickable"></span>\n                </div>\n            </div>\n            <div class="col-w-all col-w-100 d-f m-b-2" style="height: 19px;">\n                <div class="col-w-all col-w-19 p-l-5 p-r-5 d-f noWrapHidden">\n                    <label>\n                        ' +
((__t = ( _.i18n('assays.running') )) == null ? '' : __t) +
'\n                    </label>\n                </div>\n                <div class="col-w-all col-w-1 d-f noWrapHidden"></div>\n                <div class="col-w-all col-w-5 d-f noWrapHidden">\n                    ';
 if(assaysToDisplay.running.length > 0) { ;
__p += ' ' +
((__t = ( assaysToDisplay.assaysIcons.running )) == null ? '' : __t) +
' ';
 }
                    ;
__p += '\n                </div>\n                <div class="col-w-all col-w-75 d-f css-prepRun-listView-assayRunning noWrapHidden"\n                     style="font-size: 15px;">\n                    ' +
((__t = ( assaysToDisplay.running )) == null ? '' : __t) +
'\n                </div>\n            </div>\n            <div class="col-w-all col-w-100 d-f m-b-2" style="height: 19px;">\n                <div class="col-w-all col-w-19 p-l-5 p-r-5 d-f noWrapHidden">\n                    <label>\n                        ' +
((__t = ( _.i18n('assays.done') )) == null ? '' : __t) +
'\n                    </label>\n                </div>\n                <div class="col-w-all col-w-1 d-f noWrapHidden"></div>\n                <div class="col-w-all col-w-5 d-f noWrapHidden">\n                    ';
 if(assaysToDisplay.done.length > 0) { ;
__p += ' ' +
((__t = ( assaysToDisplay.assaysIcons.done )) == null ? '' : __t) +
' ';
 } ;
__p += '\n                </div>\n                <div class="col-w-all col-w-75 d-f css-prepRun-listView-assayDone noWrapHidden"\n                     style="font-size: 15px;">\n                    ' +
((__t = ( assaysToDisplay.done )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="row col-xs-12 p-r-0 d-f m-t-5">\n    <div class="col-w-all col-w-74 p-l-15 p-r-15 p-t-5 p-b-5 noWrapHidden t-a-l rounded">\n        <div class="col-w-all col-w-100 d-f p-b-5">\n            <div class="col-w-all col-w-100 noWrapHidden t-a-l">\n                <label>' +
((__t = ( _.i18n('pcrwell.from.preprun') )) == null ? '' : __t) +
'</label>\n            </div>\n        </div>\n        <div class="col-w-all col-w-100 d-f">\n            <div class="col-w-all col-w-20 noWrapHidden t-a-l pcrFromPrepTitleDiv ">\n                ' +
((__t = ( _.i18n('well.runname') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l"></div><!--separator-->\n            <div class="col-w-all col-w-5 noWrapHidden t-a-l pcrFromPrepTitleDiv ">\n                ' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l"></div><!--separator-->\n            <div class="col-w-all col-w-10 noWrapHidden t-a-l pcrFromPrepTitleDiv ">\n                ' +
((__t = ( _.i18n('well.assay') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l"></div><!--separator-->\n            <div class="col-w-all col-w-52 noWrapHidden t-a-l pcrFromPrepTitleDiv ">\n                ' +
((__t = ( _.i18n('well.results') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l"></div><!--separator-->\n            <div class="col-w-all col-w-4 noWrapHidden t-a-l"></div><!--separator-->\n        </div>\n        ';
 if(pcrWellsFromPrep && pcrWellsFromPrep.length > 0) { ;
__p += '\n        ';
 _.each(pcrWellsFromPrep, function(pcrWell) { ;
__p += '\n        <div class="col-w-all col-w-100 d-f rounded" style="margin-top: 5px;">\n            <div class="col-w-all col-w-20 noWrapHidden t-a-l bottom-border-cell-light"\n                 title="' +
((__t = ( pcrWell.runName )) == null ? '' : __t) +
'">\n                ' +
((__t = ( pcrWell.runName )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n            <div class="col-w-all col-w-5 noWrapHidden t-a-l bottom-border-cell-light"\n                 title="' +
((__t = ( pcrWell.pos )) == null ? '' : __t) +
'">\n                ' +
((__t = ( pcrWell.pos )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n            <div class="col-w-all col-w-10 noWrapHidden t-a-l bottom-border-cell-light"\n                 title="' +
((__t = ( pcrWell.assay )) == null ? '' : __t) +
'">\n                ' +
((__t = ( pcrWell.assay )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n            <div class="col-w-all col-w-52 noWrapHidden t-a-l bottom-border-cell-light">\n                <div class="col-w-all col-w-100 d-f">\n                    ';
 _.each(pcrWell.results, function(result) { ;
__p += '\n                    ';
 if (result.iconHtml) { ;
__p += '\n                    <div class="col-w-5 noWrapHidden t-a-l"\n                         title="' +
((__t = ( result.targetCode )) == null ? '' : __t) +
' &nbsp; ' +
((__t = ( result.cts )) == null ? '' : __t) +
' &nbsp; ' +
((__t = ( result.resCode )) == null ? '' : __t) +
'">\n                        ' +
((__t = ( result.iconHtml )) == null ? '' : __t) +
'\n                    </div>\n                    ';
 } else { ;
__p += '\n                    <div class="col-w-all col-w-15">\n                    </div>\n                    ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </div>\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n            <div class="col-w-all col-w-4 noWrapHidden t-a-l clickable headerRunActionButton js-read-click"\n                 style="border-radius: 20px; height: 27px; width: 27px; position: relative; bottom: 5px;">\n                <div class="headerRunActionButtonIcon mdi-action-visibility js-show-pcrWell-from-prepWell"\n                     data-secId="' +
((__t = ( pcrWell.secId )) == null ? '' : __t) +
'"\n                     style="font-size: 22px; position: relative; left: 3px; top: 5px;">\n                </div>\n            </div>\n        </div>\n        ';
 }); ;
__p += '\n        ';
 } else { ;
__p += '\n        <div class="col-w-all col-w-100 d-f">\n            <div class="col-w-all col-w-100 noWrapHidden t-a-l bottom-border-cell-light">\n                ' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n        ';
 } ;
__p += '\n    </div>\n    <div class="col-w-all col-w-1 p-r-15 noWrapHidden t-a-l"></div>\n    <div class="col-w-all col-w-25 p-l-15 p-r-15 p-t-5 p-b-5 noWrapHidden t-a-l">\n        <div class="rounded">\n            <div class="col-w-all col-w-100 d-f p-b-5">\n                <div class="col-w-all col-w-100 noWrapHidden t-a-l">\n                    <label>' +
((__t = ( _.i18n('prep.run.ic.kits') )) == null ? '' : __t) +
'</label>\n                </div>\n            </div>\n            ';
 if(pcrKitsLots && pcrKitsLots.length > 0) { ;
__p += '\n            <div class="col-w-all col-w-100 d-f">\n                <div class="col-w-all col-w-49 noWrapHidden t-a-l pcrFromPrepTitleDiv">\n                    ' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-2 noWrapHidden t-a-l pcrFromPrepTitleDiv"></div>\n                <div class="col-w-all col-w-49 noWrapHidden t-a-l pcrFromPrepTitleDiv">\n                    ' +
((__t = ( _.i18n('kitLot') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n            ';
 _.each(pcrKitsLots, function(pcrKitLot) { ;
__p += '\n            <div class="col-w-all col-w-100 d-f" style="height: 22px; margin-top: 5px;">\n                <div class="col-w-all col-w-49 noWrapHidden t-a-l bottom-border-cell-light"\n                     title="' +
((__t = ( pcrKitLot.code )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( pcrKitLot.code )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n                <div class="col-w-all col-w-49 noWrapHidden t-a-l bottom-border-cell-light"\n                     title="' +
((__t = ( pcrKitLot.kitLot )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( pcrKitLot.kitLot )) == null ? '' : __t) +
'\n                </div>\n            </div>\n            ';
 }); ;
__p += '\n            ';
 } else { ;
__p += '\n            <div class="col-w-all col-w-100 d-f">\n                <div class="col-w-all col-w-100 noWrapHidden t-a-l bottom-border-cell-light">\n                    ' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n            ';
 } ;
__p += '\n        </div>\n\n        ';
 if(!_.isEmpty(pcrKitsLotsForValidation)) { ;
__p += '\n        <div class="rounded m-t-10">\n            <div class="col-w-all col-w-100 d-f p-b-5">\n                <div class="col-w-all col-w-100 noWrapHidden t-a-l">\n                    <label>' +
((__t = ( _.i18n('prep.run.ic.kits.validation') )) == null ? '' : __t) +
'</label>\n                </div>\n            </div>\n            <div class="col-w-all col-w-100 d-f">\n                <div class="col-w-all col-w-49 noWrapHidden t-a-l pcrFromPrepTitleDiv">\n                    ' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-2 noWrapHidden t-a-l pcrFromPrepTitleDiv"></div>\n                <div class="col-w-all col-w-49 noWrapHidden t-a-l pcrFromPrepTitleDiv">\n                    ' +
((__t = ( _.i18n('kitLot') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n            ';
 _.each(pcrKitsLotsForValidation, function(pcrKitLot) { ;
__p += '\n            <div class="col-w-all col-w-100 d-f" style="height: 22px; margin-top: 5px;">\n                <div class="col-w-all col-w-49 noWrapHidden t-a-l bottom-border-cell-light"\n                     title="' +
((__t = ( pcrKitLot.code )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( pcrKitLot.code )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n                <div class="col-w-all col-w-49 noWrapHidden t-a-l bottom-border-cell-light"\n                     title="' +
((__t = ( pcrKitLot.kitLot )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( pcrKitLot.kitLot )) == null ? '' : __t) +
'\n                </div>\n            </div>\n            ';
 }); ;
__p += '\n        </div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';
 if(displayPool) { ;
__p += '\n<div class="row col-xs-12 p-r-0 m-t-5">\n    <div class="col-w-all col-w-74 p-l-15 p-r-15 p-t-5 p-b-5 noWrapHidden t-a-l rounded">\n        <div class="col-w-all col-w-100 d-f p-b-5">\n            <div class="col-w-all col-w-100 noWrapHidden t-a-l">\n                <label>' +
((__t = ( _.i18n('prepwell.pools') )) == null ? '' : __t) +
'</label>\n            </div>\n        </div>\n        <div class="col-w-all col-w-100 d-f">\n            <div class="col-w-all col-w-20 noWrapHidden t-a-l pcrFromPrepTitleDiv ">\n                ' +
((__t = ( _.i18n('prepWell.runname') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l"></div><!--separator-->\n            <div class="col-w-all col-w-5 noWrapHidden t-a-l pcrFromPrepTitleDiv ">\n                ' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l"></div><!--separator-->\n            <div class="col-w-all col-w-25 noWrapHidden t-a-l pcrFromPrepTitleDiv ">\n                ' +
((__t = ( _.i18n('well.sample') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l"></div><!--separator-->\n            <div class="col-w-all col-w-4 noWrapHidden t-a-l"></div><!--separator-->\n        </div>\n        ';
 _.each(wellsPool, function(prepWell) { ;
__p += '\n        <div class="col-w-all col-w-100 d-f" style="height: 22px; margin-top: 5px;">\n            <div class="col-w-all col-w-20 noWrapHidden t-a-l bottom-border-cell-light"\n                 title="' +
((__t = ( prepWell.runName )) == null ? '' : __t) +
'">\n                ' +
((__t = ( prepWell.runName )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n            <div class="col-w-all col-w-5 noWrapHidden t-a-l bottom-border-cell-light"\n                 title="' +
((__t = ( prepWell.pos )) == null ? '' : __t) +
'">\n                ' +
((__t = ( prepWell.pos )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n            <div class="col-w-all col-w-25 noWrapHidden t-a-l bottom-border-cell-light"\n                 title="' +
((__t = ( prepWell.pos )) == null ? '' : __t) +
'">\n                ' +
((__t = ( prepWell.smpId.code )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n        </div>\n        ';
 }); ;
__p += '\n    </div>\n</div>\n';
 } ;
__p += '\n';
 if(displayPooled) { ;
__p += '\n<div class="row col-xs-12 p-r-0 m-t-5">\n    <div class="col-w-all col-w-74 p-l-15 p-r-15 p-t-5 p-b-5 noWrapHidden t-a-l rounded">\n        <div class="col-w-all col-w-100 d-f p-b-5">\n            <div class="col-w-all col-w-100 noWrapHidden t-a-l">\n                <label>' +
((__t = ( _.i18n('prepwell.pooleds') )) == null ? '' : __t) +
'</label>\n            </div>\n        </div>\n        <div class="col-w-all col-w-100 d-f">\n            <div class="col-w-all col-w-20 noWrapHidden t-a-l pcrFromPrepTitleDiv ">\n                ' +
((__t = ( _.i18n('prepWell.runname') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l"></div><!--separator-->\n            <div class="col-w-all col-w-5 noWrapHidden t-a-l pcrFromPrepTitleDiv ">\n                ' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l"></div><!--separator-->\n            <div class="col-w-all col-w-25 noWrapHidden t-a-l pcrFromPrepTitleDiv ">\n                ' +
((__t = ( _.i18n('well.sample') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l"></div><!--separator-->\n            <div class="col-w-all col-w-4 noWrapHidden t-a-l"></div><!--separator-->\n        </div>\n        ';
 _.each(wellsPooled, function(prepWell) { ;
__p += '\n        <div class="col-w-all col-w-100 d-f" style="height: 22px; margin-top: 5px;">\n            <div class="col-w-all col-w-20 noWrapHidden t-a-l bottom-border-cell-light"\n                 title="' +
((__t = ( prepWell.runName )) == null ? '' : __t) +
'">\n                ' +
((__t = ( prepWell.runName )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n            <div class="col-w-all col-w-5 noWrapHidden t-a-l bottom-border-cell-light"\n                 title="' +
((__t = ( prepWell.pos )) == null ? '' : __t) +
'">\n                ' +
((__t = ( prepWell.pos )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n            <div class="col-w-all col-w-25 noWrapHidden t-a-l bottom-border-cell-light"\n                 title="' +
((__t = ( prepWell.smpId )) == null ? '' : __t) +
'">\n                ' +
((__t = ( prepWell.smpId )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-w-all col-w-2 noWrapHidden t-a-l bottom-border-cell-light"></div>\n        </div>\n        ';
 }); ;
__p += '\n    </div>\n</div>\n';
 } ;
__p += '\n';

}
return __p
};});

