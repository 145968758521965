define('runCreateFromPlateLayoutView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runCreateFromPlateLayoutView',
    'commonCancelUploadView',
    'customBootboxMessage',
    'autocompleteView'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonCancelUploadView,
    CustomBootboxMessage,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        regions: {
            cancelUploadRegion: '.js-cancel-upload-region',
            selectFileFormat: '.js-select-fileFormat-region'
        },
        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },
        modelEvents: {
            'change:fileFormat': 'onValidateChange'
        },

        fieldsToValidate: function () {
            return [
                {name: 'fileFormat', type: 'required'}
            ];
        },

        initialize: function () {
            this.model = new Backbone.Model({fileFormat: null});
        },

        onValidateChange: function () {
            this.commonCancelUploadView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onRender: function () {
            this.commonCancelUploadView = new CommonCancelUploadView({settingImport: this.options.settingImport, accept: '.xlsx,.xls,.xml,.rdml,.csv'});
            this.listenTo(this.commonCancelUploadView, 'upload:click', _.bind(this.onUpload, this));
            this.listenTo(this.commonCancelUploadView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelUploadRegion').show(this.commonCancelUploadView);
            this.commonCancelUploadView.enableButtons(false);

            var FileFormatPlateLayoutService = require('services/caccounts/fileFormatPlateLayouts');
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(FileFormatPlateLayoutService.getAutocompleteParamForImport({
                    modelProperty: 'fileFormat'
                }))
            );
            this.getRegion('selectFileFormat').show(view);
        },

        onUpload: function (files) {
            var loading = $('.js-global-loader');
            loading.show();
            this.model.set('files', files);
            this.options.entity.createRunFromPlateLayout(this.model)
                .done(_.bind(function () {
                    this.hide();
                    if (this.options.callback) {
                        this.options.callback(arguments);
                    }
                }, this))
                .fail(_.bind(function (response) {
                    alert(response.statusText + ' -- ' + response.status);
                }, this))
                .always(function () {
                    loading.hide();
                });
        }
    });
});
