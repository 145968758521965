define('runPcrContentTargetListView',[
    'module',
    'underscore',
    'jquery',
    'jqgridView',
    'wellUtils',
    'colorUtils',
    'pcrWellResultController'
], function (
    module,
    _,
    $,
    JqGridView,
    WellUtils,
    ColorUtils,
    PcrWellResultController
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: _.template('<table id="<%= jqGridId %>" role="grid"></table><div class="jq-grid-pager-list" id="<%= jqGridPagerId %>"></div>'),
        filtersName: 'wellViewTableListFilters',
        className: 'content',

        ui: function () {
            return _.extend({
                jqGrid: '#' + this.jqGridId.jqGrid,
                jqGridPager: '#' + this.jqGridId.jqGridPager,
                jqgRow: 'tr.jqgrow'
            }, this.gridUi);
        },

        initialize: function () {
            this.jqGridId = {
                jqGrid: _.uniqueId('jq-grid-list'),
                jqGridPager: _.uniqueId('jq-grid-pager-list')
            };
        },
        attributes: {
            style: 'flex: 1; height: 100%;'
        },

        serializeData: function () {
            return {
                jqGridId: this.jqGridId.jqGrid,
                jqGridPagerId: this.jqGridId.jqGridPager
            };
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 9999,
                currentPage: 1
            };
        },

        gridOptions: function (data) {
            var defaultsOptions = this.gridInitOptions(data),
                options = {
                    colModel: [{
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('well.pos'),
                        name: 'refPcrWell.pos',
                        classes: 'well-list-pos-label',
                        formatter: this.defaultFormatter,
                        search: true,
                        sortable: true,
                        index: 'pos',
                        searchoptions: {
                            sopt: ['cn']
                        },
                        fixed: true,
                        width: 40
                    }, {
                        label: _.i18n('well.smpId'),
                        name: 'refPcrWell.smpId',
                        classes: 'well-list-label',
                        formatter: this.codeNameFormatter,
                        search: true,
                        sortable: true,
                        index: 'smpId.name',
                        searchoptions: {
                            sopt: ['cn']
                        },
                        fixed: true,
                        width: 180
                    }, {
                        label: _.i18n('well.refAssay'),
                        name: 'refPcrWell.refAssay',
                        classes: 'well-list-label',
                        formatter: this.codeNameFormatter,
                        search: true,
                        sortable: true,
                        index: 'refAssay.code',
                        searchoptions: {
                            sopt: ['cn']
                        },
                        fixed: true,
                        width: 120
                    }, {
                        label: _.i18n('well.target'),
                        name: 'mbAna',
                        classes: 'well-list-label',
                        formatter: this.codeNameFormatter,
                        search: true,
                        sortable: true,
                        index: 'mbAna.code',
                        searchoptions: {
                            sopt: ['cn']
                        },
                        fixed: true,
                        width: 120
                    }, {
                        label: _.i18n('result'),
                        name: 'pos',
                        classes: 'well-list-label',
                        formatter: _.bind(this.resultFormatter, this),
                        search: false,
                        fixed: true,
                        width: 50
                    }, {
                        label: _.i18n('result'),
                        name: 'result',
                        classes: 'well-list-label',
                        formatter: this.codeNameFormatter,
                        search: true,
                        sortable: true,
                        fixed: true,
                        width: 50
                    }, {
                        label: _.i18n('wellresult.num'),
                        name: 'cts',
                        classes: 'well-list-label',
                        formatter: this.defaultFormatter,
                        search: false,
                        fixed: true,
                        width: 50
                    }, {
                        label: _.i18n('target.quantification'),
                        name: 'quantifications',
                        classes: 'well-list-label',
                        formatter: this.defaultFormatter,
                        search: false,
                        sortable: false,
                        fixed: true,
                        width: 60
                    }, {
                        label: _.i18n('well.valSt'),
                        name: 'valSt',
                        classes: 'well-list-label',
                        formatter: _.bind(this.validationFormater, this),
                        search: false,
                        sortable: true,
                        fixed: true,
                        width: 60
                    }, {
                        label: _.i18n('well.resCodeErr'),
                        name: 'codeErr',
                        classes: 'well-list-label',
                        formatter: this.defaultFormatter,
                        search: true,
                        sortable: true,
                        fixed: true,
                        width: 60
                    }, {
                        label: _.i18n('well.resComment'),
                        name: 'comment',
                        classes: 'well-list-label',
                        formatter: this.defaultFormatter,
                        search: true,
                        sortable: true,
                        fixed: true
                    }
                    ],
                    sortname: this.sortOn ? this.sortOn : 'row', // 'smpId'
                    loadonce: true,

                    onSelectRow: _.bind(function (rowId, bool, e) {
                        if (e.type === 'click') {
                            var filteredRowList = this.ui.jqGrid.getRowData(null, false);
                            var cSelection = [];
                            _.each(filteredRowList, _.bind(function (wellRow) {
                                var pos = $(wellRow.pos).text();
                                if (!cSelection.includes(pos)) {
                                    cSelection.push(pos);
                                }
                            }, this));

                            var well = this.model.get('wells').chain().filter(function (well) {
                                return well.get('results').findWhere({secId: rowId});
                            }).first().value();

                            require(['pcrWellController'], _.bind(function (PcrWellController) {
                                PcrWellController.showDetails({model: well, cSelection: cSelection});
                            }, this));
                        }
                    }, this)
                };

            options = _.defaults(options, defaultsOptions);

            return options;
        },

        resultFormatter: function (cellValue, call, object) {
            var model = this.model.get('wells').findWhere({secId: object.refPcrWell.secId});
            var html;
            var result = model.get('results').findWhere({secId: object.secId});

            html = '<div class="row m-b-0">';
            html += '<div class="col-xs-12 p-r-0" style="display: flex;">';
            if (result) {
                html += PcrWellResultController.generateContent(result, 'TargetList');
            }
            html += '</div>';
            html += '</div>';
            return html;
        },

        validationFormater: function (cellValue, call, object) {
            var valSt = cellValue;
            if (!object.isOvar && valSt !== null && (valSt <= 2 || valSt === 4 || valSt === 3)) {
                var exp = '';
                if (valSt === 2) {
                    exp = 'E';
                }
                var color = '';

                if (valSt <= 2) {
                    color = '#0000FF';
                } else if (valSt === 4) {
                    color = '#00FF00';
                } else if (valSt === 3) {
                    color = '#FF0000';
                }

                return '<div class="row m-b-0">' +
                    '<div class="col-xs-1">' +
                    '<div class="well-square-icon-green">' +
                    '<div class="well-circle-icon p-t-1" style="position:absolute; background-color: ' + color + '">' +
                    '<span style="color:#FFFFFF;font-size:10px;font-weight:400;margin-left:3px;margin-top: -2px;' +
                    'position:absolute;">' + exp + '</span>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
            } else {
                return '';
            }
        },

        onGridLoaded: function (model, rowId) {
            var results = [];
            this.model.get('wells').each(function (well) {
                well.get('results').each(function (result) {
                    var json = result.toJSON();
                    json.isOvar = result.isOvar();
                    results.push(json);
                });
            });

            var data = {
                datatype: 'local',
                data: results,
                rowCount: 9999
            };
            this.setGridData(data, rowId);
        },

        setGridData: function (data, rowId) {
            /** */ this.ui.jqGrid.clearGridData(); /** needed in case of refresh */
            /** */ this.ui.jqGrid.setGridParam(data, true); //overwrite param is needed true to avoid maximum call stack error on grid refresh
            /** needed in case of refresh */
            this.gridOptionsObj = this.gridOptions(data);
            this.onRowCountDone(data, 9999);
            this.triggerMethod('checkRowCount', 9999);
            if (rowId) {
                /** */ this.ui.jqGrid.trigger('reloadGrid', [{current: true}]);
                /** needed in case of refresh */
                this.ui.jqGrid.setSelection(rowId, false); // highlight selected row
            }
            this.setWellListPopover();
        },
        setWellListPopover: function () {
            this.model.get('wells').each(_.bind(function (well, index) {
                if (well.get('pos')) {

                    if ($('.wellPosPopover-' + well.get('pos')).length > 0 &&
                        $('.wellSmpidPopover-' + well.get('pos')).length > 0 &&
                        $('.wellAssayPopover-' + well.get('pos')).length > 0) {

                        var params = {
                            model: well
                        };

                        params.targets = [
                            $('.wellPosPopover-' + well.get('pos')),
                            $('.wellSmpidPopover-' + well.get('pos')),
                            $('.wellAssayPopover-' + well.get('pos'))
                        ];

                        if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                            (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                            params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                        }
                        var placement = 'right';
                        if (index < 3) {
                            placement = 'bottom';
                        } else if (index > this.model.get('wells').length - 4) {
                            placement = 'top';
                        }
                        params.placement = placement;
                        WellUtils.showPcrWellPopover(params);
                    }
                }
            }, this));
        }
    });
});

