define('entities/caccounts/lis',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/setting',
    'entities/caccounts/sampleClass',
    'entities/caccounts/lisConfiguration'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.Lis = Backbone.RelationalModel.extend({
        service: 'services/caccounts/lis',
        fetch: function () {
            this.url = Settings.url('rest/v2/lis/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'parameter': '',
            'lisFollowed': undefined,
            'configurations': undefined,
            'sampleClasses': [],
            description: null,
            comment: null,
            tags: []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'lisFollowed',
            relatedModel: 'Lis',
            includeInJSON: false
        }, {
            type: Backbone.HasMany,
            key: 'configurations',
            relatedModel: 'LisConfiguration',
            collectionType: 'LisConfigurationCollection',
            reverseRelation: {
                key: 'lis',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'sampleClasses',
            relatedModel: 'SampleClass',
            collectionType: 'SampleClassCollection'
        }],


        postUrl: function () {
            return Settings.url('rest/v2/lis/' + this.get('secId'));
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'LISJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Lis',

        importable: true
    });

    app.ns.Lis.role = RolesMixin.LIS;

    app.ns.LisCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/lis/');
        },
        model: app.ns.Lis
    });
});

