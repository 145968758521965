/**
 * Created by GSP on 16/07/2015.
 */
define('services/session',[
    'jquery',
    'backbone',
    'settings',
    'module'
], function (
    $,
    Backbone,
    Settings,
    module
) {
    'use strict';

    /**
     * Model
     */
    var Session = Backbone.Model.extend({});

    /**
     * REST API
     */
    module.exports = {
        login: function (userName, password) {
            var defer = $.Deferred();
            var body = {username: userName, password: password};

            var url = Settings.url('rest/v2/sessions.json/token/login');

            // send POST request to the server
            $.ajax({
                url: url,
                type: 'POST',
                data: JSON.stringify(body),
                dataType: 'json',
                contentType: 'application/json',
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (result) {
                    defer.reject(result);
                }
            });
            return defer.promise();
        },

        getSessionByToken: function (data) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/sessions.json/user/byToken', data);

            $.get(url).done(
                function (resp) {
                    defer.resolve(resp);
                }
            );

            return defer.promise();
        },

        registerNew: function (data) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/caccounts/quick', data);

            $.ajax({
                type: 'POST',
                url: url,
                complete: function (resp) {
                    defer.resolve(resp);
                }
            });
            return defer.promise();
        },

        postForgotPassword: function () {
            var session = new Session({});

            session.url = Settings.url('rest/v2/userpid/forgotPassword');

            return session;
        },

        postChangePassword: function () {
            var session = new Session({});

            session.url = Settings.url('rest/v2/userpid/changePassword');

            return session;
        },

        postResetPassword: function () {
            var session = new Session({});

            session.url = Settings.url('rest/v2/userpid/updatePassword');

            return session;
        },

        createSession: function (session) {
            var defer = $.Deferred();

            $.ajax({
                url: Settings.url('rest/v2/sessions.json'),
                type: 'POST',
                data: JSON.stringify(session),
                dataType: 'json',
                contentType: 'application/json',
                success: function (result) {
                    defer.resolve(result);
                }
            });

            return defer.promise();
        }
    };
});

