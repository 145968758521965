/**
 * Created by OBL on 7/01/2016.
 */
define('privileges',['module'], function (module) {
    'use strict';
    module.exports = {
        ALL: 'ALL',
        ARCHIVE: 'ARCHIVE',
        CHANGE_VALUE: 'CHANGE_VALUE',
        CURRENT: 'CURRENT',
        CURRENT_LOT: 'CURRENT_LOT',
        DELETE: 'DELETE',
        DELIVERED: 'DELIVERED',
        EDIT_RESULT_EXPORTED: 'EDIT_RESULT_EXPORTED',
        EXHAUSTED: 'EXHAUSTED',
        EXPORT: 'EXPORT',
        EXTRACTION: 'EXTRACTION',
        INACTIVE: 'INACTIVE',
        KIT_LOT_TARGET: 'KIT_LOT_TARGET',
        LIS_ANA: 'LIS_ANA',
        MRES: 'MRES',
        ORP: 'ORP',
        PCR_SETUP: 'PCR_SETUP',
        PREPROC: 'PREPROC',
        READ: 'READ',
        REG: 'REG',
        REPEAT: 'REPEAT',
        ROUT: 'ROUT',
        SCAN: 'SCAN',
        SET_TEST: 'SET_TEST',
        TEST: 'TEST',
        VAL1: 'VAL1',
        VAL12: 'VAL12',
        VAL2: 'VAL2',
        VAL2G: 'VAL2G',
        VALIDATED_1: 'VALIDATED_1',
        VALIDATED_2: 'VALIDATED_2',
        VAL_EXPERT: 'VAL_EXPERT',
        WELL_VALIDATION: 'WELL_VALIDATION',
        WRITE: 'WRITE'
    };
});

