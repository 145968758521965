define('pcrWellToolTipDotCollectionView',[
    'module',
    'backbone.marionette',
    'pcrWellToolTipDotItemView'
], function (
    module,
    Marionette,
    PcrWellToolTipDotItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: PcrWellToolTipDotItemView
    });
});
