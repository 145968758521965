
define('template!kitLotsActionGenericQuantityView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="ui-date">\n    <label for="date" class="ui-date">' +
((__t = ( _.i18n('common.date') )) == null ? '' : __t) +
'</label>\n    <div id="date" class="js-date ui-date"></div>\n</div>\n<div class="ui-userpid">\n    <label for="userpid" class="ui-userpid">' +
((__t = ( _.i18n('common.user') )) == null ? '' : __t) +
'</label>\n    <input id="userpid" class="js-userpid js-info-input form-control inputBackground disabled" disabled value="' +
((__t = ( user )) == null ? '' : __t) +
'"/>\n</div>\n<div class="ui-quantity">\n    <label for="quantity">' +
((__t = ( _.i18n('lot.delivered.quantity') )) == null ? '' : __t) +
'</label>\n    <input id="quantity" class="js-info-input form-control inputBackground ui-quantity" value="' +
((__t = ( quantity )) == null ? '' : __t) +
'"/>\n</div>\n<div class="ui-comment">\n    <label for="comment" class="ui-comment">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n    <textarea id="comment" class="ui-comment js-info-input inputBackground form-control ui-comment">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n</div>';

}
return __p
};});

