
define('template!assayVersionCreateEditLayout', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="jq-grid-table-page">\n    <div class="assay-table">\n        <div class="detailed-info-form-container">\n            <div class="saving-label"></div>\n            <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n                <div class="error-msg"></div>\n            </div>\n            <div class="form-horizontal">\n                <div class="form-inputs-container">\n                    <div class="form-group">\n\n                        <label class="col-xs-2 control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.assay') )) == null ? '' : __t) +
'</label>\n\n                        <div class="col-xs-2" style="display: flex;">\n                            <input autocomplete="off"\n                                   class="form-control floating-label js-code inputBackground"\n                                   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                                   data-placement="top" placeholder=""\n                                   type="text" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n                            <span class="clickable btn-edit-assay mdi mdi-eye headerRunActionButtonIcon"\n                                  style="line-height: 22px; font-size: 23px;"></span>\n                        </div>\n                        <div class="col-xs-3">\n                            <input autocomplete="off"\n                                   class="form-control floating-label inputBackground"\n                                   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'" data-placement="top"\n                                   placeholder=""\n                                   type="text" value="' +
((__t = ( name?name:'' )) == null ? '' : __t) +
'">\n                        </div>\n\n                        <label class="col-xs-1 control-label dataNameIdLabel">' +
((__t = ( _.i18n('assay.groupName') )) == null ? '' : __t) +
'</label>\n\n                        <div class="col-xs-2">\n                            <input autocomplete="off"\n                                   class="form-control floating-label inputBackground"\n                                   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                                   data-placement="top"\n                                   placeholder=""\n                                   type="text" value="' +
((__t = ( assayGroupCode )) == null ? '' : __t) +
'">\n                        </div>\n                    </div>\n\n                    <div class="form-group">\n\n                        <label class="col-xs-2 control-label dataNameIdLabel">' +
((__t = ( _.i18n('menu.admin.kitprots')
                            )) == null ? '' : __t) +
'</label>\n\n                        <div class="col-xs-2">\n                            <input autocomplete="off"\n                                   class="form-control floating-label inputBackground"\n                                   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                                   data-placement="top"\n                                   placeholder=""\n                                   type="text" value="' +
((__t = ( kitProtCode )) == null ? '' : __t) +
'">\n                        </div>\n\n                        <label class="col-xs-1 control-label dataNameIdLabel">' +
((__t = ( _.i18n('assay.protocolVariant')
                            )) == null ? '' : __t) +
'</label>\n\n                        <div class="col-xs-1">\n                            <input autocomplete="off"\n                                   class="form-control floating-label inputBackground"\n                                   data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'" data-field-name="protocolVariant"\n                                   data-placement="top"\n                                   placeholder=""\n                                   type="text" value="' +
((__t = ( protocolVariant )) == null ? '' : __t) +
'">\n                        </div>\n\n                        <div class="col-xs-1"></div>\n\n                        <label class="col-xs-1 control-label dataNameIdLabel">' +
((__t = ( _.i18n('assay.volumeBySample')
                            )) == null ? '' : __t) +
'</label>\n\n                        <div class="col-xs-1">\n                            <input class="form-control inputBackground" data-field-name="volumeBySample" min="0"\n                                   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                                   step="0.01"\n                                   type="number"\n                                   value="' +
((__t = ( volumeBySample )) == null ? '' : __t) +
'"/>\n                        </div>\n                    </div>\n\n                    <div class="js-assayConfiguration-region"></div>\n                </div>\n                <!-- liaison lots -->\n                <div class="col-xs-12 m-t-10 m-b-10 p-5 js-kitlots rounded">\n                </div>\n                <!-- comments row -->\n\n                <div class="js-description"></div>\n                <!-- cancel/confirm row -->\n                <div class="cancelConfirmRow">\n                    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n                </div>\n\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

