/**
 * Created by OLD on 21/09/2015.
 */
define('registerShowView',[
    'module',
    'backbone.marionette',
    'underscore',
    'app',
    'template!registerViewTpl'
], function (
    module,
    Marionette,
    _,
    App,
    viewTpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: viewTpl,

        triggers: {
            'click a.register-sign-in': 'register:sign:in'
        },

        ui: {
            company: 'input.register-company',
            email: 'input.register-email',
            comanyForm: '.form-company',
            form: '.js-form-submit'
        },

        events: {
            'keyup @ui.company': 'onKeyUp',
            'keyup @ui.email': 'onKeyUp',
            'focus @ui.company': 'onFocus',
            'focus @ui.email': 'onFocus'
        },

        className: 'login-form-container',

        onKeyUp: function (e) {
            var charCode = (e.which) ? e.which : event.keyCode;

            if (charCode === 13) {
                this.trigger('register:keyup');
            }
        },

        onFocus: function () {
            this.ui.form.removeClass('has-error');
            this.ui.company.removeClass('invalid');
            this.ui.email.removeClass('invalid');
            this.ui.comanyForm.find('.floating-label')
                .text(_.i18n('common.company.name'));
        },

        onError: function (requestError) {
            var company = this.ui.company.val(),
                email = this.ui.email.val();

            if (_.isEmpty(company) || requestError) {
                this.ui.form.addClass('has-error');
                this.ui.company.addClass('invalid');
                this.ui.comanyForm.find('.floating-label')
                    .text(requestError);
            }
            if (_.isEmpty(email) || requestError) {
                this.ui.form.addClass('has-error');
                this.ui.email.addClass('invalid');
            }
        }
    });
});
