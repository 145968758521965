/**
 * Created by Matteo on 14/07/2015.
 */
define('selectCaccountListController',[
    'require',
    'backbone',
    'module',
    'logger',
    'jquery',
    'underscore',
    'app',
    'settings',
    'selectCaccountListView'
], function (
    require,
    Backbone,
    module,
    Logger,
    $,
    _,
    App,
    Settings,
    ListView
) {
    'use strict';

    module.exports = {

        listCAccounts: function () {
            var CAccountService = require('services/global/caccounts');
            if (!Settings.get('currentCAccount') || !Settings.get('tokenId')) {
                var cAccountCount = CAccountService.getCAccountCount(),
                    listView;

                this.view = listView;
                cAccountCount.done(_.bind(function (count) {
                    if (count === 1) {
                        this.skipSelectionPage();
                        return;
                    }

                    listView = new ListView();

                    this.view = listView;

                    listView.listenTo(listView, 'onSignin', _.bind(this.onSignin, this));

                    App.regions.getRegion('main').show(listView);
                }, this));
            } else {
                App.trigger('index:show');
            }
        },

        onSignin: function () {
            var CAccountService = require('services/global/caccounts');
            var UserpidService = require('services/caccounts/userpids');
            var that = this,
                cAccount = this.view.selectedAccount,
                postCAccount;

            if (cAccount) {
                this.view.ui.signin.addClass('disabled');
                postCAccount = CAccountService.postCAccount(
                    cAccount.get('secId')
                );
                postCAccount.done(function (response) {
                    Settings.set('tokenId', response.tokenId);
                    Settings.set('currentCAccount', response.caccount);
                    that.view.ui.signin.removeClass('disabled');
                    UserpidService.getCurrentUser().done(function (currentUser) {
                        Settings.set('currentUser', currentUser);
                        if (!Backbone.History.started) {
                            Backbone.history.start();
                        }
                        App.trigger('index:show');
                    });
                });
            } else {
                this.view.onError();
            }
        },

        skipSelectionPage: function () {
            var CAccountService = require('services/global/caccounts');
            var fetchCAccounts = CAccountService.getCAccountEntities(),
                postCAccount;

            fetchCAccounts.done(function (cAccounts) {
                postCAccount = CAccountService.postCAccount(
                    cAccounts.at(0).get('secId')
                );

                postCAccount.done(function (response) {
                    Settings.set('tokenId', response.tokenId);
                    Settings.set('currentCAccount', cAccounts.at(0).toJSON());
                    App.trigger('index:show');
                });
            });
        }
    };
});
