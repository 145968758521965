/**
 * Created by GSP on 9/05/2016.
 */
define('iframeFullScreen',['module',
    'underscore',
    'jquery',
    'backbone.marionette'], function (module, _, $, Marionette) {
    'use strict';

    var IframeFullScreenBehavior = Marionette.Behavior.extend({

        events: {
            'click .alert-link': 'onClickAlertLink',
            'mouseover .iframe-full-screen-container': 'onMouseOverContainer',
            'mouseout .iframe-full-screen-container': 'onMouseOutContainer'
        },

        onMouseOverContainer: function (e) {
            this.showFullScreenTooltip(e);
        },

        onMouseOutContainer: function (e) {
            this.showFullScreenTooltip(e);
        },

        onClickAlertLink: function (e) {
            e.preventDefault();
            this.switchToFullScreen(e);
        },

        switchToFullScreen: function () {
            this.$('#iframeCompuzz').toggleClass('iframe--full-screen');
            this.$('.alert').toggleClass(function () {
                if ($(this).hasClass('alert--full-screen')) {
                    $(this).find('#alert-info-text').text(', ' + _.i18n('common.tooltip.fullscreen') + '.');
                } else {
                    $(this).find('#alert-info-text').text(', ' + _.i18n('common.tooltip.normalscreen') + '.');
                }
                return 'alert--full-screen';
            });
        },

        showFullScreenTooltip: function (e) {
            if (!$(e.target).hasClass('alert') && !$(e.target).parent().hasClass('alert')) {
                this.$('.alert').stop().slideToggle('slow');
            }
        }

    });

    module.exports = IframeFullScreenBehavior;
});
