
define('template!pcrKitLotForValidationView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h1>' +
((__t = ( run )) == null ? '' : __t) +
' - ' +
((__t = ( pos )) == null ? '' : __t) +
' - ' +
((__t = ( sampleId )) == null ? '' : __t) +
'</h1>\n\n<div class="d-f baseline m-b-10">\n    <label style="width: 200px; text-align: right; margin-right: 5px;">' +
((__t = ( _.i18n('well.test.lot.selectWell') )) == null ? '' : __t) +
'</label>\n    <select class="form-control js-well-selected" style="width: 200px;">\n        ';
 _.each(wells, function(well) { ;
__p += '\n        <option value="' +
((__t = ( well.secId )) == null ? '' : __t) +
'"\n        ' +
((__t = ( well.sampleId === sampleId ? 'selected': '' )) == null ? '' : __t) +
'>' +
((__t = ( well.pos )) == null ? '' : __t) +
' - ' +
((__t = ( well.sampleId )) == null ? '' : __t) +
'</option>\n        ';
 }); ;
__p += '\n    </select>\n</div>\n\n\n<div class="d-f baseline m-b-10">\n    <label style="width: 200px; text-align: right; margin-right: 5px;">' +
((__t = ( _.i18n('assay') )) == null ? '' : __t) +
'</label>\n    <input type="text" class="disabled form-control js-assay" style="width: 240px" disabled/>\n</div>\n\n<div class="d-f baseline m-b-10" style="justify-content: center">\n    <button class="btn btn-primary js-compare">' +
((__t = ( _.i18n('well.test.lot.compare') )) == null ? '' : __t) +
'</button>\n</div>\n\n\n<div class="js-kitlots-region"></div>\n\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>\n';

}
return __p
};});

