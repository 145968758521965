define('services/caccounts/prepwells',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/prepwells'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.PrepWell,
                collection: app.ns.PrepWellCollection
            };
        },
        getList: function (fieldName) {
            var defer = $.Deferred();
            var configurations = [];
            var UserCodeListService = require('services/caccounts/userCodelist');
            configurations.push({fieldName: 'tags', values: UserCodeListService.findItemsByCode('TAG_RESULT'), codeListCode: 'TAG_RESULT'});
            var configuration = _.findWhere(configurations, {fieldName: fieldName});
            if (configuration) {
                configuration.values.done(function (items) {
                    defer.resolve({items: items});
                });
            } else {
                defer.reject('No found for ' + fieldName);
            }
            return defer.promise();
        },
        getName: function () {
            return 'PrepWell';
        },
        filterWells: function (wells, from, to, step, row, column) {
            console.info('%c check "filterWells" static method in prepwells', 'color: #00f8ff');
            if (from) {
                var rowFrom = from.substr(0, 1);
                var columnFrom = parseInt(from.substr(1), 10);
            }
            if (row) {
                var rowTo = to.substr(0, 1);
                var columnTo = parseInt(to.substr(1), 10);
            }

            wells = wells.filter(_.bind(function (well) {
                var pos = well.get('pos');
                var rowCurrent = pos.substr(0, 1);
                var columnCurrent = parseInt(pos.substr(1), 10);
                if (from) {
                    if ((rowCurrent < rowFrom) ||
                        (columnCurrent < columnFrom) ||
                        ((columnCurrent - columnFrom) % step !== 0) ||
                        ((rowCurrent.charCodeAt(0) - rowFrom.charCodeAt(0)) % step !== 0)) {
                        return false;
                    }
                }
                if (to) {
                    if ((columnCurrent > columnTo) ||
                        (rowCurrent > rowTo)) {
                        return false;
                    }
                }
                if ((row && rowCurrent !== row) || (column && columnCurrent !== column)) {
                    return false;
                }

                return true;
            }, this));
            return _.sortBy(wells, function (model) {
                return model.get('pos');
            });
        },
        findBySampleId: function (sampleId) {
            var defer = $.Deferred();
            var url = Settings.url('rest/v2/wells/prep/bySampleId/' + sampleId);
            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(new app.ns.PrepWellCollection(resp));
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();

        }
    }, Dynamic);
});

