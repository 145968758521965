/**
 * Created by RKL on 05/08/2015.
 */
define('resetMainRouter',[
    'require',
    'exports',
    'module',
    'marionetteAppRouter',
    'app',
    'underscore'
], function (
    require,
    exports,
    module,
    AppRouter,
    App,
    _
) {
    'use strict';

    var ResetPwdRouter = AppRouter.extend({
        appRoutes: {
            'reset_password': 'showReset'
        }
    });

    var API = {
        showReset: function () {
            // token FP valide
            var service = require('services/global/userpids');
            var tokenFP = App.getCurrentRoute().split('?')[1].split('&')[0].split('=')[1];
            service.checkTokenFP(tokenFP).done(function () {
                require(['resetShowController'], function (ShowController) {
                    App.startSubModule('resetMain');
                    ShowController.showReset();
                });
            }).fail(function () {
                App.trigger('login:show', _.i18n('forgotPassword.token.invalid'));
            });
        }
    };

    App.on('before:start', function () {
        new ResetPwdRouter({
            controller: API
        });
    });

    module.exports = ResetPwdRouter;
});

