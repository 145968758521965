/**
 * Created by OBL on 14/01/2016.
 */
define('runPrepController',[
    'require',
    'module',
    'app',
    'underscore',
    'prepRunCreateLayoutView',
    'runController',
    'runPlateLayoutImportView',
    'runSampleListImportView',
    'runCreateFromPlateLayoutView',
    'runCreateFromSampleListView'
], function (
    require,
    module,
    App,
    _,
    CreateEditLayoutView,
    RunController,
    RunPlateLayoutImportView,
    RunSampleListImportView,
    RunCreateFromPlateLayoutView,
    RunCreateFromSampleListView
) {
    'use strict';


    module.exports = _.extend({}, RunController, {

        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.service - The service object.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         */
        showDetails: function (param) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('DEF_DEPT').done(function (defDept) {
                var createEditView = new CreateEditLayoutView({
                    newItem: true,
                    defDept: defDept,
                    runModel: param.model,
                    callBackOnClose: function (result) {
                        if (!param.callBackOnClose && result.length === 1) {
                            App.navigate('runs/prep/' + result[0].secId, {trigger: true});
                        } else if (param.callBackOnClose) {
                            param.callBackOnClose(result);
                        } else {
                            App.navigate('runs/prep', {trigger: true});
                        }
                    },
                    service: param.service
                });
                createEditView.show({
                    title: _.i18n('run.prep.new'),
                    className: 'actionPopupCss900 actionPopupCss popupFitContent',
                    service: param.service
                });
            });
        },

        importPlateLayout: function (model) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('IMPORT-PLATELAYOUT-PREP').done(_.bind(function (setting) {
                var view = new RunPlateLayoutImportView({
                    model: model,
                    settingImport: setting
                });
                view.show({
                    title: _.i18n('run.platelayout.import')
                });
            }, this));
        },
        importSampleList: function (model) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('IMPORT-SAMPLELIST-PREP').done(_.bind(function (setting) {
                var view = new RunSampleListImportView({
                    model: model,
                    settingImport: setting
                });
                view.show({
                    title: _.i18n('run.importSampleList.title')
                });
            }, this));
        },
        createFromPlateLayoutFile: function () {
            var PrepRunService = require('services/caccounts/prepruns');
            new RunCreateFromPlateLayoutView({
                entity: PrepRunService,
                callback: function () {
                    if (arguments.length === 1) {
                        App.navigate('runs/prep/' + arguments[0][0].secId, {trigger: true});
                    }
                }
            }).show({
                title: _.i18n('run.createFromPlateLayoutFile.title')
            });
        },
        createFromSampleListFile: function () {
            var PrepRunService = require('services/caccounts/prepruns');
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('LAB-SETTINGS').done(_.bind(function (setting) {
                new RunCreateFromSampleListView({
                    entity: PrepRunService,
                    settings: setting,
                    callback: function () {
                        if (arguments.length === 1) {
                            App.navigate('runs/prep/' + arguments[0][0].secId, {trigger: true});
                        }
                    }
                }).show({
                    title: _.i18n('run.createFromSampleListFile.title')
                });
            }, this));
        }
    });
});

