define('services/customers/product_templates',[
    'app',
    'underscore',
    'jquery',

    'settings',
    'module'
], function (
    App,
    _,
    $,
    Settings,
    module
) {
    'use strict';

    /**
     * REST API
     */
    module.exports = {
        countCorporateTemplates: function (endUserId, productCode) {
            var params = {
                    endUserId: endUserId,
                    productCode: productCode,
                    types: 'corporateTemplate'
                },
                url = Settings.url(
                    'compuzz',
                    'commands.json/advancedSearch/rowCount',
                    params
                ),
                defer = $.Deferred();

            $.ajax({
                url: url,
                success: function (resp) {
                    defer.resolve(resp);
                }
            });

            return defer.promise();
        }
    };
});
