define('linkPopupView',[
    'module',
    'backbone.marionette',
    'underscore',
    'jquery'
], function (
    module,
    Marionette,
    _,
    $
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<%= code %>'),

        className: 'js-click clickable openNewTab',

        events: {
            'click': 'onClick'
        },

        tagName: 'span',

        attributes: function () {
            return {
                'data-secId': this.options.secId,
                title: this.options.name
            };
        },

        serializeData: function () {
            return {code: this.options.code};
        },

        onClick: function () {
            $('.js-global-loader').show();
            require([
                'dynamicController'
            ], _.bind(function (DynamicController) {
                var model = this.options.service.getModel({secId: this.options.secId});
                var useDynamic = false;
                model.fetch()
                    .done(_.bind(function () {
                        if (this.options.service.getName() === 'sample') {
                            require(['samplesController'], _.bind(function (Controller) {
                                DynamicController.showDetails({model: model, service: this.options.service, useCreateEditView: Controller.showDetails});
                            }, this));
                        } else {
                            useDynamic = true;
                            DynamicController.showDetails({model: model, useCreateEditView: this.options.useCreateEditView});
                        }
                    }, this))
                    .always(function () {
                        if (useDynamic) {
                            $('.js-global-loader').hide();
                        }
                    });
            }, this));
        }
    });
});

