define('settings',[
    'module',
    'underscore',
    'cookies',
    'envHandler',
    'protocol',
    'browserUtils'
], function (module, _, Cookies, EnvironmentHandler, Protocol, BrowserUtils) {
    'use strict';

    var env = ' ';
    var lastcommit = 'a865af2e6fd9f18e42043a4064ffa1e58a8d87cc';
    var version = '2.4.1-SNAPSHOT.0';
    var date = 1726067618605;
    var _default = {
        urls: {
            compuzzHtml: 'compuzz/plugin/compuzzhtml/',
            compuzzPlugin: 'compuzz/plugin/index.jsf',
            compuzzOrderProcess: 'compuzz/orderprocess/',
            compuzzTranslate: 'https://api.microsofttranslator.com/v2/Http.svc/Translate',
            pdf: 'compuzz/PdfGenerator'
        },
        imageSize: {
            thumbnail: 't',
            medium: 'm',
            big: 'b'
        },
        environment: env,
        lastcommit: lastcommit,
        version: version,
        date: date,
        tokenId: '',
        perPage: 17,
        perPage15: 15,
        pageNumber: 1,
        application: 'dashboard',
        lang: BrowserUtils.getLanguage(),
        defaultLogo: EnvironmentHandler.getValue(env, 'defaultLogo'),
        obsolete: EnvironmentHandler.getValue(env, 'obsolete'),
        folder: EnvironmentHandler.getValue(env, 'folder'),
        accountsPageSize: 15,
        customersPageSize: 15,
        lazyLoadingSize: 10,
        minBalance: 20,
        supportEmail: 'support.compuzz.com',
        googleClientId: '853426927905-94mqbg6o7m3ph0u8v1nhphbea7106jmr.apps.googleusercontent.com',
        translateClientApiId: '29ABC175-452C-4e6a-B625-AFDC9C314B39'
    };

    var Settings = {
        isDebugMode: true,
        tplEngine: 'html',
        storeInCookies: [
            'tokenId', 'lastUrlPageCookie', 'lang', 'userFileExtension', 'loginTo'
        ],
        storeInMemory: {},
        numbers: ['perPage', 'pageNumber'],
        serverUrl: Protocol.apply(EnvironmentHandler.getValue(env,
            'serverUrl')),
        domaine: '.com/',

        pluginUrl: function () {
            return this.serverUrl + 'compuzz/plugin/index.jsf';
        },

        set: function (name, value) {
            if (!name) {
                return;
            }
            if (name === 'sessionId' && !value) {
                return;
            } else if (_.contains(this.storeInCookies, name)) {
                if (!_.isEmpty(value) || value) {
                    value = value.toString();
                    var size = new Blob([value]).size;
                    if (size > 4096) {
                        alert('Cookie "' + name + '" is too big (' + size + ' bytes). Max size is 4096 bytes.');
                        new Error('Cookie "' + name + '" is too big (' + size + ' bytes). Max size is 4096 bytes.');
                    }
                    Cookies.set(name, value, {expires: 30, path: '/'});
                    this[name] = value;
                } else {
                    Cookies.remove(name);
                    delete this[name];
                }
            } else {
                this[name] = value;
            }
        },

        get: function (name) {
            var option;
            if (_.contains(this.storeInCookies, name)) {
                option = Cookies.get(name);
                if (option === 'false') {
                    option = false;
                }
            }
            option = option || _default[name];
            if (_.contains(this.numbers, name)) {
                option = parseInt(option, 10);
            }
            if (name === 'path' && option) {
                option = option.split(',');
            }
            if (this[name]) {
                option = this[name];
            }
            return option;
        },

        url: function (relative, params, tokenNotRequired, useServerUrl) {
            if (useServerUrl === undefined || useServerUrl === null) {
                useServerUrl = true;
            }
            var baseUrl = '',
                baseServerUrl = '',
                indexOfServerUrl = -1,
                stringParams = '',
                ampersand = '';

            if (relative) {
                indexOfServerUrl = relative.indexOf('http://localhost:8080/');
                if (indexOfServerUrl >= 0) {
                    baseServerUrl = 'http://localhost:8080/';
                } else {
                    indexOfServerUrl = relative.indexOf(this.serverUrl || '');
                    if (indexOfServerUrl >= 0) {
                        baseServerUrl = this.serverUrl;
                    } else {
                        indexOfServerUrl = relative.indexOf(this.domaine);

                        var hasArobase = relative.indexOf('@');
                        if (indexOfServerUrl >= 0 && (hasArobase < 0 || hasArobase > indexOfServerUrl)) {
                            baseServerUrl = relative.substring(0, indexOfServerUrl + this.domaine.length);
                        }
                    }
                }
                if (indexOfServerUrl >= 0) {
                    relative = relative.replace(baseServerUrl, '');
                }

            }
            params = params || {};

            if (params.size) {
                params.action = 'res';
                params.params = _default.imageSize[params.size];
                delete params.size;
            }

            if (relative) {
                if (relative.indexOf('?') === -1) {
                    stringParams = '?';
                } else {
                    stringParams = '&';
                }
            } else {
                stringParams = '?';
            }

            if (!tokenNotRequired) {
                if (this.lang) {
                    params.lang = this.lang;
                } else {
                    params.lang = this.get('lang');
                }
            }

            _.each(params, function (value, key) {
                if (value !== undefined && value !== null && key) {
                    if (key === 'poi' && typeof value === 'object') {
                        _.each(value, function (value) {
                            stringParams += (ampersand + key + '=' + value.toString());
                            ampersand = '&';
                        });
                    } else {
                        stringParams += (ampersand + key + '=' + value.toString());
                        ampersand = '&';
                    }
                }
            });

            if (baseUrl.indexOf('getfile') < 0 && !tokenNotRequired) {
                stringParams += ampersand + 'tokenId' + '=' + this.get('tokenId');
            }

            return (useServerUrl === true ? Protocol.apply(this.serverUrl) : '') + baseUrl + relative + stringParams;
        },

        generateParamUrl: function (params) {
            var stringParams = '',
                ampersand = '';
            _.each(params, function (value, key) {
                if (value !== undefined && value !== null && key) {
                    if (key === 'poi' && typeof value === 'object') {
                        _.each(value, function (value) {
                            stringParams += (ampersand + key + '=' + value.toString());
                            ampersand = '&';
                        });
                    } else {
                        stringParams += (ampersand + key + '=' + value.toString());
                        ampersand = '&';
                    }
                }
            });
            return stringParams;
        },

        setToMemory: function (key, value) {
            value = JSON.stringify(value);

            if (key) {
                if (_.isEmpty(value) || !value) {
                    delete Settings.storeInMemory[key];
                } else {
                    Settings.storeInMemory[key] = value;
                }
            }
        },

        clearMemory: function () {
            Settings.storeInMemory = {};
        },

        getFromMemory: function (key) {
            var value = Settings.storeInMemory[key];
            if (value) {
                value = JSON.parse(value);
            }

            return value;
        },
        getDocUrl: function (docObject) {
            var url = this.url('rest/public/document/getfileByName/TB_' + docObject) + '#toolbar=0';
            return 'window.open(\'' + url + '\', \'documentation\', \'width=\' + window.outerWidth * 0.6 + \',height=\' + window.outerWidth * 0.7)';
        }
    };

    module.exports = Settings;
});

