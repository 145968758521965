
define('template!assayReagentMMXItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-1 p-r-2 p-l-2"><span class="mdi mdi-delete delete-row js-icon clickable" style="line-height: 26px;"></span></div>\n<div class="col-xs-4 p-r-4 p-l-2 js-kit"></div>\n<div class="col-xs-2 p-r-2 p-l-2 js-reagent"></div>\n<div class="col-xs-2 p-r-2 p-l-2">\n	<input autocomplete="off"\n		   class="form-control js-info-input-mmx inputBackground t-a-r p-r-5 volumeByTest"\n		   data-field-name="volumeByTest"\n		   id="volumeByTest" placeholder=""\n		   type="number" step="0.01" min="0"\n		   value="' +
((__t = ( volumeByTest )) == null ? '' : __t) +
'">\n</div>\n<div class="col-xs-2 p-r-2 p-l-2">\n	<input autocomplete="off"\n		   class="form-control js-info-input-mmx inputBackground t-a-r p-r-5 reagent"\n		   data-field-name="remark"\n		   id="remark" placeholder=""\n		   type="text"\n		   value="' +
((__t = ( remark )) == null ? '' : __t) +
'">\n</div>';

}
return __p
};});

