
define('template!runPcrKitsLotsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row col-xs-12 p-l-30">\n    <div class="col-xs-12 p-l-0 p-r-0 p-b-1" style="height: 21px;">\n        <div class="col-xs-2 p-l-5 p-r-0 noWrapHidden">\n            <label>\n                ' +
((__t = ( _.i18n('reagent.assay.name') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="col-xs-2 p-l-5 p-r-0 noWrapHidden">\n            <label>\n                ' +
((__t = ( _.i18n('pcrReagents.type') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="col-xs-2 p-l-5 p-r-0 noWrapHidden">\n            <label>\n                ' +
((__t = ( _.i18n('common.refKit') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="col-xs-2 p-l-5 p-r-0 noWrapHidden">\n            <label>\n                ' +
((__t = ( _.i18n('kitLot.lotId') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="col-xs-1 p-l-5 p-r-0 noWrapHidden">\n            <label>\n                ' +
((__t = ( _.i18n('needed.wells.number') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="col-xs-1 p-l-5 p-r-0 noWrapHidden">\n            <label>\n                ' +
((__t = ( _.i18n('assay.volume.test') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="col-xs-1 p-l-5 p-r-0 noWrapHidden">\n            <label>\n                ' +
((__t = ( _.i18n('assay.volume.death') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="col-xs-1 p-l-5 p-r-0 noWrapHidden">\n            <label>\n                ' +
((__t = ( _.i18n('assay.volume.tot') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n    </div>\n\n    ';
 if(kitLots.length > 0) { ;
__p += '\n    ';
 _.each(kitLots, function(kitLot) { ;
__p += '\n    ';
 _.each(kitLot.assayReagents, function(assayReagent) { ;
__p += '\n    ';
 _.each(assayReagent.pcrKitLots, function(pcrKitLot) { ;
__p += '\n    <div class="col-xs-12 p-l-0 p-r-0" style="height: 23px;">\n        <div class="col-xs-2"\n             style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;height: inherit;">\n            ' +
((__t = ( kitLot.refAssay.code )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-2"\n             style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; }height: inherit;">\n            ' +
((__t = ( assayReagent.reagent.code )) == null ? '' : __t) +
' (' +
((__t = ( assayReagent.reagent.type )) == null ? '' : __t) +
')\n        </div>\n        <div class="col-xs-2"\n             style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n            ' +
((__t = ( assayReagent.kit.code )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-2"\n             style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n            ' +
((__t = ( pcrKitLot.refKitLot && pcrKitLot.refKitLot.code ? pcrKitLot.refKitLot.code : '' )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1"\n             style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n            ' +
((__t = ( pcrKitLot.count + ' (' + pcrKitLot.countInPrepWell + ')' )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1"\n             style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n            ' +
((__t = ( pcrKitLot.volumeByWell )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1"\n             style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n            ' +
((__t = ( pcrKitLot.volumeDeath )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1"\n             style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px; height: inherit;">\n            ' +
((__t = ( (pcrKitLot.count - pcrKitLot.countInPrepWell) != 0 ? ((pcrKitLot.count -
            pcrKitLot.countInPrepWell) * pcrKitLot.volumeByWell) + pcrKitLot.volumeDeath : 0 )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    ';
 }) ;
__p += '\n    ';
 }) ;
__p += '\n    ';
 }) ;
__p += '\n    ';
 } else { ;
__p += '\n    <div class="col-xs-12 p-l-0 p-r-0">\n        <div class="col-xs-12 noWrapHidden" style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px;"\n             title="">\n            ' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

