/**
 * Created by Matteo on 19/07/2015.
 */
define('unauthorizedShowController',[
  'module',
  'app',
  'unauthorizedShowView'
], function(
  module,
  App,
  View
) {
  'use strict';

  module.exports = {
      showUnauthorized: function (message) {
          var view = new View({message: message});

          App.regions.getRegion('main').show(view);
      }
  };
});

