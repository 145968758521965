define('wellSmpTypeView',[
        'module',
        'backbone.marionette',
        'backbone',
        'dialogFormView',
        'underscore'
    ], function (
        module,
        Marionette,
        Backbone,
        DialogFormView,
        _
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: _.template(''),

            tagName: 'span',
            className: function () {
                var mdi = this._findBySmpType(this.options.smpType ? this.options.smpType.toUpperCase() : 'E');
                return 'mdi ' + mdi + ' ' + (!!this.options.pcrSetup ? 'pcrSetupWellIcon' : '');
            },

            attributes: function () {
                var smpType = this.options.smpType ? this.options.smpType.toUpperCase() : 'E';
                var style = 'font-size: 17px; line-height: 17px;';
                if (this.options.size === 'big') {
                    style = 'font-size: 30px; line-height: 23px;';
                }
                return {
                    title: _.i18n('well.smpType.' + smpType),
                    style: style
                };
            },

            serializeData: function () {
                return {};
            },
            _findBySmpType: function (smpType) {
                switch (smpType) {
                    case 'U':
                        return 'mdi-checkbox-blank-circle';
                    case 'D':
                        return 'mdi-alpha-d-circle';
                    case 'C':
                        return 'mdi-alpha-c-circle';
                    case 'E':
                        return 'mdi-checkbox-blank-circle-outline';
                    case 'P':
                        return 'mdi-checkbox-blank-circle';
                    case 'PC':
                        return 'mdi-plus-circle';
                    case 'NC':
                        return 'mdi-minus-circle';
                    case 'RC':
                        return 'mdi-alpha-r-circle';
                    case 'OC':
                        return 'mdi-compass';
                }
            }
        });
    }
);

