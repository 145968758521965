
define('template!runPrepContentWellListView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<table id="jq-grid-list" class="wellTable" role="grid"></table>\n';

}
return __p
};});

