define('prepWellDetailsView',[
    'module',
    'template!prepWellDetailsView',
    'underscore',
    'jquery',
    'dialogFormView',
    'savingBehavior',
    'app',
    'sampleCommentView',
    'prepRunTracsView',
    'wellUtils',
    'wellDisplaySampleView',
    'dynamicCreateEditCustomListToList',
    'wellController',
    'entities/menuGroup',
    'backbone',
    'menuGroup',
    'prepWellContentView',
    'prepRunDisplayKitsLotsView',
    'settings',
    'rolesMixin',
    'privileges'
], function (
    module,
    Tpl,
    _,
    $,
    DialogFormView,
    SavingBehavior,
    App,
    EditSampleCommentView,
    PrepRunTracsView,
    WellUtils,
    WellDisplaySampleView,
    DynamicCreateEditCustomListToList,
    WellController,
    MenuGroup,
    Backbone,
    MenuGroupView,
    ContentView,
    PrepRunDisplayKitsLotsView,
    Settings,
    RolesMixin,
    Privileges
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        ui: {
            btnEditWellResult: '.btn-edit-well-result',
            input: '.js-info-input',
            btnNextWell: '.btn-next-well',
            btnPreviousWell: '.btn-previous-well',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            showKitsLots: '.js-show-kitsLots',
            copyParameters: '.js-copyParameters'
        },

        events: {
            'click .js-show-kitsLots': 'onShowKitsLots',
            'click @ui.btnEditWellResult': 'onEditWellResult',
            'click @ui.btnNextWell': 'onNextWell',
            'click @ui.btnPreviousWell': 'onPreviousWell',
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click .js-edit-sampleComment': 'onEditSampleComment',
            'click .js-show-pcrWell-from-prepWell': 'onShowPcrWellFromPrepWell',
            'click .js-show-prepWell': 'onShowPrepWell'
        },

        modelEvents: {
            'change': 'render',
            'change:tags': 'saveTags',
            'change:smpId': 'render'
        },

        regions: {
            wellDisplaySample: '.js-well-sample',
            tags: '.js-tags',
            tracingMenu: '.js-tracing-menu',
            testMenu: '.js-test-menu',
            contentRegion: '.js-content-region'
        },

        onShowTracsView: function () {
            this.pcrRunTracsView = new PrepRunTracsView({
                model: this.model,
                type: 'wellTracks'
            });
            this.pcrRunTracsView.show({
                title: _.i18n('tracs'),
                className: 'runShowTracsPopup'
            });
        },

        onShowPcrWellFromPrepWell: function (e) {
            var $target = $(e.currentTarget);
            var datSecId = $target.attr('data-secId');

            if (this.pcrWellsFromPrep) {
                var pcrWellModel = _.find(this.pcrWellsFromPrep, _.bind(function (obj) {
                    return obj.get('secId') === datSecId;
                }, this));

                if (pcrWellModel) {

                    var PcrRunService = require('services/caccounts/pcrruns');
                    var pcrRunModel = PcrRunService.getModel({secId: pcrWellModel.get('run').get('secId')});
                    pcrRunModel.fetch().done(_.bind(function () {
                        pcrWellModel.set('run', pcrRunModel);
                        require(['pcrWellController'], _.bind(function (PcrWellController) {
                            PcrWellController.showDetails({model: pcrWellModel});
                        }, this));
                    }, this));
                }
            }
        },

        onShowPrepWell: function (e) {
            var $target = $(e.currentTarget);
            var datSecId = $target.attr('data-secId');
            var PrepWellService = require('services/caccounts/prepwells');
            var prepWell = PrepWellService.getModel({secId: datSecId});
            require(['prepWellController'], _.bind(function (PrepWellController) {
                PrepWellController.showDetails({model: prepWell});
            }, this));
        },

        onEditSampleComment: function () {
            this.model.get('smpId').fetch().done(_.bind(function () {
                this.editSampleCommentView = new EditSampleCommentView({
                    wellModel: this.model,
                    model: this.model.get('smpId')
                });
                this.editSampleCommentView.show({
                    title: _.i18n('edit.sampleComment'),
                    className: ''
                });
            }, this));
        },

        onEditWellResult: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showDetails({model: this.model});
            }, this));
        },

        initialize: function (options) {
            this.cSelection = options.cSelection;
            this.pcrWellsFromPrep = this.options.pcrWellsFromPrep;
        },

        onPreviousWell: function () {
            var index = _.indexOf(this.cSelection, this.model);
            if (index > 0) {
                this._changeModel(this.cSelection[index - 1]);
            } else {
                this._changeModel(this.cSelection[this.cSelection.length - 1]);
            }
        },

        onNextWell: function () {
            var index = _.indexOf(this.cSelection, this.model);
            if (index < this.cSelection.length - 1) {
                this._changeModel(this.cSelection[index + 1]);
            } else {
                this._changeModel(this.cSelection[0]);
            }
        },

        _changeModel: function (model) {
            this.trigger('wellDetail:onModelChange', model);
        },

        serializeData: function () {
            var templateData = {
                pos: this.model.get('pos'),
                smpType: this.model.get('smpType'),
                pooled: this.model.get('pooled'),
                smpId: this.model.get('smpId') ? this.model.get('smpId').get('code') : '',
                repeatStatus: this.model.get('repeatStatus'),
                dilution: this.model.get('dilution'),
                wellsPooled: this.model.get('wellsPooled').map(function (well) {
                    return {
                        runName: well.get('run').get('name'),
                        pos: well.get('pos'),
                        smpId: well.get('smpId') ? well.get('smpId').get('code') : ''
                    };
                }),
                wellsPool: this.model.get('wellsPool').map(function (well) {
                    return {
                        runName: well.get('run').get('name'),
                        pos: well.get('pos'),
                        smpId: well.get('smpId') ? well.get('smpId').get('code') : ''
                    };
                })
            };

            templateData.otherWell = null;
            if (_.any(this.model.get('otherPrepWells'), function (well) {
                return well.repeatStatus;
            })) {
                templateData.otherWell = 'repeat';
            }
            if (templateData.otherWell === null && _.any(this.model.get('otherPrepWells'), function (well) {
                return !well.repeatStatus;
            })) {
                templateData.otherWell = 'other';
            }

            templateData.refLmbExtractor = this.model.get('run').get('extractionDetails').get('refLmbExtractor') && this.model.get('run').get('extractionDetails').get('refLmbExtractor').get('code') ?
                this.model.get('run').get('extractionDetails').get('refLmbExtractor').get('code') : '-';

            templateData.refExtractionMethod = this.model.get('run').get('extractionDetails').get('refExtractionMethod') && this.model.get('run').get('extractionDetails').get('refExtractionMethod').get('code') ?
                this.model.get('run').get('extractionDetails').get('refExtractionMethod').get('code') : '-';

            var pcrWellsFromPrepJson = [];
            _.each(this.pcrWellsFromPrep, _.bind(function (well) {
                var wellRes = well.getWellRes();
                var wellResults = well.getTargetsResultIcons(false);
                var wellJson = {
                    runName: well.get('run').get('name'),
                    pos: well.get('pos'),
                    secId: well.get('secId'),
                    assay: well.get('refAssay') ? well.get('refAssay').get('code') : '-'
                };
                wellJson.wellResNumFormated = wellRes && wellRes.get('quantificationFormatted') ? wellRes.get('quantificationFormatted') : '';
                wellJson.wellResColor = this.getWellResColor(wellRes);
                wellJson.results = wellResults;
                if (wellRes) {
                    wellJson.wellRes = {
                        targetCode: wellRes.get('targetCode'),
                        cts: wellRes.get('cts'),
                        resCode: wellRes.get('resCode'),
                        iconHtml: wellRes.getResultIcon(true).iconHtml
                    };
                }
                pcrWellsFromPrepJson.push(wellJson);
            }, this));

            templateData.pcrWellsFromPrep = pcrWellsFromPrepJson;

            templateData.concatComment = (this.model.get('smpId').get('lisComment') ? this.model.get('smpId').get('lisComment') : '') + ' - ' + (this.model.get('smpId').get('comment') ? this.model.get('smpId').get('comment') : '');
            if (templateData.concatComment === ' - ') {
                templateData.concatComment = '';
            }

            templateData.assaysToDisplay = WellUtils.getFormattedAssayStatus(this.model, 'wellEditPopup');
            templateData.displayPool = !this.model.get('wellsPool').isEmpty();
            templateData.displayPooled = !this.model.get('wellsPooled').isEmpty();
            return templateData;
        },

        getWellResColor: function (wellRes) {
            var wellResColor;
            if (wellRes && wellRes.get('result')) {
                wellResColor = wellRes.get('result').get('color');
            } else {
                wellResColor = 'transparent';
            }
            return wellResColor;
        },

        renderMenu: function () {
            this.tracingMenu = new MenuGroup({
                nameCode: 'common.tracing',
                type: 'wellPopup',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.tracing',
                        click: _.bind(this.onShowTracsView, this)
                    }]),
                quickActions: new Backbone.Collection([{
                    nameCode: 'common.tracing',
                    click: _.bind(this.onShowTracsView, this),
                    mdi: 'mdi-gnome'
                }])
            });
            this.testMenu = new MenuGroup({
                nameCode: 'common.test',
                type: 'wellPopup',
                disabled: !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PREP, Privileges.SET_TEST)),
                items: new Backbone.Collection([{
                    nameCode: 'well.test.lot',
                    click: _.bind(this.onShowTestLotView, this)
                }, {
                    nameCode: 'well.test.other',
                    click: _.bind(this.onShowTestOtherView, this)
                }, {
                    nameCode: 'well.test.assay',
                    click: _.bind(this.onShowTestAssayView, this)
                }, {
                    nameCode: 'well.untest',
                    click: _.bind(this.onShowUntestView, this)
                }]),
                quickActions: new Backbone.Collection([{
                    items: new Backbone.Collection([{
                        nameCode: 'well.test.lot',
                        click: _.bind(this.onShowTestLotView, this),
                        custom: {
                            code: 'testLot',
                            className: 'test'
                        },
                        selected: this.model.get('test') === 'LOT'
                    }, {
                        nameCode: 'well.test.other',
                        click: _.bind(this.onShowTestOtherView, this),
                        custom: {
                            code: 'testOther',
                            className: 'test'
                        },
                        selected: this.model.get('test') === 'OTHER'
                    }, {
                        nameCode: 'well.test.assay',
                        click: _.bind(this.onShowTestAssayView, this),
                        custom: {
                            code: 'testAssay',
                            className: 'test'
                        },
                        selected: this.model.get('test') === 'ASSAY'
                    }, {
                        nameCode: 'well.untest',
                        click: _.bind(this.onShowUntestView, this),
                        mdi: 'mdi-alpha-x',
                        className: 'untest',
                        selected: !this.model.get('test')
                    }])
                }])
            });
        },

        onRender: function () {
            this.renderMenu();
            this.showChildView('tracingMenu', new MenuGroupView({
                model: this.tracingMenu
            }));
            this.showChildView('testMenu', new MenuGroupView({
                model: this.testMenu
            }));

            this.allowActionButtons();

            this.$el.find('[data-toggle="tooltip"]').tooltip();

            var allowPreviousBtn = true;
            var allowNextBtn = true;
            if (this.cSelection && this.cSelection.length > 0) {
                _.each(this.cSelection, _.bind(function (wellPos, i) {
                    if (wellPos === this.model.get('pos')) {
                        if (i === 0) {
                            allowPreviousBtn = false;
                        }
                        if (i === this.cSelection.length - 1) {
                            allowNextBtn = false;
                        }
                    }
                }, this));
            } else {
                this.model.get('run').get('wells').each(_.bind(function (well, i) {
                    if (well.get('pos') === this.model.get('pos')) {
                        if (i === 0) {
                            allowPreviousBtn = false;
                        }
                        if (i === this.model.get('run').get('wells').length - 1) {
                            allowNextBtn = false;
                        }
                    }
                }, this));
            }
            if (!allowPreviousBtn) {
                var previousBtn = this.$el.find('.css-previous-well');
                previousBtn.removeClass('btn-previous-well');
                previousBtn.removeClass('clickable');
                previousBtn.css('opacity', 0.2);
            }
            if (!allowNextBtn) {
                var nextBtn = this.$el.find('.css-next-well');
                nextBtn.removeClass('btn-next-well');
                nextBtn.removeClass('clickable');
                nextBtn.css('opacity', 0.2);
            }

            var view = new WellDisplaySampleView({
                model: this.model
            });

            this.getRegion('wellDisplaySample').show(view);
            App.trigger('forClipboard', this.ui.copyParameters);
            var PrepWellService = require('services/caccounts/prepwells');
            this.getRegion('tags').show(new DynamicCreateEditCustomListToList({
                'field': 'tags',
                'model': this.model,
                service: PrepWellService
            }));

            if (this.model.get('run').get('extractionDetails').get('refExtractionMethod') && this.model.get('test')) {
                var elements = this.$el.find('.js-prepWell-validation-view');
                elements.removeClass('clickable');
                elements.addClass('disabled');
                elements.css('cursor', 'default');
            }

            this.showChildView('contentRegion', new ContentView({
                model: this.model
            }));
        },

        allowActionButtons: function () {
            var items = [];
            _.each(items, function (item) {
                item.trigger('enable', false);
            });
        },

        onCancel: function () {
            this.hide();
        },

        hide: function () {
            this.triggerMethod('wellDetail:destroy');
            this.triggerMethod('hide');
        },

        onShowKitsLots: function () {
            var kitLotView = new PrepRunDisplayKitsLotsView({
                model: this.model
            });

            kitLotView.show({
                title: _.i18n('prep.run') + '&nbsp;&nbsp;&nbsp;' + this.model.runName + '&nbsp;&nbsp;&nbsp;' + _.i18n('prep.run.well.kitlot'),
                className: 'editPrepRunWellComment'
            });
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.triggerMethod('hide:label');
            }
            console.error(response);
        },
        saveTags: function () {
            $('.js-global-loader').show();
            this.model.saveDetail().always(function () {
                $('.js-global-loader').hide();
            });
        },
        onShowTestLotView: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showPcrKitLotForValidation({model: this.model});
            }, this));
        },
        onShowTestOtherView: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showDetails({model: this.model, test: 'OTHER'});
            }, this));
        },
        onShowTestAssayView: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showDetails({model: this.model, test: 'ASSAY'});
            }, this));
        },
        onShowUntestView: function () {
            this.model.setTest(null);
        }
    });
});

