define('wellUtils',[
        'module',
        'backbone.marionette',
        'colorUtils',
        'fieldUtils',
        'underscore',
        'prepWellToolTipView',
        'jquery',
        'pcrWellController'
    ], function (
        module,
        Marionette,
        ColorUtils,
        FieldUtils,
        _,
        PrepWellToolTipView,
        $,
        PcrWellController
    ) {
        'use strict';

        module.exports = {
            /**
             *  PrepWell ***************************************************************************************************
             */

            getPrepWellAssaysIcons: function (waitingIsEmpty, runningIsEmpty, doneIsEmpty) {
                return {
                    waiting: this.getAssayWaitingIcon({
                        color: waitingIsEmpty ? '#9d9d9d' : '#ff0000',
                        'font-size': '16px',
                        'line-height': '16px',
                        position: 'relative'
                    }),
                    running: this.getAssayRunningIcon({
                        color: runningIsEmpty ? '#9d9d9d' : '#4397ff',
                        'font-size': '16px',
                        'line-height': '16px',
                        position: 'relative'
                    }),
                    done: this.getAssayDoneIcon({
                        color: doneIsEmpty ? '#9d9d9d' : '#49af49',
                        'font-size': '16px',
                        'line-height': '16px',
                        position: 'relative'
                    })
                };
            },

            getPrepWellEditPopupAssaysIcons: function () {
                return {
                    waiting: this.getAssayWaitingIcon({
                        color: '#ff0000',
                        'font-size': '16px',
                        'line-height': '16px',
                        position: 'relative'
                    }),
                    running: this.getAssayRunningIcon({
                        color: '#4397ff',
                        'font-size': '16px',
                        'line-height': '16px',
                        position: 'relative'
                    }),
                    done: this.getAssayDoneIcon({
                        color: '#49af49',
                        'font-size': '16px',
                        'line-height': '16px',
                        position: 'relative'
                    })
                };
            },

            getPrepWellTooltipAssaysIcons: function () {
                return {
                    waiting: this.getAssayWaitingIcon({color: '#ff0000', 'font-size': '16px', 'line-height': '16px', position: 'relative'}),
                    running: this.getAssayRunningIcon({color: '#4397ff', 'font-size': '16px', 'line-height': '16px', position: 'relative'}),
                    done: this.getAssayDoneIcon({color: '#49af49', 'font-size': '16px', 'line-height': '16px', position: 'relative'})
                };
            },

            getPrepWellAssaysFilterIcons: function () {
                return { // sans relative
                    waiting: this.getAssayWaitingIcon({color: '#ff0000', 'font-size': '24px', 'line-height': '24px', bottom: '2px', left: '4px'}),
                    running: this.getAssayRunningIcon({color: '#4397ff', 'font-size': '24px', 'line-height': '24px', bottom: '2px', left: '4px'}),
                    done: this.getAssayDoneIcon({color: '#49af49', 'font-size': '24px', 'line-height': '24px', bottom: '2px', left: '4px'})
                };
            },

            getPrepWellAssaysListViewIcons: function () {
                return {
                    waiting: this.getAssayWaitingIcon({
                        color: '#ff0000', 'font-size': '24px', bottom: '2px', left: '4px', position: 'relative'
                    }),
                    running: this.getAssayRunningIcon({
                        color: '#4397ff', 'font-size': '24px', bottom: '2px', left: '4px', position: 'relative'
                    }),
                    done: this.getAssayDoneIcon({
                        color: '#49af49',
                        'font-size': '24px',
                        bottom: '2px',
                        left: '4px',
                        position: 'relative'
                    })
                };
            },

            getAssayWaitingIcon: function (style) {
                var cssString = 'text-shadow: #fff 0px 0 9px;';
                _.each(style, function (value, key) {
                    cssString += key + ':' + value + ';';
                });
                return '<span class="mdi mdi-human-male" style="' + cssString + '"></span>';
            },

            getAssayRunningIcon: function (style) {
                var cssString = 'text-shadow: #fff 0px 0 9px;';
                _.each(style, function (value, key) {
                    cssString += key + ':' + value + ';';
                });
                return '<span class="mdi mdi-run" style="' + cssString + '"></span>';
            },

            getAssayDoneIcon: function (style) {
                var cssString = 'text-shadow: #fff 0px 0 9px;';
                _.each(style, function (value, key) {
                    cssString += key + ':' + value + ';';
                });
                return '<span class="mdi mdi-human-handsup" style="' + cssString + '"></span>';
            },

            showPrepWellPopover: function (params) {
                var well;
                if (params.model) {
                    well = params.model;
                } else {
                    well = params.context.model;
                }
                if (params.model && params.model.get && params.model.get('pos')) {
                    well = params.model;
                }

                var wellsSize = well.get('run') ? well.get('run').get('wells').size() : 96;
                var placement = params.placement ? params.placement : this.getPlacement(well.get('pos'), wellsSize);

                if (well.get('smpId').get('name') || well.get('smpType')) {
                    var view = new PrepWellToolTipView({
                        model: well,
                        assaysToDisplay: this.getFormattedAssayStatus(well, 'popover')
                    });

                    view.render();
                    var content = view.$el.html();

                    if (params.targets) {
                        _.each(params.targets, _.bind(function (target) {
                            target.attr('data-content', content);
                            // Mettre à jour le contenu du popover sans le détruire
                            if (target.data('bs.popover')) {
                                target.data('bs.popover').options.content = content;
                            } else {
                                target.popover({
                                    trigger: 'hover',
                                    html: 'true',
                                    placement: 'right',
                                    container: 'body',
                                    content: content,
                                    sanitize: false
                                }).data('bs.popover').tip().addClass('css-prep-well-popover');
                            }
                        }, this));
                    }
                    if (params.target) {
                        params.target.attr('data-content', content);
                        // Mettre à jour le contenu du popover sans le détruire
                        if (params.target.data('bs.popover')) {
                            params.target.data('bs.popover').options.content = content;
                        } else {
                            params.target.popover({
                                trigger: 'hover',
                                html: 'true',
                                placement: placement,
                                container: 'body',
                                content: content,
                                sanitize: false
                            }).data('bs.popover').tip().addClass('css-prep-well-popover');
                        }
                    }
                }
            },

            _poolsToJson: function (pools) {
                return pools.map(function (wellPool) {
                    return {
                        runName: wellPool.get('run').get('name'),
                        pos: wellPool.get('pos'),
                        smpId: wellPool.get('smpId') ? wellPool.get('smpId').get('name') : ''
                    };
                });
            },

            /**
             *  PcrWell ****************************************************************************************************
             */

            getPcrWellSmpTypeIcon: function (sampleType, pcrSetup) {
                var retVal = '';
                var title = _.i18n('well.smpType.' + (sampleType ? sampleType.toLowerCase() : 'e'));
                switch (sampleType) {
                    case 'NC': // mdi-content-remove-circle
                        retVal = '<span class="mdi mdi-minus-circle ' + (pcrSetup ? 'pcrSetupWellIcon' : '') + '" title="' + title + '"></span>';
                        break;
                    case 'PC': // mdi-content-add-circle
                        retVal = '<span class="mdi mdi-plus-circle ' + (pcrSetup ? 'pcrSetupWellIcon' : '') + '" title="' + title + '"></span>';
                        break;
                    case 'RC':
                        retVal = '<span class="mdi mdi-alpha-r-circle ' + (pcrSetup ? 'pcrSetupWellIcon' : '') + '" title="' + title + '"</span>';
                        break;
                    case 'OC':
                        retVal = '<span class="mdi mdi-compass ' + (pcrSetup ? 'pcrSetupWellIcon' : '') + '" title="' + title + '"></span>';
                        break;
                    case 'D':
                        retVal = '<span class="mdi mdi-alpha-d-circle ' + (pcrSetup ? 'pcrSetupWellIcon' : '') + '" title="' + title + '"></span>';
                        break;
                    case 'C':
                        retVal = '<span class="mdi mdi-alpha-c-circle ' + (pcrSetup ? 'pcrSetupWellIcon' : '') + '" title="' + title + '"></span>';
                        break;
                    case 'U':
                        retVal = '<span class="mdi mdi-checkbox-blank-circle ' + (pcrSetup ? 'pcrSetupWellIcon' : '') + '" title="' + title + '"></span>';
                        break;
                    case 'E':
                        retVal = '<span class="mdi mdi-checkbox-blank-circle-outline ' + (pcrSetup ? 'pcrSetupWellIcon' : '') + '" title="' + title + '"></span>';
                        break;
                    default :
                        retVal = '<span class="mdi mdi-checkbox-blank-circle ' + (pcrSetup ? 'pcrSetupWellIcon' : '') + '" title="' + title + '"></span>';
                        break;
                }
                return retVal;
            },

            showPcrWellPopover: function (params, placement) {
                var well = params.model;

                if (!well.get('smpId').get('name') && well.get('refPcrWell') && well.get('refPcrWell').get('smpId').get('name')) {
                    well = well.get('refPcrWell');
                }

                if ((well.get('smpId').get('name') || well.get('smpType')) && well.get('refAssay')) {

                    var isPooled = !well.get('wellsPooled').isEmpty();
                    var isPool = !well.get('wellsPool').isEmpty();
                    var wellsPooled = [];
                    if (isPooled) {
                        wellsPooled = this._poolsToJson(well.get('wellsPooled'));
                    }

                    var wellsPool = [];
                    if (isPool) {
                        wellsPool = this._poolsToJson(well.get('wellsPool'));
                    }
                    var content = PcrWellController.generateToolTip({
                        model: well,
                        isPool: isPool,
                        isPooled: isPooled,
                        wellsPooled: wellsPooled,
                        wellsPool: wellsPool
                    });
                    if (!placement) {
                        var wellsSize = well.get('run').get('wells').length;
                        placement = params.placement ? params.placement : this.getPlacement(well.get('pos'), wellsSize);
                    }

                    if (params.targets) {
                        _.each(params.targets, _.bind(function (target) {
                            target.attr('data-content', content);
                            // Mettre à jour le contenu du popover sans le détruire
                            if (target.data('bs.popover')) {
                                target.data('bs.popover').options.content = content;
                            } else {
                                target.popover({
                                    trigger: 'hover',
                                    html: 'true',
                                    placement: placement,
                                    container: 'body',
                                    content: content,
                                    sanitize: false
                                }).data('bs.popover').tip().addClass('css-pcr-well-popover');
                            }
                        }, this));
                    }
                    if (params.target) {
                        params.target.attr('data-content', content);
                        // Mettre à jour le contenu du popover sans le détruire
                        if (params.target.data('bs.popover')) {
                            params.target.data('bs.popover').options.content = content;
                        } else {
                            params.target.popover({
                                trigger: 'hover',
                                html: 'true',
                                placement: placement,
                                container: 'body',
                                content: content,
                                sanitize: false
                            }).data('bs.popover').tip().addClass('css-pcr-well-popover');
                        }
                    }
                }
            },

            /**
             *  Common *****************************************************************************************************
             */

            dismissRemainingPopovers: function () {
                var $items = $('.popover');
                _.each($items, _.bind(function (item) {
                    $(item).addClass('hidden');
                }, this));
            },

            getWellsSizePlateIcon: function (wellsSize, runPopover) {
                var containerStyle = 'font-size: 20px; border: 1px solid #333; border-radius: 10px 3px 3px 3px; width: 40px; height: 26px; text-align: center; background-color: white;';
                var wellSizeStyle = 'font-size: 20px; bottom: 1px; position: relative;';
                if (runPopover) {
                    containerStyle += ' top: 3px; position: relative; border-color: white;';
                    wellSizeStyle = 'font-size: 20px; position: relative; top: 5px;';
                }
                return '<div style="' + containerStyle + '">' +
                    '<div style="' + wellSizeStyle + '">' +
                    wellsSize +
                    '</div>' +
                    '</div>';
            },

            getPlacement: function (position, wellsSize) {
                var line = position.substr(0, 1);
                var column = parseInt(position.substr(1, 2), 10);
                var upperLines = ['A', 'B'];

                if (wellsSize > 96) {
                    upperLines.push('C', 'D');
                    return (column > 4 && column < 21) ? (upperLines.includes(line) ? 'bottom' : 'top') : (column < 5 ? 'right' : 'left');
                } else {
                    return (column > 3 && column < 10) ? (upperLines.includes(line) ? 'bottom' : 'top') : (column < 4 ? 'right' : 'left');
                }
            },

            getPreviousLine: function (line) {
                if (line === 'A') {
                    return false;
                }
                return String.fromCharCode(line.charCodeAt(0) - 1);
            },

            getNextLine: function (line) {
                if (line === 'H') {
                    return false;
                }
                return String.fromCharCode(line.charCodeAt(0) + 1);
            },

            getPreviousColumn: function (column) {
                var col = parseInt(column, 10);
                if (col === 1) {
                    return false;
                }
                return FieldUtils.twoDigit(col - 1);
            },

            getNextColumn: function (column) {
                var col = parseInt(column, 10);
                if (col === 12) {
                    return false;
                }
                return FieldUtils.twoDigit(col + 1);
            },

            getFormattedAssayStatus: function (model, type) {
                var assaysWaiting = [];
                var assaysRunning = [];
                var assaysDone = [];

                _.each(model.get('assayStatus'), _.bind(function (item) {
                    if (item.status === 'WAITING') {
                        assaysWaiting.push(item.assay.code);
                    } else if (item.status === 'RUNNING') {
                        assaysRunning.push(item.assay.code);
                    } else if (item.status === 'DONE') {
                        assaysDone.push(item.assay.code);
                    }
                }, this));

                assaysWaiting = assaysWaiting.join(';');
                assaysRunning = assaysRunning.join(';');
                assaysDone = assaysDone.join(';');

                assaysWaiting = assaysWaiting === '' ? '-' : assaysWaiting;
                assaysRunning = assaysRunning === '' ? '-' : assaysRunning;
                assaysDone = assaysDone === '' ? '-' : assaysDone;

                var assayIcons;
                if (type === 'well') {
                    assayIcons = this.getPrepWellAssaysIcons(assaysWaiting === '-', assaysRunning === '-', assaysDone === '-');
                } else if (type === 'popover') {
                    assayIcons = this.getPrepWellTooltipAssaysIcons();
                } else if (type === 'wellEditPopup') {
                    assayIcons = this.getPrepWellEditPopupAssaysIcons();
                } else {
                    assayIcons = this.getPrepWellAssaysIcons();
                }

                return {
                    waiting: assaysWaiting,
                    running: assaysRunning,
                    done: assaysDone,
                    assaysIcons: assayIcons
                };
            }
        };
    }
);

