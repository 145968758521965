// Kick off the application.
require([
    'app',
    'settings',
    'require',
    'translation'
], function (
    App,
    Settings,
    require,
    Translation
) {
    'use strict';

    Translation.fetchTranslations().done(function () {
        require(['router',
            'loadServices'], function () {
            //start the application
            App.start();
        });
    }).fail(function () {
        window.location.href = Settings.serverUrl + 'server-unavailable.html';
    });
});

define("main", function(){});

