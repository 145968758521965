
define('template!resetViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form class="form-horizontal">\n    <fieldset>\n        <div class="top-login-part text-center">\n            <a class="login-view-link">\n                <div class="avatar-view-link-img"></div>\n            </a>\n            <h2>' +
((__t = ( _.i18n("resetPassword.title") )) == null ? '' : __t) +
'</h2>\n        </div>\n        <div class="form-group">\n            <div class="col-lg-12">\n                <input type="password" class="form-control js-password floating-label"\n                       placeholder="' +
((__t = ( _.i18n('resetPassword.password') )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <div class="form-group reset-pass-form">\n            <div class="col-lg-12">\n                <input type="password" class="form-control js-password-confirm floating-label"\n                       placeholder="' +
((__t = ( _.i18n('resetPassword.confirmPassword') )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <div class="form-group reset-pass-form">\n            <div class="col-lg-12 login-view-btns">\n                <a class="btn btn-primary btn-lg btn-block js-reset-password">' +
((__t = ( _.i18n('login.reset.password') )) == null ? '' : __t) +
'</a>\n                <a href="#login" class="js-login-link">' +
((__t = ( _.i18n('forgotpassword.navigate.login') )) == null ? '' : __t) +
'</a>\n            </div>\n        </div>\n        <div class="form-group login-footer">\n            <div class="col-lg-12 text-center">\n                <p>Mobiolink</p>\n                <p>&#169; 2015</p>\n            </div>\n        </div>\n    </fieldset>\n</form>';

}
return __p
};});

