
define('template!menuItemCustom', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="' +
((__t = ( custom.code )) == null ? '' : __t) +
' ' +
((__t = ( custom.className )) == null ? '' : __t) +
'" data-code="' +
((__t = ( nameCode )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'">\n    ';
 switch(custom.code) {
    case 'val2' : ;
__p += '\n    <span class="mdi mdi-check"></span>\n    <span class="mdi mdi-check"></span>\n    ';
 break; ;
__p += '\n    ';
 case 'val12' : ;
__p += '\n    <span class="mdi mdi-check"></span>\n    <span class="mdi mdi-check"></span>\n    ';
 break; ;
__p += '\n    ';
 case 'valExpert' : ;
__p += '\n    <span class="mdi mdi-check"></span>\n    <span class="mdi mdi-school"></span>\n    ';
 break; ;
__p += '\n    ';
 case 'repeat' : ;
__p += '\n    <div class="repeatStatus repeat-XT"></div>\n    ';
 break; ;
__p += '\n    ';
 case 'repeatControl' : ;
__p += '\n    <div class="repeatStatus repeat-XC"></div>\n    ';
 break; ;
__p += '\n    ';
 case 'testLot' : ;
__p += '\n    <span class="mdi mdi-alpha-l"></span>\n    <span class="mdi mdi-alpha-t"></span>\n    ';
 break; ;
__p += '\n    ';
 case 'testAssay' : ;
__p += '\n    <span class="mdi mdi-alpha-a"></span>\n    <span class="mdi mdi-alpha-t"></span>\n    ';
 break; ;
__p += '\n    ';
 case 'testOther' : ;
__p += '\n    <span class="mdi mdi-alpha-x"></span>\n    <span class="mdi mdi-alpha-t"></span>\n    ';
 break; ;
__p += '\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

