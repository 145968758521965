define('createEditLayoutPrepTemplatesView',[
    'module',
    'dialogFormView',
    'template!prepRunTemplatesCreateEditLayoutTpl',
    'savingBehavior',
    'underscore',
    'jquery',
    'settings',
    'dynamicCreateEditUserCodeList',
    'runOperationsView',
    'runController',
    'dynamicCreateEditDescriptionCommentTags'
], function (
    module,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    _,
    $,
    Settings,
    UserCodeListView,
    RunOperationsView,
    RunController,
    DynamicCreateEditDescriptionCommentTags
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            plateLayout: '.js-define-plateLayout',
            periodFormatInfo: '.js-periodFormat-info',
            printRunSheet: '.js-printRunSheet',
            onlyForPcrSetupFromPrep: '.js-onlyForPcrSetupFromPrep',
            generateSmpIds: '.js-generate-smpids',
            removeSmpIds: '.js-remove-smpids',
            assignAssay: '.js-assign-assay',
            removeAssay: '.js-remove-assay',
            addTest: '.js-add-test',
            removeTest: '.js-remove-test',
            operations: '.js-operations'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'click @ui.plateLayout': 'onPlateLayout',
            'click @ui.periodFormatInfo': 'onPeriodFormatInfo',
            'change @ui.printRunSheet': 'onChangePrintRunSheet',
            'change @ui.onlyForPcrSetupFromPrep': 'onChangeOnlyForPcrSetupFromPrep',
            'click @ui.generateSmpIds': 'onGenerateSmpIds',
            'click @ui.removeSmpIds': 'onRemoveSmpIds',
            'click @ui.assignAssay': 'onAssignAssay',
            'click @ui.removeAssay': 'onRemoveAssay',
            'click @ui.addTest': 'onAddTest',
            'click @ui.removeTest': 'onRemoveTest',
            'click @ui.operations': 'onOperations'
        },

        regions: {
            // selectType: '.js-select-type-region',
            selectDepartment: '.js-selectDepartment',
            selectRunGroup: '.js-selectRunGroup',
            selectSequenceFormat: '.js-selectSequenceFormat',
            selectPattern: '.js-selectPattern',
            selectPlateSize: '.js-selectPlateSize',
            descriptionRegion: '.js-description'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [
            {name: 'code', type: 'required'},
            {name: 'plateSize', type: 'required'}
        ],

        onGenerateSmpIds: function () {
            RunController.generateSampleId(this.model);
        },
        onRemoveSmpIds: function () {
            RunController.removeSampleId(this.model);
        },
        onAssignAssay: function () {
            RunController.assignAssay(this.model);
        },
        onRemoveAssay: function () {
            RunController.removeAssay(this.model);
        },
        onAddTest: function () {
            RunController.defineTestWell(this.model);
        },
        onRemoveTest: function () {
            RunController.removeTestWell(this.model);
        },
        onOperations: function () {
            var view = new RunOperationsView({
                model: this.model
            });

            view.show({
                title: _.i18n('runOperations.title'),
                className: 'baseTableEditAddPopup'
            });
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.newItem = this.model.isNew();
            templateData.selectedRunsList = this.options.selectedRunsList;

            templateData.sortSequence = this.model.get('sortSequence') ? this.model.get('sortSequence') : '';
            if (templateData.newItem) {
                templateData.printRunSheet = this.options.printRunSheet;
            }

            return templateData;
        },

        onRender: function () {
            this.getRegion('selectPattern').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'pattern',
                    code: 'PATTERN_PREP'
                }
            }));

            this.getRegion('selectDepartment').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'department',
                    code: 'DEPT'
                }
            }));

            this.getRegion('selectRunGroup').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'group',
                    code: 'RUNGROUP_PREP'
                }
            }));
            this.getRegion('selectSequenceFormat').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'sequenceFormat',
                    code: 'SEQUENCE'
                }
            }));

            if (this.model.isNew()) {
                this.getRegion('selectPlateSize').show(new UserCodeListView({
                    model: this.model,
                    config: {
                        modelProperty: 'plateSize',
                        code: 'PLATESIZES_PREP'
                    }
                }));
            }

            var service = require('services/caccounts/prepruntemplates');
            this.setPermissions(service.canAddOrEdit());
            this.getRegion('descriptionRegion').show(new DynamicCreateEditDescriptionCommentTags({
                model: this.model,
                service: service
            }));
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate) && this.isValidRunGroup()) {
                this.model.save();
            }
        },

        onSave: function (model) {
            var modelJSON = model.toJSON();
            this.model.set(modelJSON);
            this.triggerMethod('saved');
            this.box.modal('hide');
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },

        isValidRunGroup: function () {
            return true;
        },

        onChangePrintRunSheet: function (event) {
            this.model.set('printRunSheet', event.target.checked);
        },

        onPlateLayout: function () {
            RunController.editPlateLayout(this.model);
        }
    });
});

