define('kitLotsView',[
    'module',
    'dialogFormView',
    'template!kitLotsView',
    'savingBehavior',
    'underscore',
    'jquery',
    'settings',
    'dynamicCreateEditDescriptionComment',
    'moment',
    'backbone',
    'autocompleteView',
    'dynamicCreateEditDate',
    'kitLotsActionRegionView',
    'entities/ns'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    Settings,
    DynamicCreateEditDescriptionComment,
    moment,
    Backbone,
    AutocompleteView,
    DynamicCreateEditDate,
    KitLotsActionRegionView,
    app
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            delivery: '.js-delivery',
            test: '.js-test',
            validated: '.js-validate',
            validated2: '.js-validate-2',
            current: '.js-current',
            inactive: '.js-inactive',
            exhausted: '.js-exhausted',
            backToStatus: '.js-status',
            target: '.mdi-target'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm',
            'click .mdi-target.clickable': 'onTargetClick'
        },

        regions: {
            descriptionRegion: '.js-description',
            deliveryRegion: '.js-delivery',
            testRegion: '.js-test',
            validatedRegion: '.js-validated',
            validated2Region: '.js-validated-2',
            currentRegion: '.js-current',
            inactiveRegion: '.js-inactive',
            exhaustedRegion: '.js-exhausted',
            expirationRegion: '.js-expiration',
            kitRegion: '.js-kit-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        className: 'kitLotStatus',

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError',
            change: 'render'
        },

        fieldsToValidate: [
            {name: 'kit', type: 'required'},
            {name: 'code', type: 'required'}
        ],

        serializeData: function () {
            return {
                kitCode: this.model.get('kit') ? this.model.get('kit').get('code') : null,
                code: this.model.get('code'),
                status: this.model.get('status')
            };
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.model.save();
            }
        },

        onSave: function () {
            this.hide();
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onRender: function () {
            if (this.model.isNew() && !this.model.get('kit')) {
                var KitService;
                if (this.model instanceof app.ns.KitLotPcr) {
                    KitService = require('services/caccounts/kitspcr');
                } else {
                    KitService = require('services/caccounts/kitsextraction');
                }
                this.showChildView('kitRegion', new AutocompleteView(
                    this._getAutocompleteOptionObject(KitService.getAutocompleteParam({
                        modelProperty: 'kit'
                    }))));
            }
            this.showChildView('expirationRegion', new DynamicCreateEditDate({
                'field': 'expiration',
                model: this.model,
                'value': this.model.get('expiration'),
                service: require(this.model.service)
            }));
            this.showChildView('descriptionRegion', new DynamicCreateEditDescriptionComment({
                'model': this.model,
                editable: Settings.get('currentUserModel').hasType('KIT0') && Settings.get('currentUserModel').hasRole('KIT')
            }));
            this.showChildView('deliveryRegion', new KitLotsActionRegionView({
                model: this.model,
                status: 'DELIVERED',
                data: ['deliveryBy', 'deliveryDate', 'deliveryQuantity', 'deliveryComment'],
                fieldsToValidate: ['deliveryDate'],
                numberOfValidation: this.options.numberOfValidation,
                role: this.options.role
            }));
            this.showChildView('testRegion', new KitLotsActionRegionView({
                model: this.model,
                status: 'TEST',
                data: ['testBy', 'testDate', 'testComment'],
                fieldsToValidate: ['testDate'],
                numberOfValidation: this.options.numberOfValidation,
                role: this.options.role
            }));
            this.showChildView('validatedRegion', new KitLotsActionRegionView({
                model: this.model,
                status: 'VALIDATED_1',
                data: ['validatedBy', 'validatedDate', 'validatedComment'],
                fieldsToValidate: ['validatedDate'],
                numberOfValidation: this.options.numberOfValidation,
                role: this.options.role
            }));
            if (this.options.numberOfValidation === 1) {
                this.getRegion('validated2Region').empty();
            } else {
                this.showChildView('validated2Region', new KitLotsActionRegionView({
                    model: this.model,
                    status: 'VALIDATED_2',
                    data: ['validated2By', 'validated2Date', 'validated2Comment'],
                    fieldsToValidate: ['validated2Date'],
                    numberOfValidation: this.options.numberOfValidation,
                    role: this.options.role
                }));
            }
            this.showChildView('currentRegion', new KitLotsActionRegionView({
                model: this.model,
                status: 'CURRENT',
                data: ['currentBy', 'currentDate', 'currentComment'],
                fieldsToValidate: ['currentDate'],
                numberOfValidation: this.options.numberOfValidation,
                role: this.options.role
            }));
            this.showChildView('inactiveRegion', new KitLotsActionRegionView({
                model: this.model,
                status: 'INACTIVE',
                data: ['inactiveBy', 'inactiveDate', 'inactiveComment'],
                fieldsToValidate: ['inactiveDate'],
                numberOfValidation: this.options.numberOfValidation,
                role: this.options.role
            }));
            this.showChildView('exhaustedRegion', new KitLotsActionRegionView({
                model: this.model,
                status: 'EXHAUSTED',
                data: ['exhaustedBy', 'exhaustedDate', 'exhaustedComment'],
                fieldsToValidate: ['exhaustedDate'],
                numberOfValidation: this.options.numberOfValidation,
                role: this.options.role
            }));
            var service = require(this.model.service);
            this.setPermissions(service.canAddOrEdit());
        },
        onTargetClick: function () {
            var loader = $('.js-global-loader');
            loader.show();
            this.model.get('kit').fetch().done(_.bind(function () {
                require(this.model.service).editValues({model: this.model});
            }, this))
                .always(function () {
                    loader.hide();
                });
        }
    });
});

