/**
 * Created by RKL on 05/08/2015.
 */
define('forgotShowView',[
    'module',
    'backbone.marionette',
    'app',
    'underscore',
    'template!forgotViewTpl'
], function (
    module,
    Marionette,
    App,
    _,
    viewTpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: viewTpl,

        triggers: {
            'change @ui.email': 'forgot:change:email',
            'click @ui.sendRequest': 'forgot:send:request',
            'submit @ui.form': 'forgot:send:request'
        },

        ui: {
            email: '.js-request-email',
            sendRequest: '.js-send-request',
            form: '.email-form-group'
        },

        events: {
            'click @ui.email': 'onSelect',
            'focus @ui.email': 'onSelect'
        },

        className: 'login-form-container',

        onSelect: function () {
            this.ui.form.find('.floating-label').text(_.i18n('common.email'));
            this.ui.form.removeClass('has-error');
        },

        emailError: function (error) {
            this.ui.form.addClass('has-error');
            this.ui.email.addClass('invalid');
            this.ui.form.find('.floating-label').text(error);
        }
    });
});
