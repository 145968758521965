define('entities/caccounts/kitlotextraction',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/kitsextraction'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.KitLotExtraction = Backbone.RelationalModel.extend({
        service: 'services/caccounts/kitlotextraction',
        fetch: function () {
            this.url = Settings.url('rest/v2/kitlots/extraction/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'kit': null,
            'code': '',
            'status': 'NONE',
            'expiration': null,
            'description': '',
            'assayReagentTargetPcrKitLots': [],
            'deliveryBy': null,
            'testBy': null,
            'validatedBy': null,
            'currentBy': null,
            'inactiveBy': null,
            'exhaustedBy': null
        },
        relations: [{
            type: Backbone.HasOne,
            key: 'kit',
            relatedModel: 'KitExtraction',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'deliveryBy',
            relatedModel: 'UserPid',
            includeInJSON: ['secId', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'testBy',
            relatedModel: 'UserPid',
            includeInJSON: ['secId', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'validatedBy',
            relatedModel: 'UserPid',
            includeInJSON: ['secId', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'currentBy',
            relatedModel: 'UserPid',
            includeInJSON: ['secId', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'inactiveBy',
            relatedModel: 'UserPid',
            includeInJSON: ['secId', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'exhaustedBy',
            relatedModel: 'UserPid',
            includeInJSON: ['secId', 'firtName', 'lastName', 'email', 'nickName']
        }],
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/kitlots/extraction/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'ExtractionKitLotJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ExtractionKitLot',

        importable: true
    });

    app.ns.KitLotExtraction.role = RolesMixin.KIT_EXTRACTION_LOT;

    app.ns.KitLotExtractionCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/kitlots/extraction/');
        },
        model: app.ns.KitLotExtraction,
        comparator: function (model) {
            return -model.get('creatDate');
        }
    });
});

