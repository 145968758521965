define('kitLotsActionGenericView',[
    'module',
    'dialogFormView',
    'template!kitLotsActionGenericView',
    'dynamicCreateEditDate',
    'settings',
    'underscore',
    'autocompleteView',
    'services/caccounts/userpids'
], function (
    module,
    DialogFormView,
    Tpl,
    DynamicCreateEditDate,
    Settings,
    _,
    AutocompleteView,
    UserpidService
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        regions: {
            date: '.js-date',
            userpid: '.js-userpid'
        },

        ui: {
            input: '.js-info-input',
            date: '.ui-date',
            userpid: '.ui-userpid',
            comment: '.ui-comment'
        },

        events: {
            'change @ui.input': 'onInputChange'
        },

        className: 'details',

        initialize: function () {
            this.fieldsToValidate = _.map(this.options.fieldsToValidate, function (fieldToValidate) {
                return {name: fieldToValidate, type: 'required'};
            });
            switch (this.options.status) {
                case 'TEST':
                    this.fieldDate = 'testDate';
                    this.fieldUserpid = 'testBy';
                    this.fieldComment = 'testComment';
                    break;
                case 'VALIDATED_1':
                    this.fieldDate = 'validatedDate';
                    this.fieldUserpid = 'validatedBy';
                    this.fieldComment = 'validatedComment';
                    break;
                case 'VALIDATED_2':
                    this.fieldDate = 'validated2Date';
                    this.fieldUserpid = 'validated2By';
                    this.fieldComment = 'validated2Comment';
                    break;
                case 'CURRENT':
                    this.fieldDate = 'currentDate';
                    this.fieldUserpid = 'currentBy';
                    this.fieldComment = 'currentComment';
                    break;
                case 'INACTIVE':
                    this.fieldDate = 'inactiveDate';
                    this.fieldUserpid = 'inactiveBy';
                    this.fieldComment = 'inactiveComment';
                    break;
                case 'EXHAUSTED':
                    this.fieldDate = 'exhaustedDate';
                    this.fieldUserpid = 'exhaustedBy';
                    this.fieldComment = 'exhaustedComment';
                    break;
                default:
                    throw new Error('Unknown status: ' + this.options.status);
            }
            if (this.options.isAction) {
                var data = {};
                data[this.fieldUserpid] = Settings.get('currentUserModel');
                this.model.set(data, {silent: true});
                this.model.trigger('change:' + this.fieldUserpid);
            }
            this.triggerMethod('enable:cancel:confirm', true);
        },

        serializeData: function () {
            return {
                comment: this.model.get(this.fieldComment),
                user: this.model.get(this.fieldUserpid) ? this.model.get(this.fieldUserpid).toString() : null
            };
        },

        onRender: function () {
            var data;
            if (this.options.isAction) {
                if (this.model.get(this.fieldDate) === null) {
                    data = {};
                    data[this.fieldDate] = new Date().getTime();
                    this.model.set(data, {silent: true});
                    this.model.trigger('change:' + this.fieldDate);
                }
            }
            this.showChildView('date', new DynamicCreateEditDate({
                'field': this.fieldDate,
                model: this.model,
                'value': this.model.get(this.fieldDate),
                param: {display: {readOnly: this.options.readOnly}}
            }));
            var autocomplete = new AutocompleteView(this._getAutocompleteOptionObject(UserpidService.getAutocompleteParam({
                modelProperty: this.fieldUserpid,
                valueProperty: 'nickName',
                queryProperty: 'nickName',
                valueKey: 'nickName',
                readOnly: this.options.readOnly
            })));
            this.showChildView('userpid', autocomplete);
            if (this.options.readOnly) {
                this.ui.input.prop('disabled', true);
                this.ui.comment.prop('disabled', true);
            }
            this.ui.date.attr('data-field-name', this.fieldDate);
            this.ui.userpid.attr('data-field-name', this.fieldUserpid);
            this.ui.comment.attr('data-field-name', this.fieldComment);
        }
    });
});

